/** 
 * @fileOverview An icon button with mouse over highlighing, selectable
 * and activatable with support for tracking clicks. 
 *
 * @module IconButtonCompFunction
 *
 * @author Philip Gottfried
 */
import React, { Component} from 'react';
import styled from 'styled-components';
 
import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
import * as appTracking from "../modules/AppTracking";

/**
* Implements button with embossed look, mouse over highlighing, selectable,
* activatable and stylable with support for tracking clicks. 
* 
* Uses:
* <ul>
* <li>A link button for a tag or a Link component.</li>
* <li>A menu item that returns the selected value.</li>
* <li>A group item that returns the selected value. i.e., a radio button.</li>
* <li>A navigation button that shows active for matching last node of url.</li>
* </ul>
*
*
* Note: Tracking is implemented using isTracked, tracker and appTrackers.trackPageView.  The
* value of isTracked defaults to appDefaults.isReactTracker.  When a tracker function is supplied
* isTracked is set to true, overriding the default.  The tracker function expects a click event.
*
* Use isTracked property of false when using as part of a component the does its own tracking.
* 
*
* @function IconButtonCompFunction
*
* @param {Object} props property object
* 
* @prop {String} type button or submit, default is button.
* @prop {String} text value for button id.
* @prop {String} image value for button. i.e. relative to deployed folder.
* @prop {String} align value for text alignment. i.e. left, right or center.
* @prop {String} color of text.
* @prop {String} backgroundColor of buttton.
* @prop {String} activeColor of mouse over.
* @prop {String} keyId for key uniqueness.
* @prop {String} formId identifies associated form.
* @prop {Function} selectionHandler on click pass button text to function when defined.
* @prop {Function} onClick pass click event to function when defined.
* @prop {Function} tracker pass click event to function when defined. defaults to appTrackers.trackButtonEvent
* @prop {Number} width for fixed width button, as in a menu.
* @prop {Number} height for fixed height button, as in a menu.
* @prop {String} borderColorTL of top and left color for border.
* @prop {String} borderColorBR of bottom and right color for border.
* @prop {String} padLeft of button in pixels.
* @prop {String} padright of button in pixels.
* @prop {String} borderWidth of button in pixels.
* @prop {String} borderStyle of button. i.e. none, solid, ridge ...
* @prop {String} toggle button type of no, yes, drag or route. default is no.
* @prop {Boolean} isTracked indicate clicks are to be tracked. defaults to appDefaults.isReactTracker.
* 
*
* @return {IconButtonCompFunction} component with styles set from defaults or overridden by props.  
*/
export default function IconButtonCompFunction(props) {
//export default function IconButtonCompFunction(props) {

  let activeToggleName = "";

  let formName = "";
  if(props.formId !== undefined) {
    formName = props.formId;
  }
  let iconButtonType = "button";
  if(props.type !== undefined) {
    iconButtonType = props.type;
  }

  let iconButtonAlign = "center";
  if(props.align !== undefined) {
    iconButtonAlign = props.align;
  }

  let iconButtonOpacity=1;
  let iconButtonActiveOpacity=1;
    
  let iconButtonFont = "3vw";
  if(appFunctions.isMobileDevice()) {
    iconButtonFont = "6vw";
  }
  let iconButtonColor=appDefaults.navColor;
  let iconButtonBackgroundColor=appDefaults.navBackgroundColor;
  let iconButtonActiveColor=appDefaults.navActiveColor;
  let iconButtonSelectedColor=appDefaults.navSelectedColor;
  if(props.color !== undefined) {
    iconButtonColor=props.color;
  }
  if(props.backgroundColor !== undefined) {
    iconButtonBackgroundColor=props.backgroundColor;
  }
  if(props.activeColor !== undefined) {
    iconButtonActiveColor=props.activeColor;
  }

  let iconButtonToggle = "no";
  if(props.toggle !== undefined) {
    iconButtonToggle = props.toggle;
  }

  let iconButtonBorderWidth = appDefaults.borderWidth;
  if(props.borderWidth !== undefined) {
    iconButtonBorderWidth = props.borderWidth+"px";
  }
  let iconButtonBorderStyle = appDefaults.borderStyle;
  if(props.borderStyle !== undefined) {
    iconButtonBorderStyle = props.borderStyle;
  }
  let iconButtonPadLeft = "1px";
  if(props.padLeft !== undefined) {
    iconButtonPadLeft = props.padLeft+"px";
  }
  let iconButtonPadRight = iconButtonPadLeft;
  if(props.padRight !== undefined) {
    iconButtonPadRight = props.padRight+"px";
  }
  let iconButtonBorderTopColor=appDefaults.navBorderColorTL;
  let iconButtonBorderBottomColor=appDefaults.navBorderColorBR;
  let iconButtonBorderLeftColor=iconButtonBorderTopColor;
  let iconButtonBorderRightColor=iconButtonBorderBottomColor;
  if(props.borderColorTL !== undefined) {
    iconButtonBorderTopColor=props.borderColorTL;
    iconButtonBorderLeftColor=props.borderColorTL;
  }
  if(props.borderColorBR !== undefined) {
    iconButtonBorderBottomColor=props.borderColorBR;
    iconButtonBorderRightColor=props.borderColorBR;
  }
  let isTracked = appDefaults.isReactTracker;
  if(props.isTracked !== undefined) {
    isTracked = props.isTracked;
  }
  if(props.tracker !== undefined) {
    isTracked = true;
  }

  setActiveToggleName()
  let curl = window.location.href;
  let hrefs = curl.split("/");
  let href = hrefs[hrefs.length-1];


  let bw = iconButtonBorderWidth;
  let bs = iconButtonBorderStyle;

  let tc = iconButtonBorderTopColor;
  let lc = iconButtonBorderLeftColor;
  let bc = iconButtonBorderBottomColor;
  let rc = iconButtonBorderRightColor;

  if(isToggleRouteActive()) {
    tc = iconButtonBorderBottomColor;
    lc = iconButtonBorderRightColor;
    bc = iconButtonBorderTopColor;
    rc = iconButtonBorderLeftColor;
  }

  let style = {
              color: iconButtonColor,
//              fontSize: iconButtonFont,
//              textAlign: iconButtonAlign,
              opacity: iconButtonOpacity,
              backgroundColor: iconButtonBackgroundColor,
              paddingTop: '2px',
              paddingLeft: iconButtonPadLeft,
              paddingBottom: '2px',
              paddingRight: iconButtonPadRight,
              cursor: 'pointer',
              borderRadius: appDefaults.borderRadius,
              borderStyle: appDefaults.borderStyle,
              borderTop: iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+tc,
              borderBottom: iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+bc,
              borderLeft: iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+lc,
              borderRight: iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+rc,
            }
/*  if(props.width !== undefined) {
    style.width=props.width;
  }
    if(props.height !== undefined) {
    style.height=props.height;
  }*/
  if(isToggleRouteActive()) {
    style.backgroundColor=iconButtonSelectedColor;
  }
  let opacity = 1;
  if(props.toggle === "drag") {
    opacity = .1;  
  }
   style.filter="brightness('"+opacity+"')";
  let t=0;
  return ( 
    <img
        key={"iconbutton"+props.keyId+props.text}
        id={props.text}
        name={props.keyId}
        style= {style}
        title={props.title}
        alt={props.title}
        src={props.image}
        type={iconButtonType}
        width={props.width}
        height={props.height}
        onMouseMove={moveNoHandler}
        onMouseDown={mouseDownIconButton}
        onMouseOver={mouseOverIconButton}
        onMouseOut={mouseOutIconButton}
        onClick={(event) => {
          if(isTracked) {
            if(props.tracker !== undefined) {
              props.tracker(event);
            } else {
              appTracking.trackButtonEvent(event);
            }
          }
          if(props.onClick !== undefined) {
            props.onClick(event);
            return;
          }
          if(props.selectionHandler !== undefined) {
            props.selectionHandler(props.text);
            return;
          }
          if(isToggle()) {
            activeToggleName = props.text
          }
        }}
    />
  )

  /**
   * Mouse is over toggle button, set background to iconButtonActiveColor.  
   *
   * @function mouseOverIconButton
   * 
   * @param {Object} event for mouse over.
   */
  function mouseOverIconButton(event) {
    event.preventDefault();
    if(isToggleRouteActive()) {
      //event.target.style.color = iconButtonSelectedColor;
      event.target.style.background = iconButtonSelectedColor;
      event.target.style.opacity = iconButtonOpacity;
      event.target.style.borderTop=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderTopColor;
      event.target.style.borderLeft=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderLeftColor;
      event.target.style.borderBottom=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderBottomColor;
      event.target.style.borderRight=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderRightColor;

    } else {
     // event.target.style.color = iconButtonActiveColor;
      event.target.style.background = iconButtonActiveColor;
      event.target.style.opacity = iconButtonActiveOpacity;
      //appFunctions.logLocalHostOnly("mouse over iconButton "+event.currentTarget.textContent.toLowerCase());  
      event.target.style.borderTop=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderBottomColor;
      event.target.style.borderLeft=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderRightColor;
      event.target.style.borderBottom=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderTopColor;
      event.target.style.borderRight=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderLeftColor;
    }
}

/**
  * Mouse is out of toggle button, set background to iconButtonActiveColor and 
  * when href matches the textContext otherwise use the iconButtonBackgroundColor.  
  * 
  * i.e. highlighted when over.  
  *
  * @function mouseOutIconButton
  * 
  * @param {Object} event for mouse out.
  */
 function mouseOutIconButton(event) {
    event.preventDefault();
    let ref = appFunctions.getRouteFromLocationHref();
    if(!(event.target.id.toLowerCase() === ref.toLowerCase())) {
      //appFunctions.logLocalHostOnly("ref="+ref+" t="+event.target.textContent.toLowerCase());
      //event.target.style.color = iconButtonBackgroundColor;
      event.target.style.background = iconButtonBackgroundColor;
      event.target.style.opacity = iconButtonOpacity;
      event.target.style.borderTop=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderTopColor;
      event.target.style.borderLeft=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderLeftColor;
      event.target.style.borderBottom=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderBottomColor;
      event.target.style.borderRight=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderRightColor;
    } else {
        //appFunctions.logLocalHostOnly("ref="+ref+" t="+event.target.textContent.toLowerCase());
        if(isToggleRouteActive()) {
          //.target.style.color = iconButtonSelectedColor;
          event.target.style.background = iconButtonSelectedColor;
        } else {
         // event.target.style.color = iconButtonActiveColor;
          event.target.style.background = iconButtonActiveColor;
        }
        event.target.style.opacity = iconButtonActiveOpacity;
        event.target.style.borderTop=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderBottomColor;
        event.target.style.borderLeft=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderRightColor;
        event.target.style.borderBottom=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderTopColor;
        event.target.style.borderRight=iconButtonBorderWidth+" "+iconButtonBorderStyle+" "+iconButtonBorderLeftColor;
    }
    //appFunctions.logLocalHostOnly("mouse out taggle button "+event.currentTarget.textContent.toLowerCase());   
  }

  /**
   * Prevent mouse down from allowing drag on button.
   *
   * @function mouseDownIconButton
   * 
   * @param {Object} event for mouse down event.
   */
  function mouseDownIconButton(event) {
    event.preventDefault();
  }
  /**
   * Prevent mouse down from allowing drag on component.
   * 
   * @function moveNoHandler
   *
   * @param {Object} event 
   */
   function moveNoHandler(event) {
    event.preventDefault();
  }
  /**
   * Set the active toggle name, when toggle property is route, set the activeToggleName and 
   * when the text property matches the current route.  i.e., the last node of href.  
   * 
   * So the button shows active selection on first render when
   * the button matchs the end of the url.  
   *
   * @function setActiveToggleName
   *
   */
  function setActiveToggleName() { 
    let curl = window.location.href;
    let refs = curl.split("/");
    let ref = refs[refs.length-1];
    //appFunctions.logLocalHostOnly("did mount styled button "+props.text.toLowerCase()+" ref="+ref.toLowerCase()); 
    if(isToggleRoute() && props.text.toLowerCase() === ref.toLowerCase()) {
      //appFunctions.logLocalHostOnly("set styled activeToggleName "+ref); 
      //setState({ activeToggleName: ref });
      activeToggleName = ref;
    } 
  }

  /**
   * Determine if the toggle property is not no. i.e. button toggles.  
   *
   * All buttons that do not have a click or selection handler will display as a toggle.  
   *
   * @function iconButtonToggle
   *
   * @return {Boolean} True when iconButtonToggle is not no, otherwise false. i.e. yes or route
   */
  function isToggle() {
    return iconButtonToggle.toLowerCase() !== "no";
  }

  /**
   * Determine if the toggle property is for a route.  i.e. is active when text property 
   * matches the last node of the url.  
   *
   * @function isToggleRoute
   *
   * @return {Boolean} True when iconButtonToggle is route, otherwise false. i.e. yes or no
   */
  function isToggleRoute() {
    return iconButtonToggle.toLowerCase() === "route";
  }

  /**
   * Determine if a toggle route button has a activeToggleName that matches the text property.  i.e. the text property
   * matches the last node of the url.  
   *
   * @function isToggleRouteActive
   *
   * @return {Boolean} True when activeToggleName matches text property, otherwise false.
   */
  function isToggleRouteActive() {
    let activeToggle = false;
    if(isToggleRoute()) {
      activeToggle=activeToggleName.toLowerCase() === props.text.toLowerCase();
    }
    return activeToggle;
  }

}
