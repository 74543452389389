
    /**
    * @fileOverview Utility methods working with validating input text.  
    *
    * @module StyledInputTextValidator
    */
import * as appFunctions from "./AppFunctions";
    /**
    * Apply schema validation rule to a value.  
    *  
    * Value can be empty if schemaRecord has canBeEmpty member and canBeEmpty is true.  
    *  
    * A numeric rule can check schemaRecord minimum and maximum if they exist.  
    *  
    * To prevent negative values, use a minimum of 0 and no maximum.  
    *
    * @function applyValueValidationRule
    *
    * @param {String} value to apply validation rule to.
    * @param {Object} schemaRecord with validation rules.
    *
    * @return {Object} result with invalid and message members.
    */
    export const applyValueValidationRule = function(value, schemaRecord) {
      let rule = schemaRecord.validation;
      let checkRange = false;
      let cv = null;
      let result = {invalid:false,message: ""};
      if(schemaRecord.canBeEmpty !== undefined) {
        if(schemaRecord.canBeEmpty && value === "") {
          return result;
        } 
        if(!schemaRecord.canBeEmpty && value === "") {
          result = {invalid:true,message:schemaRecord.label+" can not be an empty!"};
          return result;
        }
      }
      if(rule === "None") return result;
      switch(rule) {
        case "Real":
          if(!isReal(value)) {
            result = {invalid:true,message:value+" is not a "+rule+"!"};
            break;
          }
          checkRange = true;
          break;
        case "Integer":
          if(!isInteger(value)) {
            result = {invalid:true,message:value+" is not a "+rule+"!"};
            break;
          }
          checkRange = true;
          break;
        case "String":
          break;
        case "Alphanumeric":
          if(!this.isAlphanumeric(value)) {
            result = {invalid:true,message:value+" is not "+rule+"!"};
          }
          break;
        case "Email":
          if(!this.isEmail(value)) {
            result = {invalid:true,message:value+" is not "+rule+"! i.e. "+schemaRecord.placeHolder};
          }
          break;
        case "Phone":
          let isUS = this.isUSPhone(value);
          let isInt = this.isInternationalPhone(value);
          if(!isUS && !isInt) {
            result = {invalid:true,message:value+" is not "+rule+"! i.e. "+schemaRecord.placeHolder};
          }
          break;
        case "WebSafe":
        /*
            simple validation of form fields replaces
            a number of character strings with null values
            in an attempt to prevent processing of possible
            script code durring logging and responses with
            posted contact values.
        */
        
        default:
        cv = value.toString();
        //cv = cv.replace('<','');
        cv = cv.replace('>','');
        cv = cv.replace('=','');
        cv = cv.replace('&','');
        cv = cv.replace('%','');
        cv = cv.replace('(','');
        cv = cv.replace(')','');
        if(cv.length < value.length) {
            result = {invalid:true,message:value+" is not "+rule+"!"};
        }
      }
      if(!result.invalid && checkRange) {
        result = this.rangeCheck(value, schemaRecord);
      }
      return result;
    }
 /**
    * Apply a schema validation rule to a keyed value.  
    *
    * @function applyKeyValidationRule
    *
    * @param {String} keyed value, could be last character of paste.
    * @param {String} value to apply validation rule to.
    * @param {String} oldValue before keyed value added to value.
    * @param {Object} schemaRecord with validation rules.
    *
    * @return {Array} result with invalid and message members.
    */
    export const applyKeyValidationRule = function(keyed, value, oldValue, schemaRecord) {
      let rule = schemaRecord.validation;
      let result = {reject:false,invalid:false,message: ""};
      let cv = null;
      let validKeys = "";
      let hasDecimal = false;
      let hasNegative = false;
      let hasPlus = false;
      let hasDash = false;
      let firstIndex = 0;
      let lastIndex = 0;
      let character = "-";
      if(rule === "None") return result;
      switch(rule) {
         case "Real":
          result = this.validateReal(keyed, value, oldValue, schemaRecord);
          break;
        case "Integer":
          hasNegative = oldValue.indexOf('-') >= 0;
          validKeys = "1234567890-";
          if(hasNegative) validKeys = validKeys.replace("-","");
          if(validKeys.indexOf(keyed)>=0) {
            break;
          }
          if(hasNegative && keyed === "-") {
            if(value.indexOf("-") !==  value.lastIndexOf("-") || value.indexOf("-") > 0) {//invalid if two decimals exist or decimal not first character
              result = {reject:true,invalid:true,message:keyed+" rejected as "+rule+" allows only one minus sign at start!"};
            }
          }
          result = {reject:true,invalid:true,message:keyed+" rejected as not "+rule+" character!"};
          break;
        case "String":
          break;
        case "Alphanumeric":
          if(!this.isAlphanumeric(keyed)) {
            result = {reject:true,invalid:true,message:keyed+" rejected as not "+rule+" character!"};
          }
          break;
        case "Email":
          result = this.validateEmail(keyed, value, oldValue, schemaRecord);
          break;
        case "Phone":
          result = this.validatePhone(keyed, value, oldValue, schemaRecord);
          break;
        case "WebSafe":
        default:
        cv = value.toString();
        cv = cv.replace('<','');
        //cv = cv.replace('>','');
        cv = cv.replace('=','');
        cv = cv.replace('&','');
        cv = cv.replace('%','');
        cv = cv.replace('(','');
        cv = cv.replace(')','');
        if(cv.length < value.length) {
            result = {reject:true,invalid:false,message:keyed+" reject as not web safe!"};
        }
      }
      if(!result['reject']) {
       // let valueResult = this.valueValidationRule(value, schemaRecord);
       // return valueResult;
    /*    if(valueResult["invalid"]) {
          result = {reject:false,invalid:true,message:value+" is not complete for "+rule+"!"};
        } else {
          appFunctions.logLocalHostOnly("reject="+result['reject']+" and invalid="+result['invalid']);
          return result;
        }*/
      }
      appFunctions.logLocalHostOnly("applyKeyValidationRule result",result.reject,result.invalid,result.message);
      return result;
    }
    /**
    * Apply a schema validation rule to a value.  
    *
    * @function valueValidationRule
    *
    * @param {String} value to apply validation rule to.
    * @param {Object} schemaRecord with validation rules.
    *
    * @return {Array} result with invalid, , message .
    */
    export const valueValidationRule = function(value, schemaRecord) {
     let rule = schemaRecord.validation;
      let result = {reject:false,invalid:false,message: ""};
        let valueResult = this.applyValueValidationRule(value, schemaRecord);
        if(valueResult["invalid"]) {
          result = {reject:false,invalid:true,message:value+" is not complete for "+rule+"!"};
        } else {
          appFunctions.logLocalHostOnly("reject="+result['reject']+" and invalid="+result['invalid']);
          return result;
        }
      appFunctions.logLocalHostOnly("applyValueValidationRule result=>",result.reject,result.invalid,result.message);
      return result;
    }

    /**
    * Check value is within range defined in schemaRecord.  
    *
    * Allows for a minimum value without a maximum value.  
    * 
    * @function rangeCheck
    *
    * @param {String} value to range check.
    * @param {Object} schemaRecord with range minimum and maximum.
    *
    * @return {Object} result with reject, invalid and message members.
    */
    export const rangeCheck = function(value, schemaRecord) {
      let rule = schemaRecord.validation;
      let result = {reject:false,invalid:false,message: ""};
      if(schemaRecord.minimum !== undefined && schemaRecord.maximum !== undefined) {
        if(Number(value) < Number(schemaRecord.minimum) || Number(value) > Number(schemaRecord.maximum) ) {
          result = {invalid:true,message:value+" of "+rule+" is not between "+schemaRecord.minimum+" and "+schemaRecord.maximum+"!"};
        }
      } else{
        if(schemaRecord.minimum !== undefined && schemaRecord.maximum === undefined) {
          if(Number(value) < Number(schemaRecord.minimum)) {
            result = {invalid:true,message:value+" of "+rule+" is less than "+schemaRecord.minimum+"!"};
          }
        }
      }
      return result;
    }
    /**
    * Indicate value is a real number.  
    *
    * Only possible starting negative, digits and one decimal charater.  
    *
    * @function isReal
    *
    * @param {String} value to apply validation rule to.
    *
    * @return {Array} result true when value is a real number, otherwise false.
    */
    export const isReal = function(value) {
      return value && /^-?\d+(\.\d+)?$/.test(value + '');
    }
    /**
    * Validate key strokes for Real number.  Rejects any keyed
    * values that are not digits and negative or decimal point in 
    * the wrong positions.  
    *
    * US Phone is 999-999-9999. 3 digits, a dash, 3 digits, a dash and 4 digits.  
    *  
    * International Phone is +999999999999999. a plus sign and 7 to 15 digits.  
    *
    * @function validateReal
    *
    * @param {String} keyed value, could be last character of paste.
    * @param {String} value to apply REal rule to.
    * @param {String} oldValue before keyed value added to value.
    * @param {Object} schemaRecord with validation rules.
    *
    * @return {Object} result with reject, invalid and message members.
    */
    export const validateReal = function(keyed, value, oldValue, schemaRecord) {
      let rule = schemaRecord.validation;
      let allowNegative = true;
      if(schemaRecord.minimum !== undefined) {
        if(schemaRecord.minimum >= 0) {
          allowNegative = false;
        }
      }
      let cv = null;
      let result = {reject:false,invalid:false,message: ""};
      let firstIndex = 3;
      let lastIndex = 7;
      let  character = ".";
      let hasDecimal = oldValue.indexOf(character) >= 0;
      let hasNegative = oldValue.indexOf('-') >= 0;
      if(keyed === "-") {
        if(!allowNegative) {
          result = {reject:true,invalid:false,message:keyed+" rejected as minimum is "+schemaRecord.minimum+" and can not be negative "+rule+"!"};
          return result;
        }
        if(!hasNegative && value.indexOf("-") !== 0) {
          result = {reject:true,invalid:false,message:keyed+" rejected as it must be first character of negative "+rule+"!"};
        }
        if(hasNegative) {
          result = {reject:true,invalid:false,message:keyed+" rejected as only one of character is allowed in negative "+rule+"!"};
        }
        return result;
      }
      if(keyed === character) {
        if(!this.oneCharOnly(value, character)) {
            result = {reject:true,invalid:false,message:keyed+" rejected as must be only one of character in "+rule+"!"};
            return result;
        }
        return result;
      }
      let validKeys = "1234567890";
      if(validKeys.indexOf(keyed) < 0) {
        result = {reject:true,invalid:false,message:keyed+" rejected as only digits for "+rule+"!"};
       return result;
      }
      //valid key may have moved negative or decimal
      if(hasNegative && value.indexOf("-") !== 0) {
        result = {reject:true,invalid:false,message:keyed+" rejected as it must be first character of negative "+rule+"!"};
        return result;
      }
      return result;
    }

    /**
    * Indicate value is an integer number.  
    *
    * Only digits and possible starting negative charater.  
    *
    * @function isInteger
    *
    * @param {String} value to apply validation rule to.
    *
    * @return {Array} result true when value is an integer number, otherwise false.
    */
    export const isInteger = function(value) {
      return value && /^-?\d+$/.test(value + '');
    }

    /**
    * Indicate value is alphabetic.  i.e. a-z or A-Z.  
    *
    * Only alphabetic charaters.  
    *
    * @function isAlpha
    *
    * @param {String} value to apply validation rule to.
    *
    * @return {Array} result true when value is alphabetic, otherwise false.
    */
    export const isAlpha = function(value) {
      let code, i, len;
      for (i = 0, len = value.length; i < len; i++) {
        code = value.charCodeAt(i);
        if (!(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
          return false;
        }
      }
      return true;;
    }

    /**
    * Indicate value is alphanumeric.  i.e. 0-9, a-z or A-Z.  
    *
    * Only alphanumeric charaters.  
    *
    * @function isAlphanumeric
    *
    * @param {String} value to apply validation rule to.
    *
    * @return {Array} result true when value is alphanumeric, otherwise false.
    */
    export const isAlphanumeric = function(value) {
      let code, i, len;
      for (i = 0, len = value.length; i < len; i++) {
        code = value.charCodeAt(i);
        if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
          return false;
        }
      }
      return true;
    }
    /**
    * Indicate value is US phone number.  i.e. 999-999-9999.  
    *
    * Only three digits, a dash, three digits, a dash and four digits.  
    *
    * @function isUSPhone
    *
    * @param {String} value to apply validation rule to.
    *
    * @return {Array} result true when value is a us phone number, otherwise false.
    */
    export const isUSPhone = function(value) {
      let t = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value + '');
      return t;
    }

    /**
    * Indicate value is international phone number.  i.e. +999999999999999.  
    *  
    * Only a plus sign plus 7 to 15 digits.  
    *
    * @function isInternationalPhone
    *
    * @param {String} value to apply validation rule to.
    *
    * @return {Array} result true when value is an international phone number, otherwise false.
    */
    export const isInternationalPhone = function(value) {
      let t = /^\+\d{7,15}$/.test(value + '');
      return t;
    }

    /**
    * Validate key strokes for a US or International phone number.  Rejects any keyed
    * values that are not digits and dashes or plus signs in the wrong positions.  
    *
    * US Phone is 999-999-9999. 3 digits, a dash, 3 digits, a dash and 4 digits.  
    *  
    * International Phone is +999999999999999. a plus sign and 7 to 15 digits.  
    *
    * @function validatePhone
    *
    * @param {String} keyed value, could be last character of paste.
    * @param {String} value to apply Phone rule to.
    * @param {String} oldValue before keyed value added to value.
    * @param {Object} schemaRecord with validation rules.
    *
    * @return {Object} result with reject, invalid and message members.
    */
    export const validatePhone = function(keyed, value, oldValue, schemaRecord) {
      let rule = schemaRecord.validation;
      let result = {reject:false,invalid:false,message: ""};
      let firstIndex = 3;
      let lastIndex = 7;
      let  character = "-";
      let hasDash = oldValue.indexOf(character) >= 0;
      let hasPlus = oldValue.indexOf('+') >= 0;
      if(keyed === "+") {
        if(!hasPlus && value.indexOf("+") !== 0) {
          result = {reject:true,invalid:false,message:keyed+" rejected as it must be first character of international "+rule+"!"};
        }
        if(hasPlus) {
          result = {reject:true,invalid:false,message:keyed+" rejected as only one of character is allowed in international "+rule+"!"};
        }
        return result;
      }
      if(keyed === character) {
        if(hasPlus) {
          result = {reject:true,invalid:false,message:keyed+" rejected as character is allowed in international "+rule+"!"};
          return result;
        }
        if(!this.oneCharAtIndex(value, firstIndex, character)) {
            result = {reject:true,invalid:false,message:keyed+" rejected as must be character "+firstIndex+" of US "+rule+"!"};
            return result;
        }
        if(!this.twoCharAtIndexes(value, firstIndex, lastIndex, character))  {
            result = {reject:true,invalid:false,message:keyed+" rejected as must be character "+firstIndex+" & "+lastIndex+" of US "+rule+"!"};
            return result;
        }
        return result;
      }
      let validKeys = "1234567890";
      if(validKeys.indexOf(keyed) < 0) {
        result = {reject:true,invalid:false,message:keyed+" rejected as only digits for "+rule+"!"};
       return result;
      }
      //valid key may have moved + or dashes
  /*    if(value.indexOf("+") >= 0 && value.indexOf("+") !== 0) {
        result = {reject:true,invalid:false,message:keyed+" rejected as it must be first character of international "+rule+"!"};
        return result;
      }
      if(value.length >=firstIndex && value.length<lastIndex) {
        if(!this.oneCharAtIndex(value, firstIndex, character)) {
            result = {reject:true,invalid:false,message:keyed+" rejected as must be character "+firstIndex+" of US "+rule+"!"};
            return result;
        }
      }
      if(value.length >=lastIndex) {
        if(!this.twoCharAtIndexes(value, firstIndex, lastIndex, character)) {
            result = {reject:true,invalid:false,message:keyed+" rejected as must be character "+firstIndex+" & "+lastIndex+" of US "+rule+"!"};
            return result;
        }
      }//*/
      return result;
    }

   /**
    * Validate key strokes for an email.  Rejects any keyed
    * values that are not digits and dashes or plus signs in the wrong positions.  
    *
    * Email is one or more string characters followed by an at sign followed by 
    * one or more characters a period and at least one or more characters.  
    *
    * user@domain .name
    *
    * user = a-z A-Z 0-9 _ ~ - but not first or last and . with one character perfore and after.
    *  
    * domain = A-Z 0-9 and one period.
    * 
    * @function validateEmail
    *
    * @param {String} keyed value, could be last character of paste.
    * @param {String} value to apply email rule to.
    * @param {String} oldValue before keyed value added to value.
    * @param {Object} schemaRecord with validation rules.
    *
    * @return {Object} result with reject, invalid and message members.
    */
    export const validateEmail = function(keyed, value, oldValue, schemaRecord) {
      let rule = schemaRecord.validation;
      let result = {reject:false,invalid:false,message: ""};
      let atIndex = oldValue.indexOf('@');
      let dotIndex =  oldValue.indexOf(".");
      let dashIndex =  oldValue.indexOf("-");
      let hasPeriod = dotIndex >= 0;
      let hasAt = atIndex >= 0;
      let hasDash = dashIndex >= 0;
      if(keyed === "@") {
        if(!hasAt && value.indexOf("@") === 0) {
          result = {reject:true,invalid:false,message:keyed+" rejected as it can NOT be first character of "+rule+"!"};
        }
        if(hasAt) {
          result = {reject:true,invalid:false,message:keyed+" rejected as only one is allowed in "+rule+"!"};
        }
        return result;
      }
      if(keyed === "-") {
        if(!hasDash && value.indexOf("-") === 0) {
          result = {reject:true,invalid:false,message:keyed+" rejected as it can NOT be first character of "+rule+"!"};
          return result;
         }
      }
      if(keyed === '.' || keyed==='_' || keyed==='-' || this.isAlphanumeric(keyed)) {
        return result;
      } else {
        result = {reject:true,invalid:false,message:keyed+" rejected as only letters, numbers, dots, dashes and underscores are allowed in "+rule+"! i.e. "+schemaRecord.placeHolder};
        return result;
      }
      return result;
    }

    /**
    * Indicate value is email.  i.e. name@domain.com.  
    *
    * Only a user string, an @ sign, a domain string, a dot and top level domain string.  
    *
    * Note: will invalidate some valid emails, but should get most.  Only an email
    * sent with a reply can verify it is valid. i.e. this is a sanity check.
    *
    * @function isEmail
    *
    * @param {String} value to apply validation rule to.
    *
    * @return {Array} result true when value is an email, otherwise false.
    */
    export const isEmail = function(value) {
      let t = /\S+@\S+\.\S+$/.test(value + '');
      return t;
    }

    /**
    * Indicate value has only one of character at any position.  
    *
    * @function oneCharOnly
    *
    * @param {String} value to check for character.
    * @param {String} character that there must only be one of.
    *
    * @return {Boolean} result true when only one of character, otherwise false.
    */
    export const oneCharOnly = function(value, character) {
      let valid = true;
      let first = value.indexOf(character);
      let last = value.lastIndexOf(character);
      if(first >= 0 && first !== last) {
        valid = false;
      }
      return valid;
    }

    /**
    * Indicate value has one character at the firstIndex.  
    *
    * @function oneCharAtIndex
    *
    * @param {String} value to check for character at firstIndex.
    * @param {Number} firstIndex character must be at.
    * @param {String} character that must be at firstIndex.
    *
    * @return {Boolean} result true when firstIndex is character, otherwise false.
    */
    export const oneCharAtIndex = function(value, firstIndex, character) {
      let valid = true;
      let first = value.indexOf(character);
      let last = value.lastIndexOf(character);
      if(first >= 0 && first === last) {
        if(first !== firstIndex) {
          valid = false;
        }
      }
      return valid;
    }

    /**
    * Indicate value has two of character at the firstIndex and lastIndex.  
    *
    * @function twoCharAtIndexes
    *
    * @param {String} value to check for character at firstIndex and lastIndex.
    * @param {Number} firstIndex character must be at.
    * @param {Number} lastIndex character must be at.
    * @param {String} character that must be at firstIndex and lastIndex.
    *
    * @return {Boolean} result true when firstIndex and lastIndex are character, otherwise false.
    */
    export const twoCharAtIndexes = function(value, firstIndex, lastIndex, character) {
      let valid = true;
      let first = value.indexOf(character);
      let last = value.lastIndexOf(character);
      if(first >= 0 && first !== last) {
        if(first !== firstIndex || last !== lastIndex)  {
          valid = false;
        }
      }
      return valid;
    }