/** 
 * @fileOverview Display a Dropdown with an icon to activate and select link options
 * with optional LogIn/LogOut items.  
 *
 * @module LinkMenuFunction
 */
import React, {Component} from "react";
import {useNavigate} from 'react-router-dom';

import * as appFunctions from "../modules/AppFunctions";
import DropdownFunction from "./DropdownFunction";

/**
* Implements display of drop down menu with an icon and link locations that
* is set to work from the left side of a container.  
*
* Dropdown with menuMap, size and handler set to treat as Router path or http href,
* otherwise 404 Router page.
* 
* Adds the default social media items, optional exclude items for Contact and Resume
* on their respective pages and when logged in adds a Logout item, otherwise a Login item. 
*
* Initialize gotoSelectedLocation for Dropdown selectionHandler to include log in/out
* 
* @function LinkMenuFunction
*
* @param {*} props 
*     
* @prop {object} auth the auth0Client instance, passed to LinkMenu
* @prop {boolean} isAppLoggedIn indicating login state.
* @prop {number} width of drop down button width, default 50.
* @prop {number} height of drop down button height, default 50.
*
* @return {Object} DropdownFunction for menuMap align on left of container. 
*/
export default function LinkMenuFunction(props) {
    //appFunctions.logLocalHostOnly("LinkMenu construct");
    const navigate = useNavigate();
    let linkMenuWidth=50;
    let linkMenuHeight=50;
    if(props.width !== undefined) {
        linkMenuWidth=props.width;
    }
    if(props.height !== undefined) {
        linkMenuHeight=props.height;
    }
    let isUseLogIn = false;
    if(props.isUseLogIn !== undefined) {
        isUseLogIn = props.isUseLogIn;
    }
    let isAppLoggedIn = false;
    if(props.isAppLoggedIn !== undefined) {
        isAppLoggedIn = props.isAppLoggedIn;
    }

    /**
     * Goto selected location.
     * 
     * When location starts with http a window is opened in new tab,
     * otherwise location is replaced on navigate.
     *
     * When selection is Login or Logout use optional signIn/signOut property functions.  
     * 
     * @function gotoSelectedLocation
     *
    * @param {*} selection the menuItem selected.
    */
    function gotoSelectedLocation(selection) { 
        let location = menuMap.menuItems[selection];
        if(location.toLowerCase().indexOf("http")===0) {
           window.open(location, "menuclick") || window.location.replace(location);
        } else {
            let redisplay = appFunctions.getRouteFromLocationHref();
            switch(selection) {
                case "Login":
                    props.auth.signIn(redisplay);
                    break;
                case "Logout":
                    props.auth.signOut(redisplay);
                    break;
                default: 
                    navigate(location);   
            }
        }
    }

    let menuMap={menuItems:{LinkedIn: "https://www.linkedin.com/in/philgottfried/"}};
    let ref = appFunctions.getRouteFromLocationHref();  
    if(!(ref.toLowerCase() === "contactform")) {
        menuMap.menuItems["Contact"]="/ContactForm";
    }
    if(!(ref.toLowerCase() === "resume")) {
        menuMap.menuItems["Resume"]="/Resume";
    }
    if(isUseLogIn) {
        if(isAppLoggedIn) {
            menuMap.menuItems["Logout"]="/AppAuthorization";
        } else { 
            menuMap.menuItems["Login"]="/AppAuthorization";
        }
    }
    return <DropdownFunction 
            direction="left"
            offset={0}
            menuMap={menuMap}
            width={linkMenuWidth}
            height={linkMenuHeight}
            selectionHandler={gotoSelectedLocation}
            color='black'
            backgroundColor="white"
            isAppLoggedIn={props.isAppLoggedIn}
        />
}
