/**
* @fileOverview Utility methods for maintaining cache using local store.  
*
* @module AppCache
*/
import * as React from 'react';

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";

/**
* Log value to name in local store.  
*
* The value is appended to the end of the existing value
* with a preceding new line character.
*
* @function logToLocalStore
*
* @param name {String} of local store.
* @param value {String} value to add to name in local store.
* 
* @return {Boolean} True when cache reset, otherwise false.
*/
export const logToLocalStore = function(name, value) {
    if(doesLocalStoreExist(name)) {
        let log = JSON.parse(localStorage.getItem(name));
        setLocalStoreObject(name, log+"\n"+value);
    } else {
        setLocalStoreObject(name, value);
    }
};

/**
* Indicate name exists in local store.  
*
* @function doesLocalStoreExist
* 
* @param name {String} to search local store.
*
* @return {Boolean} True when name is found in local store, otherwise false.
*/
export const doesLocalStoreExist = function(name) { 
    let found =  false;
    let result = "";
    for (let i = 0; i < localStorage.length; i++) {
        result = localStorage.key(i);
        if(result === name) {
            found =  true;
            break;
        }
    }
    return found;
};

/**
* Delete local store for name.  
*
* @function deleteLocalStore
*
* @param name {String} to delete from local store.
*
* @return {Boolean} True when name removed, otherwise false when name does not exists.
*/
export const deleteLocalStore = function(name) {
    let result = false;
    if(doesLocalStoreExist(name)) {
        localStorage.removeItem(name);
        result = true;
    }
    return result;
};

/**
* Get object value for name from local store.  
*
* Uses JSON.parse to return object value so it will work
* on any javascript object.
*
* @function getLocalStoreObject
*
* @param name {String} to get from local store.
*
* @return {Object} value for name fron local store or empty object when name does not exist.
*/
export const getLocalStoreObject = function(name) {
    let result = {};
    if(doesLocalStoreExist(name)) {
        result = JSON.parse(localStorage.getItem(name));
    }
    return result;
};

/**
* Set local store object value for name.  
*
* Uses JSON.sringify to store object value, so it will work
* on any javascript object.
*
* @function setLocalStoreObject
*
* @param name {String} of value for local store.
* @param value {Object} for local store with name.
*
*/
export const setLocalStoreObject = function(name, value) {
    localStorage.setItem(name, JSON.stringify(value));
};

/**
* Reset local store of name with value when different from existing value.  
*
* Sets local store of name+'Reset' to value:  
* <ul>
* <li>new - local store with name did not exists.</li>
* <li>no - value matches existing value.</li>
* <li>reset - value replaced existing value.</li>
*</ul>
* @function resetNamedLocalStore 
*
* @param name {String} for local store with name.
* @param value {String} of to check against for reset.
*
* @return {Boolean} True when local store reset/new, otherwise false.
*/
export const resetNamedLocalStore  = function(name, value) {
    let result = true;
    if(doesLocalStoreExist(name)) {
        let savedValue = getLocalStoreObject(name);
        if(value === savedValue) {
            result = false;
            logToLocalStore("localLog","using browser cache");
            setLocalStoreObject(name+"Reset","no");
        } else {
            setLocalStoreObject(name, value);
            setLocalStoreObject(name+"Reset","reset");
            appFunctions.logLocalHostOnly("before browser cache reset");
            logToLocalStore("localLog","before browser cache reset");
            emptyBrowserCache(value, savedValue);
            appFunctions.logLocalHostOnly("after browser cache reset");
            logToLocalStore("localLog","before browser cache reset");
        }
    } else {
        setLocalStoreObject(name, value);
        setLocalStoreObject(name+"Reset","new");
    }
    return result;
};

/**
* Indicate local store with name+'Reset' is reset/new or no.  
*
* @function isCacheReset
*
* @param name {String} prefix of local store name of reset .
*
* @return {Boolean} True if new or reset, otherwise false.
*
*/
export const isCacheReset = function(name) {
    let reset = true;
    let value = getLocalStoreObject(name+"Reset") || '';
    if(value === 'no') {
        reset = false;
    }
    return reset;
};

/**
* Empty browser cache and reload.  
*
* @function emptyBrowserCache
*
* @param date {String} of new deployment.
* @param saveDate {String} of old deployment.
*
*/
export const  emptyBrowserCache = function(date, saveDate){
if('caches' in window){
    setLocalStoreObject("emptyCache","new="+date+" old="+saveDate+"\n");
    caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        }); 
        appFunctions.logLocalHostOnly("reload browser caches deleted");
        logToLocalStore("localLog","before reload browser cache reset");
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
        logToLocalStore("localLog","after reload browser cache reset");
    }
}
