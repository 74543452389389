/** 
 * @fileOverview A div set to display as flex with a left, center and right div
 * set to display inline-block.  Any children are placed in the center div that
 * has rounded corners, a border and background color properties.  
 *
 * @module DivGroupFunction
 * 
 * @author Philip Gottfried
 */

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";

/**
* Implements a div with three columns and place any children in the center div.
* The width of the div's default to 5%, 90% and 5% with the left and right div
* set to diplay as transparent and the center div is set to have a partially
* transparent background and a border with rounded corners.
* 
* Uses AppDefaults to set default properties.  
*  
* Mobile uses 1%, 98% and 1% for the column widths.  
* 
* @function DivGroupFunction
*
* @param {Object} props property object
* 
* @prop {String} leftWidth of left div
* @prop {String} centerWidth of center div
* @prop {String} rightWidth of right div
* @prop {String} borderRadius for center div
* @prop {String} backgroundColor for center div
* @prop {String} borderTopColor for center div
* @prop {String} borderBottomColor for center div
* @prop {Boolean} noBorder of true turns off border and background. i.e. used for a menu.
* @prop {String} height used when noBorder property is true.
*
* @returns {Object} div with three divs with default widths of 5%,90,5% or for mobile 1%, 98%, 1%
*       and styled with a beveled border, rounded corners and partially transparent white background.
*/
export default function DivGroupFunction(props) {
    let scrollWidth =  window.innerWidth - document.documentElement.clientWidth;
    let wowWindidth= window.innerWidth-scrollWidth;
    let leftWidth = "5%";
    let centerWidth = "90%";
    let rightWidth = "5%";
    if(appFunctions.isMobileDevice()) {
        leftWidth = "1%";
        centerWidth = "98%";
        rightWidth = "1%";
    }
    if(props.leftWidth !== undefined) {
        leftWidth = props.leftWidth;
    }
    if(props.centerWidth !== undefined) {
        centerWidth = props.centerWidth;
    }
    if(props.leftWidth !== undefined) {
        rightWidth = props.rightWidth;
    }

    let borderStyle={
        touchAction:'manipulation',
        position: 'relative',
        display: 'block',
        borderRadius: props.borderRadius,
        backgroundColor: props.backgroundColor,
        borderTop: props.borderTopColor,
        borderLeft: props.borderTopColor,
        borderBottom: props.borderBottomColor,
        borderRight: props.borderBottomColor,
        textAlign: 'center',
        flexGrow: '1'
    }
    if(props.noBorder !== undefined) {
        if(props.noBorder) {
            if(props.height !== undefined) {
                borderStyle={
                    height: props.menuHeight,
                    touchAction:'manipulation',
                    position: 'relative',
                    display: 'block',
                    textAlign: 'center',
                    flexGrow: '1'
                }
            } else {
                borderStyle={
                        touchAction:'manipulation',
                        position: 'relative',
                        display: 'block',
                        textAlign: 'center',
                        flexGrow: '1'
                      }
            }
        }
    }

    let isNavMenu = props.onMove === undefined;
    return (
        <div key="divgroupflex" width="100%" align="center"
                style={{display: 'flex', top: '0px',
                    position: 'relative',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between:',
                    border: '0px'
                }} 
        >
            <div key={"divgroupleft"}  width={leftWidth}  align="left"
                style={{position: 'relative',display: 'inline-block',
                    color: 'rgba(0,0,0,0)'
                }}
            >
                x
            </div>
            <div key="divgroupcenter" className="divGroup" align='center' width={centerWidth} 
                    style={borderStyle}
            >
                {props.children}
            </div>
            <div key={"divgroupright"}  width={rightWidth}  align="right"
                    style={{position: 'relative',display: 'inline-block',
                        color: 'rgba(0,0,0,0)'
                    }}
            >
                x
            </div>
        </div>
    );
}
/**
 * Default props set from appDefault.
 */
DivGroupFunction.defaultProps = {
    leftWidth: '1%',
    centerWidth: '98%',
    rightWidth: '1%',
    borderRadius: appDefaults.divGroupBorderRadius,
    borderTopColor: appDefaults.borderWidth+" "+appDefaults.borderStyle+" "+appDefaults.borderColorTL,
    borderBottomColor: appDefaults.borderWidth+" "+appDefaults.borderStyle+" "+appDefaults.borderColorBR,
    backgroundColor: appDefaults.divGroupBackground
}