/** 
 *  @fileOverview Display portfolio project from json objects.  
 *
 * @module ProjectViewFunction
 */
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
import * as appFontSizes from "../modules/AppFontSizes";
import * as appMetaTags from "../modules/AppMetaTags";

import StyledButtonModFunction from "./StyledButtonModFunction";
import {getPixelWidthOfTextForNamedFontSize} from "../modules/AppFontSizes";
import {getFontSizeOfTextFitToViewportForFontSize} from "../modules/AppFontSizes";
import FormJSONFunction from "./FormJSONFunction";
import DivGroupFunction from "./DivGroupFunction";

/**
 * Implements display of formatted projectData properties.
 *
  * Initialize.
  * 
  * Display formatted projectData properties.
  * 
  * A project icon.
  * 
  * List of technologies used to build the project.
  * 
  * A description of the project.
  * 
  * A set of link buttons for docs and any implementations.
  * 
  * An icon to display screen shots of project using Lightbox.
  *
  * @function ProjectViewFunction
  *
  * @param {Object} props property object
  * 
  * @prop {object} projectData json object of project data
  * @prop {object} projectSchema json object of project field data
  * @prop {object} identitySchema json object of identity field data.
  * @prop {object} identityData json object of identity data.
  * @prop {String} proot for key uniqueness
  * @prop {boolean} isAppLoggedIn true when valid logIn, otherwise false.
  * @prop {String} cookie session value for post
  * @prop {String} fontName for text
  *
  * @return {Object} div with DivGroupFunction with formatted project data.  
  */
export default function ProjectViewFunction(props) {
  const navigate = useNavigate();
  let dataStatus = "Saved";
  const [formChanged, setFormChanged] = useState(false);
  let projectData = {...props.projectData};
  let identityData = props.identityData;
 

  /**
   * Update form with changed data that was successfully submitted or previewed
   * by setting formChanged state.  
   * 
   * Preview allows the form changes to update the project view without being saved.  
   *
   * Note: data is in json string format and must be parsed to ensure a new json value
   * is created, otherwise only the address is copied.  
   * 
   * @function updateWithFormChanges
   *
   * @param {String} name of form submitted.
   * @param {String} status result of Saved or Preview.
   * @param {String} message result of submit.
   * @param {String} data in json format result of form reload, submit or preview. i.e. new data to display.
   */
  function updateWithFormChanges(name, status, message, data) {
    if(status === 'Saved' || status === 'Preview') {
      if(name === projectData.projectTitle) {
        projectData = JSON.parse(data);
      }
      if(name === "identity") {
        identityData = JSON.parse(data);
      }
      dataStatus = status;
      //setState({formChanged:!state.formChanged});
      setFormChanged(!formChanged);
    }
    if(status === 'Reload') {
      dataStatus = "Reload";
     // setState({formChanged:!state.formChanged});
      setFormChanged(!formChanged);
    }
  }
  /**
  * Determine isAppLoggedIn status based on isAppLoggedIn property.
  * 
  * @function isAppLoggedIn
  *
  * @return {Boolean} True when isAppLoggedIn property isdefined and value is true, otherwise false. 
  */
  function isAppLoggedIn() {
    if(props.isAppLoggedIn === undefined) {
      return false;
    }
    return props.isAppLoggedIn;
  }

  /** 
   * Prevent mouse down from allowing drag on image.
   * 
   * @function mouseDownHandler
   *
   * @param {Object} event for onMouseDown for an image.
   */
  function mouseDownHandler(event) {
    event.preventDefault();
  }

  /**
   * Goto selected location.  
   * 
   * When location starts with http a window is opened in new tab,
   * otherwise location is pushed onto navigate. i.e., route.
   * 
   * @function gotoSelectedLocation
   *
   * @param {String} selection the menuItem selected.
   */
  function gotoSelectedLocation(selection) { 
    let location = "/"+selection+keyName;//project;
    if(location.toLowerCase().indexOf("http")===0) {
        window.open(location, "menuclick") || window.location.replace(location);
    } else {
        navigate(location);
        navigate(location);
    }
  }
   /**
   * Two column bullet list from bulletList with fontName and fontSize.  
   *
   * @function twoColumnBulletList
   *
   * @param {Array} bulletList of items to display in two columns.
   * @param {String} fontName name for list items.
   * @param {String} fontSize value with number and units. i.e. 3vw
   * 
   * @return {Object} two column table with bullet list split bewteen them.
   *
   */
  function twoColumnBulletList(bulletList, fontName, fontSize) {
    let col1Widest = 0;
    let col2Widest = 0;
    let col1WidestPX = 0;
    let col2WidestPX = 0;
    let textWidthInPX = 0;
    for(let i in bulletList) {
      if(parseInt(i)>=0 && parseInt(i)<bulletList.length/2) {
        if(bulletList[i].length>col1Widest) {
          col1Widest = bulletList[i].length;
        }
        textWidthInPX = getFontSizeOfTextFitToViewportForFontSize(bulletList[i], fontSize, false, 0, 16) ;
        textWidthInPX = getPixelWidthOfTextForNamedFontSize(bulletList[i],textWidthInPX+'px', fontName, false);
        if(textWidthInPX>col1WidestPX) {
          col1WidestPX = textWidthInPX;
        }
      } else {
          if(bulletList[i].length>col2Widest) {
            col2Widest = bulletList[i].length;
          }
          textWidthInPX = getFontSizeOfTextFitToViewportForFontSize(bulletList[i], fontSize, false, 0, 16) ;
          textWidthInPX = getPixelWidthOfTextForNamedFontSize(bulletList[i],textWidthInPX+'px', fontName, false);
          if(textWidthInPX>col2WidestPX) {
            col2WidestPX = textWidthInPX;
          }
      }
    }
    appFunctions.logLocalHostOnly("2col");
    return (
      <table key={"table2"+props.keyName} width="100%">
        <tbody key={"body2"+props.keyName}>  
            <tr key={"t2row1"+props.keyName}>
                <td key={"t2td1"+props.keyName} width="1%"> </td>
                <td key={"t2td2"+props.keyName} width="45%"
                  style={{ font:props.fontName, fontSize:fontSize, display: 'inline-block',
                            whiteSpace: 'nowrap', overflowWrap: 'break-word'
                        }}
                >
                      <ul key={"t2ul1"+props.keyName}  width="100%"
                          style={{ font:props.fontName, fontSize:fontSize, textAlign: 'left'
                                  }}>
                        {bulletList.map((item, index) => {
                                if((index>=0 && index<bulletList.length/2)) {
                                    return <li key={"t2li1"+props.keyName+index}
                                        width="100%"
                                        style={{ font:props.fontName, fontSize:fontSize,
                                          whiteSpace: 'nowrap', overflowWrap: 'break-word'
                                              }}
                                    >{item}</li>
                                }
                                return null;
                            })
                        }
                      </ul>
                  </td>
                  <td key={"t2td3"+props.keyName} width="1%"> </td>
                  <td key={"t2td4"+props.keyName} width="45%"
                    style={{ font:props.fontName, fontSize:fontSize, display: 'inline-block',
                            whiteSpace: 'nowrap', overflowWrap: 'break-word'
                          }}>
                    <ul key={"t2ul2"+props.keyName}  width="100%"
                        style={{ font:props.fontName, fontSize:fontSize, textAlign: 'left'
                                              }}>
                        {bulletList.map((item, index) => {
                                if((index>=bulletList.length/2 && index<bulletList.length)) {
                                  return <li key={"t2li2"+props.keyName+index}
                                              width="100%"
                                              style={{ font:props.fontName, fontSize:fontSize,
                                                whiteSpace: 'nowrap', overflowWrap: 'break-word'
                                                    }}
                                          >{item}</li>
                                }
                                return null;
                            })
                        }
                    </ul>
                  </td> 
            </tr>
        </tbody>
      </table>
    );
  }

  /**
   * One column bullet list from bulletList with fontName and fontSize.  
   * 
   * @function oneColumnBulletList
   *
   * @param {Array} bulletList of items to display in two columns.
   * @param {String} fontName name for list items.
   * @param {String} fontSize value with number and units. i.e. 3vw
   * 
   * @return {Object} one column table with bullet list.
   *
   */
  function oneColumnBulletList(bulletList, fontName, fontSize) {
    let col1Widest = 0;
    let col1WidestPX = 0;
    let textWidthInPX = 0;
    for(let i in bulletList) {
      if(parseInt(i)>=0 && parseInt(i)<bulletList.length) {
        if(bulletList[i].length>col1Widest) {
          col1Widest = bulletList[i].length;
        }
        textWidthInPX = getFontSizeOfTextFitToViewportForFontSize(bulletList[i], fontSize, false, 0, 16) ;
        textWidthInPX = getPixelWidthOfTextForNamedFontSize(bulletList[i],textWidthInPX+'px', fontName, false);
        if(textWidthInPX>col1WidestPX) {
          col1WidestPX = textWidthInPX;
        }
      }
    }
    appFunctions.logLocalHostOnly("1col");
    return (
      <table key={"table1"+props.keyName}>
        <tbody key={"body1"+props.keyName}>  
            <tr key={"t1row1"+props.keyName}>
                <td key={"t1td1"+props.keyName}width="100%"
                  style={{ font:props.fontName, fontSize:fontSize, display: 'inline-block', textAlign: 'center'}}>
                      <ul key={"t1ul1"+props.keyName} 
                          width="100%"  
                          style={{ font:props.fontName, fontSize:fontSize, textAlign: 'left'
                                  }}>
                        {bulletList.map((item, index) => {
                                if((index>=0 && index<bulletList.length)) {
                                    return <li key={"t1li"+props.keyName+index}
                                                width="100%"
                                                style={{ font:props.fontName, fontSize:fontSize
                                                      }}
                                            >{item}</li>
                                }
                                return null;
                            })
                        }
                      </ul>
                </td>
            </tr>
        </tbody>
      </table>
    );
  }


  /**
   * Display formatted projectData properties.
   * 
   * A project icon.
   * 
   * List of technologies used to build the project.
   * 
   * A description of the project.
   * 
   * A set of link buttons for docs and any implementations.
   * 
   * An icon to display screen shots of project using Lightbox.
   */
   if(projectData === undefined ) {
    let tytr=0;
   }
      if(projectData.name === undefined ) {
    let tytoor=0;
   }
  let name = projectData.name;
  let keyName=name.replace("/","");
  let headerFontSize="5vw";
  let paragraphFontSize="3vw";
  let logoWidth="50%";
  let scrollWidth =  window.innerWidth - document.documentElement.clientWidth;
  let windowWidth= window.innerWidth-scrollWidth;
  logoWidth = windowWidth*.5
  if(appFunctions.isMobileDevice())  {
    logoWidth=windowWidth*.75;
    headerFontSize="7vw";
    paragraphFontSize="4vw";
    let vpWidth = window.innerWidth;
    if(vpWidth < 450) {
      headerFontSize="7vw";
      paragraphFontSize="4vw";
    }
  }
  let logoHeight = appFontSizes.makeNumberPixels(parseInt(logoWidth/(6/2)));
  logoWidth = appFontSizes.makeNumberPixels(logoWidth)
  const Header = {
    margin: '2px 2px',
    padding: '1px 1px',
    textAlign: 'center',
    color: 'black',
    font: props.fontName,
    fontSize: headerFontSize,
    fontWeight: 'bold'
  }
  
  const Paragraph = {
    margin: '2px 2px',
    padding: '1px 1px',
    textAlign: "left",
    color: 'black',
    font: props.fontName,
    fontSize: paragraphFontSize,
    whiteSpace: 'pre-wrap'

  }
  appMetaTags.setMetaData(identityData,projectData);
  return (<>
    {/*<div key="viewMainDiv" align="center">*/}
      <DivGroupFunction key="viewMainDivGroupFunction">
        {/*<div key={"viewbody"+keyName} >*/}
          <img src={projectData.image} 
              alt={projectData.imageAlt} 
              width={logoWidth}
              height={logoHeight}
              onMouseDown={mouseDownHandler}
              key={"viewImg"+keyName} 
          />
        
          <p key={"viewp1"+keyName} style={Header}>{projectData.head2}</p>
          <p key={"viewp2"+keyName} style={Paragraph}>{projectData.head2P}</p>
          <p key={"viewp3"+keyName} style={Header}>{projectData.head1}</p>
          {appFunctions.isMobileDevice() &&
            <div key={"viewhead"+keyName}  align='center'>
              {oneColumnBulletList(projectData.head1List, props.fontName, paragraphFontSize)}
            </div>
          }
          {!appFunctions.isMobileDevice() &&
            twoColumnBulletList(projectData.head1List, props.fontName, paragraphFontSize)
          }
          
          {projectData.methods1List !== undefined && projectData.methods1List.length > 0 &&
            <div key={"viewmethods"+keyName}  align='center'>
                <p key={"viewmethodsp"+keyName} style={Header}>{projectData.methods1}</p>
                {oneColumnBulletList(projectData.methods1List, props.fontName, paragraphFontSize)}
            </div>
          }
          {projectData.links !== undefined && projectData.links.length > 0 &&
            <div key={"viewlinks"+keyName} >
              {projectData.links.map((link,index) =>
                {
                  return <a key={"linkRef"+keyName+index.toString()} href={link.href} alt={link.alt} target={"link.target"} rel='noopener' >
                            <StyledButtonModFunction key={"link"+index.toString()} text={link.text} title={"Show "+link.target}></StyledButtonModFunction >
                        </a>
                }
              )}
            </div>
          }
          {projectData.lightbox2 !== undefined && projectData.lightbox2.length > 0 &&
            <div key={"viewlightbox"+keyName}> 
                <h4 key={"viewlightboxh4"+keyName} style={Header}>{projectData.head3}</h4>
                <StyledButtonModFunction 
                  key={"hclightboxlinkbutton"+props.keyId}
                  text="Lightbox"
                  selectionHandler={gotoSelectedLocation}
                  project={keyName}
                  title={"View "+keyName+"'s lightbox."}>
                </StyledButtonModFunction >
            </div>
          }
     {/*   </div>*/}
      </DivGroupFunction>
    {/*</div>*/}
  </>);
}

 