/** 
 * @fileOverview Display a Dropdown with an icon to activate and select navigation options.  
 *
 * @module NavMenuFunction
 *
 * @author Philip Gottfried
 */
import React, {Component} from "react";

import * as appFunctions from "../modules/AppFunctions";
import DropdownFunction from "./DropdownFunction";
import {useNavigate} from "react-router-dom";

/**
* Implements display of drop down menu with and an icon and navigation locations that
* is set to work from the right side of a container.  
* 
* Dropdown with menuMap, size and handler set to treat as Router path or http href,
* otherwise 404 Router page.  
*  
* Adds the default default items of Home and Previous.  
*  
* Initialize gotoSelectedLocation for Dropdown selectionHandler.  
*
* @function NavMenuFunction
*
* @param {*} props 
* 
* @prop {boolean} isAppLoggedIn indicating login state.
* @prop {number} width of drop down button width, default 50.
* @prop {number} height of drop down button height, default 50.
*
* @return {Object} DropdownFunction for menuMap align on right of container. 
*/
export default function NavMenuFunction(props) {
        const navigate = useNavigate();
        appFunctions.logLocalHostOnly("NavMenu construct");
        let navMenuWidth=50;
        let navMenuHeight=50;
        if(props.width !== undefined) {
            navMenuWidth=props.width;
        }
        if(props.height !== undefined) {
            navMenuHeight=props.height;
        }
    

    /**
     * Goto selected location.  
     * 
     * When location starts with http a window is opened in new tab,
     * otherwise location is replaced on navigate.
     *
     * @function gotoSelectedLocation
     * 
     * @param {*} selection the menuItem selected.
     */
    function gotoSelectedLocation(selection) { 
        let location = menuMap.menuItems[selection];
        if(location.toLowerCase().indexOf("http")===0) {
           window.open(location, "menuclick") || window.location.replace(location);
           return;
        } 
        if(location.toLowerCase().indexOf("/previous")===0) {
            /* navigate(-1);
            */
            window.history.back();//navigate(-1); gives error if no history
            return;
         }
         if(location.toLowerCase().indexOf("/forward")===0) {
            window.history.forward();
            return;
         }
        navigate(location);
    }

    let menuMap={menuItems:{Home: "/"}};
    menuMap.menuItems["Previous"]="/Previous";
    return <DropdownFunction 
            direction="right"
            offset={0}
            menuMap={menuMap}
            width={navMenuWidth}
            height={navMenuHeight}
            selectionHandler={gotoSelectedLocation}
            iconImage="images/assets/parallaxGoPerspective.svg"
            color='black'
            backgroundColor="white"
        />
}