/** InputTextCompFunction
 * @fileOverview An input of type text or textarea with mouse over highlighting, selectable
 * and activatable control with text selection highlighting, as you type validation, loss of focus
 * validation and optional form result handling.  
 * 
 * @module InputTextCompFunction
 *
 * @author Philip Gottfried
 */
import React, {PureComponent} from 'react';

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
import * as validators from "../modules/StyledInputTextValidator";
import {getPixelWidthOfTextForNamedFontSize} from "../modules/AppFontSizes";

/**
* Implements a component containing an input tag of 
* type text or textarea with embossed look, mouse over highlighing, selectable, 
* activatable and stylable including as you type  and lost focus validation.  
* 
* Styled to match StyledButton and StyledInputButton and support FormJSON result handling.  
*
*
* Initialize with color, background, active and border colors, 
* defaults are: black, white, gray and gainsboro/dimgray.  
* 
* The type property allows for textarea or text.  While text is the default,
* any text that is longer than inputTextWidthMax uses a textarea for edit,
* with the wrap set to soft. i.e., no new lines.  
* 
* When width of text is over its schema size it expands up to inputTextWidthMax.  
* 
* When the type is textarea, new lines are included in result as
* wrap is set to hard. i.e., new lines included.  
*
* Also width, align, border width, pad left/right have appDefaults.  
*  
* The top/left border is lighter than the bottom/right for an embossed look, 
* with selection and activation swapping the top/left and bottom/right
* colors. i.e., simulate pushed in/out.  
*  
* The click, double click and focus colors are matched to aliceblue so that
* visually they appear the same. i.e., standard input bluish colors 
* for the background and selection while editing.  
*  
* Inputs have a controlled property that defaults to false causing the defaultValue property to
* be initialized from the props.text and the active color is set to appDefaults.inputActiveColor, 
* otherwise the value property is set from props.text and the active color is set to 
* appDefaults.inputActiveColorControlled.  
* 
* @function InputTextCompFunction
*
* @param {Object} props property object
* 
* @prop {String} text value for input.
* @prop {String} align value for text alignment. i.e. left, right or center.
* @prop {String} color of text.
* @prop {String} backgroundColor of input.
* @prop {String} activeColor of mouse over.
* @prop {String} title for tool tip popup, default is undefined
* @prop {String} keyId for input tag's id and name.
* @prop {number} width for an input of type text in characters.
* @prop {String} borderColorTL of top and left color for border.
* @prop {String} borderColorBR of bottom and right color for border.
* @prop {String} padLeft of input in pixels.
* @prop {String} padright of input in pixels.
* @prop {String} borderWidth of input in pixels.
* @prop {String} borderStyle of input. i.e. none, solid, ridge 
* @prop {String} type of text edit is textarea or text input.
* @prop {number} rows for a textarea, text area uses characters for size.
* @prop {number} cols for a textarea
* @prop {String} fontName of font to use, default is courier new.
* @prop {String} fontSize of font to use, default is 21px.
* @prop {function} onChangeResultHandler sends on change validation result to form.
* @prop {function} focusOutResultHandler ends editing of text or text area on form.
* @prop {String} form the name of the form.
* @prop {function} setLiveFormValueFromIndex to update form. 
* @prop {String} keySeparator for form input labels.
* @prop {function} getSchemaRecord  for the input field by name.
* @prop {object} schemaRecord for validation without a form. 
*
* @return {inputTextInstance} for a text textarea input
*/
export default function InputTextCompFunction(props) {
  let controlledText = props.text;
  let clickCounter = 0, lastClickTimer = 0;

  let focusOutResult = {invalid:false,message: "",name: ""};
  let changeResult = {reject:false,invalid:false,message: ""};
  if(props.keyId.indexOf("field")>=0) {
    appFunctions.logLocalHostOnly("fields input "+props.keyId); 
  }
  let schemaRecord = {};
  if(isPartOfForm()) {
    const name = props.keyId;
    const labelNames = name.split(props.keySeparator);
    const fieldName = labelNames[0]+props.keySeparator;
    if(props.schemaRecord !== undefined) {//form input not in form schema, but needs validation. i.e. addIndex
      schemaRecord = props.schemaRecord;
    } else {//form input that uses form schema
      schemaRecord = props.getSchemaRecord(labelNames[0]);
    }
  } else {//non-form input with validation
    if(props.schemaRecord !== undefined) {
      schemaRecord = props.schemaRecord;
    }
  }
  let kSV = true;//indicate perform key stroke validations. i.e. support incomplete
  let placeHolder = "";
  if(schemaRecord.placeHolder !== undefined) {
    placeHolder =  schemaRecord.placeHolder;
  }
  let undoValue = "";
  let oldValue = "";//set by onFocus
  let defaultTitle = props.title;
  let invalid = false;
  let invalidMessage = "";
  let currentText = props.text;


  let inputTextWidthMax = 50;
  let inputTextType = 'text';
  if(props.type !== undefined) {
    inputTextType = props.type;
  }

  let inputTextControlled = false;
  if(props.controlled !== undefined) {
    inputTextControlled = props.controlled;
  }

  let styledInputTextWrap = "hard";
  let syledInputTextSize = inputTextWidthMax;
  if(props.width !== undefined) {
    syledInputTextSize = props.width;
  }
  let areaColumns = inputTextWidthMax;
  if(props.cols !== undefined) {
    areaColumns = props.cols;
  }
  let areaRows = "10";
  if(props.rows !== undefined) {
    areaRows = props.rows;
  }

  if(inputTextType === 'text') {
    if( props.text.length > inputTextWidthMax) {
      inputTextType = 'textarea';
      styledInputTextWrap = "soft";
      areaRows = (props.text.length/areaColumns)+1;
    } 
  }

  let styledInputTextClicked = false;
  let styledInputTextDblClicked = false;

  let styledInputTextSelected = false;
  let styledInputTextFocused = false;

  let styledInputTextMouseDown = false;
  let styledInputTextMouseUp = false;
  let styledInputTextMouseStartMove = false;
  let styledInputTextMouseEndMove = false;
  let styledInputTextMouseDrag = false;

  let styledInputTextMouseDownEvent = false;
  let styledInputTextMouseUpEvent = false;
  let styledInputTextMouseMoveEndEvent = false;
  let styledInputTextMouseMoveStartEvent = false;
  let styledInputTextMouseDragEvent = false;


  let styledInputTextAlign = "left";
  if(props.align !== undefined) {
    styledInputTextAlign = props.align;
  }
    
  let styledInputTextFontSize = "21px";
  if(props.fontSize !== undefined) {
    styledInputTextFontSize = props.fontSize;
  }
  let styledInputTextFont = appDefaults.inputFontName;
  if(props.fontName !== undefined) {
    styledInputTextFont = props.fontName;
  }

  let styledInputTextColor=appDefaults.inputColor;
  let styledInputTextBackgroundColor=appDefaults.inputBackgroundColor;
  let styledInputTextActiveColor=appDefaults.inputActiveColor;
  if(inputTextControlled) {
    styledInputTextActiveColor=appDefaults.inputActiveColorControlled;
  } 
  let styledInputTextFocusColor=appDefaults.inputFocusColor;
  let styledInputTextSelectColor="lightyellow";
  let styledInputTextSelectTextColor="red";
  let styledInputTextClickedColor="aliceblue";
  let styledInputTextDblClickedColor="aliceblue";
  if(props.color !== undefined) {
    styledInputTextColor=props.color;
  }
  if(props.backgroundColor !== undefined) {
    styledInputTextBackgroundColor=props.backgroundColor;
  }
  if(props.activeColor !== undefined) {
    styledInputTextActiveColor=props.activeColor;
  }

  let styledInputTextBorderWidth = appDefaults.borderWidth;
  if(props.borderWidth !== undefined) {
    if(isNaN(props.borderWidth)) {
      styledInputTextBorderWidth = props.borderWidth;
    } else {
      styledInputTextBorderWidth = props.borderWidth+"px";
    }
  }
  let styledInputTextBorderStyle = appDefaults.borderStyle;
  if(props.borderStyle !== undefined) {
    styledInputTextBorderStyle = props.borderStyle;
  }
  let styledInputTextPadLeft = "5px";
  if(props.padLeft !== undefined) {
    styledInputTextPadLeft = props.padLeft+"px";
  }
  let styledInputTextPadRight = styledInputTextPadLeft;
  if(props.padRight !== undefined) {
    styledInputTextPadRight = props.padRight+"px";
  }
  let styledInputTextBorderTopColor=appDefaults.borderColorTL;
  let styledInputTextBorderBottomColor=appDefaults.borderColorBR;
  let styledInputTextBorderLeftColor=styledInputTextBorderTopColor;
  let styledInputTextBorderRightColor=styledInputTextBorderBottomColor;
  if(props.borderColorTL !== undefined) {
    styledInputTextBorderTopColor=props.borderColorTL;
    styledInputTextBorderLeftColor=props.borderColorTL;
  }
  if(props.borderColorBR !== undefined) {
    styledInputTextBorderBottomColor=props.borderColorBR;
    styledInputTextBorderRightColor=props.borderColorBR;
  }

  /**
   * Indicate input is invalid, empty or incomplete.  
   *
   * @function isInvalid
   *
   * @return {Boolean} of invalid global
   */
  function isInvalid() {
    return invalid;
  }
  /**
   * Indicate input is part of a form and can notify form onChange and onBlur.  
   *
   * @function isPartOfForm
   *
   * @return {Boolean} True when part of a form, otherwise false.
   */
  function isPartOfForm() {
    let pof = false;
    if(props.form !== undefined) {
   // let formElement = document.getElementById(props.form);
    //let pof = formElement.attr(props.id);
        pof = true;
      
    }
    return pof;
  }
   /**
   * Indicate input has a schema for validation.  
   *
   * When input is part of form the schema comes from the form, otherwise it is
   * supplied by the validationSchema property.  
   *
   * @function hasValidationSchema
   *
   * @return {Boolean} True when schema is set, otherwise false.
   */
  function hasValidationSchema() {
    let set = false;
    if(schemaRecord !== undefined) {
      set = true;
    }
    return set;
  }
  /**
   * Set inputChanged state to reverse of current value. i.e. a changed value.  
   * 
   * this should force component to render as state changed.  
   *
   * @function inputChanged
   */
  function inputChanged() {
    const vc=0;
  }
  /**
  * Reset focus out result.  
  *
  * @function resetFocusOutResult
  */
  function resetFocusOutResult() {
    focusOutResult = {invalid:false,message: "",name: ""};
  }
  /**
   * Set style for styledInputText border width, style and color using top, left, bottom and right 
   * border colors with an option to swap the colors to indicate on mouse over and out.  
   * 
   * i.e., an embossed edge look that swaps border colors when selected or
   * activated to simulate pushed in/out.  
   *
   * @function setStyleForInputBorder
   * 
   * @param {Object} target input tag. 
   * @param {Boolean} reverse of true swaps top/left and bottom/right colors.
   */
  function setStyleForInputBorder(target, reverse) {
    setFocusStyleForInputBorder(target, reverse, styledInputTextBorderTopColor, styledInputTextBorderBottomColor);
  }

  /**
   * Set focus style for styledInputText border width, style and color using top, left, bottom and right 
   * border colors with an option to swap the colors for indicating focus even when on mouse over and out.  
   * 
   * i.e., an embossed edge look that swaps border colors when selected or
   * activated to simulate pushed in/out.  
   *
   * @function setFocusStyleForInputBorder
   * 
   * @param {Object} target input tag.
   * @param {Boolean} reverse of true swaps top/left and bottom/right colors.
   * @param {String} topColor for top/left border when focus is active
   * @param {String} bottomColor for bottom/right border when focus is active
   */
  function setFocusStyleForInputBorder(target, reverse, topColor, bottomColor) {
    let tlColor = topColor;
    let brColor = bottomColor;
    if(invalid) {
      if(reverse) {
        tlColor = appDefaults.controlErrorColorBR;
        brColor = appDefaults.controlErrorColorTL;
      } else {
        tlColor = appDefaults.controlErrorColorTL;
        brColor = appDefaults.controlErrorColorBR;
      }
    }
    if(reverse) {
      target.style.borderTop=styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+brColor;
      target.style.borderLeft=styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+brColor;
      target.style.borderBottom=styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+tlColor;
      target.style.borderRight=styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+tlColor;    
    } else {
      target.style.borderTop=styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+tlColor;
      target.style.borderLeft=styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+tlColor;
      target.style.borderBottom=styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+brColor;
      target.style.borderRight=styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+brColor;    
    }
  }
  /**
   * Set styledInputText background for selection mode as determined by 
   * dblclicked, clicked, selected and focused gain/loss boolean values, when
   * none are true the default color is used.  i.e., not selected/activated.  
   * 
   * i.e., set selection mode color or defaultColor.  
   * 
   * dblclick, click, focus and selected color set for event boolean,
   * the default is to use the same color for these as it is the standard,
   * but it was useful in debugging to be able to tell focus from click.  
   *
   * @function setStyleForInputMode
   * 
   * @param {Object} target input tag. 
   * @param {String} defaultColor value when no selection mode is true.
   */
  function setStyleForInputMode(target, defaultColor) {
    if(invalid) {
       target.style.color = appDefaults.controlErrorColor; 
        return;
    }
    if(styledInputTextDblClicked) {
        target.style.background = styledInputTextDblClickedColor;
        target.style.color = styledInputTextColor;
        return;
    } 
    if(styledInputTextClicked) {
        target.style.background = styledInputTextClickedColor;
        target.style.color = styledInputTextColor;
        return;
    }
    if(styledInputTextFocused) {
        target.style.background = styledInputTextFocusColor;
        target.style.color = styledInputTextColor;
        return;
    } 
    if(styledInputTextSelected) {
        target.style.background = styledInputTextSelectColor;
        target.style.color = styledInputTextSelectTextColor;
        return;
    }
    target.style.background = defaultColor;
    target.style.color = styledInputTextColor; 
    if(invalid) {
       target.style.color = appDefaults.controlErrorColor; 
    }
    return;
  }

  /**
   * Mouse is over input, set background to styledInputTextActiveColor taking account
   * of focus.  
   * 
   * i.e., add highlight when mouse over.  
   *
   * @function onMouseOverStyledInputText
   * 
   * @param {Object} event mouse over on input tag.
   */
  function onMouseOverStyledInputText(event) {
    event.preventDefault();
    event.persist();
    setStyleForInputMode(event.target, styledInputTextActiveColor);
   // event.target.style.opacity = styledInputTextActiveOpacity;
    //appFunctions.logLocalHostOnly("mouse over styledInputText "+event.currentTarget.textContent.toLowerCase());  
    if(styledInputTextFocused) {
      setFocusStyleForInputBorder(event.target, false, appDefaults.inputSelectedColorTL, appDefaults.inputSelectedColorBR);
    } else {
      setStyleForInputBorder(event.target, true);
    }
  }

  /**
  * Mouse is out of input, set background to styledInputTextBackgroundColor taking account
  * of focus.  
  * 
  * i.e., remove highlight when mouse out.  
  *
  * @function onMouseOutStyledInputText
  * 
  * @param {Object} event mouse out on input tag.
  */
 function onMouseOutStyledInputText(event) {
    event.preventDefault();
    event.persist();
    //appFunctions.logLocalHostOnly("mouse out styledInputText t="+event.target.textContent.toLowerCase());
    setStyleForInputMode(event.target, styledInputTextBackgroundColor);
    //event.target.style.opacity = styledInputTextOpacity;
    if(styledInputTextFocused) {
        setStyleForInputBorder(event.target, true);
    } else {
        setStyleForInputBorder(event.target, false);
    }
  }

  /**
   * Handle focus on input, set activated when gain focus.  
   *
   * @function onFocusStyledInputText
   * 
   * @param {Object} event focus on input tag.
   */
  function onFocusStyledInputText(event) {
    appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" focus "+event.target.name);
    event.preventDefault();
    undoValue = event.target.value;
    styledInputTextFocused = true;
    setStyleForInputMode(event.target, styledInputTextActiveColor);
    //event.target.style.opacity = styledInputTextActiveOpacity;
    //appFunctions.logLocalHostOnly("focus gained styledInputText "+event.currentTarget.textContent.toLowerCase());  
    setFocusStyleForInputBorder(event.target, true, appDefaults.inputSelectedColorTL, appDefaults.inputSelectedColorBR);
  }
  /**
   * Handle on before input, save oldValue before key stroke.  
   *
   * @function onBeforeInputsStyledInputText
   * 
   * @param {Object} event on before input key stroke.
   */
  function onBeforeInputsStyledInputText(event) {
    appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" onBefore "+event.target.name+' '+event.target.value);
    oldValue = event.target.value;
  }
  /**
   * handle on input, perform key stroke and whole value validation.  
   * 
   *
   * @function onInputStyledInputText
   *
   * @param {Object} event on input of key stroke.
   */
  function onInputStyledInputText(event) {
    appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" onInput "+event.target.name+' '+event.target.value, oldValue);
    event.preventDefault();
    let result = validateOnInput(event);
    if(props.focusOutResultHandler !== undefined) {
      props.focusOutResultHandler(result);//
    }
    inputChanged();
  }
   /**
   * Handle on change.  
   *
   * @function onChangeStyledInputText
   * 
   * @param {Object} event on change after key stroke.
   */
  function onChangeStyledInputText(event) {
     event.preventDefault();
     appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" onChange "+event.type+" "+event.target.name+' '+event.target.value, oldValue);
  }
  /**
   * Handle on blur, calls focusOutResultHandler property from form.  
   *
   * @function onBlurStyledInputText
   * 
   * @param {Object} event on change after key stroke.
   */
  function onBlurStyledInputText(event) {
    appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" onBlurStyle "+event.type+" "+event.target.name+' '+event.target.value, oldValue);
    let result = validateOnBlur(event);
    result["reject"] = false;
    if(props.focusOutResultHandler !== undefined) {
      props.focusOutResultHandler(result,props.form);//
    }
    setStyleForValidation(event);
  }


    /**
     * Validate input to target name input as each character is entered. i.e., onInput event.  
     * 
     * Validation of key stoke is perform to verify that they are a valid value for the rule such as
     * a numeric can not have an alpha character or an integer can not have a decimal point.  
     *
     * Additional validation is performed on the whole value but is a bit relaxed when not enough characters
     * exist yet as in an email, negative number or phone number.  When focus is lost the whole value is
     * validated.  
     *
     * This is an as you type validation for web posting.  The input should be escaped when
     * storing the values posted with fetch to a php script.  
     * 
     * A target type of checkbox uses the target checked property for the value,
     * while all other types use the target value.  
     * 
     * The input controls are controlled, in that they use the onInput event for each key press.
     * A useStatesWithInputs indicates if the controls use states and have value initialized or
     * are stateless and are initialized using defaultValue.constructor.  
     *
     * The default is to NOT use states on the controlled inputs as it seemed to be really slowing things down.  
     *
     * The name of the target is a keySeparator delimited list of values that index either 
     * a state value, with controlled inputs, or the json object liveFormData.  
     *
     * The return object has reject, invalid and message members to indicate if the key stroke 
     * was accepted/rejected and if the whole value is valid.  When invalid the message
     * gives the reason.  When key stroke is rejected, the value of the target is reset to
     * value before key stroke.
     *
     * @function validateOnInput
     * 
     * @param {Object} event input tag change event.
     *
     * @return {Object} result of validation. default is {reject:false,invalid:false,message: ""} i.e. valid
     */
    function validateOnInput(event) {
        appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" "+props.keyId+" validateOnInput "+currentText);
        let result = {fieldName:props.keyId,reject:false,invalid:false,message: ""};
        const target = event.target;
        if(target.type === 'checkbox') return result;
        const value = target.value;
        const selectionRange = target.createTextRange;
        const selectionStart = target.selectionStart;
        const selectionEnd = target.selectionEnd;
        
        const defaultValue = target.defaultValue;
        let name = target.name; 

        let keySeparator = "";
        let labelNames = [];
        let fieldName = "";//super.name;
        if(isPartOfForm()) {
          keySeparator = props.keySeparator;
          labelNames = name.split(keySeparator);
          fieldName = labelNames[0]+keySeparator;
        }
        let keyedValue = value.toString().substring(selectionStart-1,selectionEnd);
        if(value.toString().length === 0) {
            //appFunctions.logLocalHostOnly("new length is 0");
            //delete key or cut on only one character or all selected text
        }
        if(value.toString().length < oldValue.length ) {
            //appFunctions.logLocalHostOnly("new length is less than old length");
            //delete key or cut on at least one or more characters
            if(keyedValue === '') {

            }
        }
        if(value.toString().length === oldValue.length ) {
            //appFunctions.logLocalHostOnly("new length is equals old length");
            //edit on at least one selected character or paste one selected characters
        }
        if(value.toString().length > oldValue.length ) {
            //appFunctions.logLocalHostOnly("new length is greater than old length");
            //edit on at least one character or paste one or more characters
            if(value.toString().length === oldValue.length+1) {
                //appFunctions.logLocalHostOnly("new length is equal to old length + 1");
                //edit on at least one character or paste one or more characters
            }
            if(value.toString().length > oldValue.length+1) {
                //appFunctions.logLocalHostOnly("new length is greater than old length by 1");
                //paste two or more characters
            }
        }
        
        if(hasValidationSchema() && kSV && keyedValue !== '') {
          result = validators.applyKeyValidationRule(keyedValue,value.toString(),oldValue,schemaRecord);
        }
        result["fieldName"] = props.keyId;
        invalid = false;
        //appFunctions.logLocalHostOnly("changed",name, target.type, fieldName);
        if(!result["reject"]) {//key accepted
          if(isPartOfForm()) {
            props.setLiveFormValueFromIndex(value.toString(), labelNames, schemaRecord);
          }
          if(result["invalid"]) {//incomplete or invalid when valid key is allowed
            target.style.color = appDefaults.controlErrorColor;
            target.title = result["message"];
            invalid = true;
          } else {
            target.title = defaultTitle;
            target.style.color = styledInputTextColor;
          }
          //oldValue = target.value;
         target.value = value.toString();
         currentText = value.toString();
        } else {//rejected key
          appFunctions.logLocalHostOnly("validateOnInput key rejected",name, target.type, fieldName,  target.value, oldValue, currentText);
          currentText = oldValue;
          target.value = oldValue;
          let valueResult = validators.valueValidationRule(oldValue, schemaRecord);
          valueResult['fieldName'] = props.keyId;
          if(valueResult["invalid"]) {//key rejected and old value is invalid or incomplete
           // target.value = value.toString();
            target.title = result["message"];
            target.style.color = appDefaults.controlErrorColor;
            invalid = true;
            result['invalid'] = valueResult['invalid'];
            result['message'] = result['message']+' '+valueResult['message'];
          } else {//rejected keyed value but old value is valid 
            target.title = defaultTitle;
            target.style.color = styledInputTextColor;
            invalid = false;
          }
        }
     //   if(isPartOfForm()) {
          
         //   inputTextInstance.form.form.setState({[name]:target.value});
          
        /*  if(props.useStatesWithInputs) {
              props.setState({[name]:target.value});
          } */
      //  }
        return result;
    }
      /** 
    * Validate on blur for the whole value based on the schema validation rule.  
    * 
    * Uses hasValidationSchema to perform optional validation.  
    *
    * Sets invalid and message to indicate validation result,
    * so the border shows error color or not.  
    *
    * note: value is invalid when the rule allows an incomplete entry or
    * can not be empty/required.
    *
    * @function validateOnBlur
    *
    * @param {Object} event on blur.
    *
    * @return {Object} result of validation with invalid and message members.
    */
    function validateOnBlur(event) {
        appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" validateOnBlur "+currentText);
        let result = { fieldName:props.keyId,reject:false,invalid:false,message: ""};
        const target = event.target;
        if(target.type === 'checkbox') return result;
        const value = target.value;
        const defaultValue = target.defaultValue;
        let name = target.name; 

        let keySeparator = "";
        let labelNames = [];
        let fieldName = "";//super.name;
        if(isPartOfForm()) {
          keySeparator = props.keySeparator;
          labelNames = name.split(keySeparator);
          fieldName = labelNames[0]+keySeparator;
        }
        if(hasValidationSchema()) {
          result = validators.applyValueValidationRule(value.toString(), schemaRecord);
        }
        result["fieldName"] = fieldName;
        if(result["invalid"]) {//incomplete or invalid when valid key is allowed
          target.style.color = appDefaults.controlErrorColor;
          target.title = result["message"];
          invalid = true;
        } else {
          target.style.color = styledInputTextColor;
          target.title = defaultTitle;
          invalid = false;
        }
      return result;
  }

    /**
    * Indicate target has error by changing the color and title.  When the delay
    * is greater than zero, display the error for the delay time. 
    *
    * @function indicateKeyedError
    * 
    * @param {Object} target input tag that had error.
    * @param {String} message to display in tool tip. i.e. title
    * @param {Number} delay in milliseconds before resetting color and title, do not reset if delay is zero.
    *
    */
    function indicateKeyedError(target, message, delay) {
        let oldTip = target.title;
        let backgroundColor = target.style.background;
        target.title = message;
        target.style.color = appDefaults.controlErrorColor;
        if(delay > 0) {
            setTimeout(() => {
                target.title = oldTip;
                target.style.color = backgroundColor;
            }, delay);
        }
    }

  /**
  * Blur on input, set background to styledInputTextBackgroundColor
  * and reset click and focus flags.  
  * 
  * i.e., input lost focus.
  *
  * @function setStyleForValidation
  * 
  * @param {Object} event on blur of input tag.
  */
  function setStyleForValidation(event) {
    //appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" blur - focus lost",event.target.id);
    styledInputTextSelected = false;
    styledInputTextFocused = false;
    styledInputTextClicked = false;
    styledInputTextDblClicked = false;
    if(!invalid) {
      event.target.style.background = styledInputTextBackgroundColor;
      event.target.style.color = styledInputTextColor;
      //event.target.style.opacity = styledInputTextOpacity;
    } else {
      event.target.style.background = styledInputTextBackgroundColor;
      event.target.style.color = appDefaults.controlErrorColor;
      //event.target.style.opacity = styledInputTextOpacity;
    }
    setStyleForInputBorder(event.target, false);
  }

  /**
  * Click on input, initial click sets background to styledInputTextClickedColor,
  * styledInputTextClicked to true and select the input contents.  
  * 
  * Upon subsequent clicks, the position of mouseMove start and end are used
  * to calculate the character position clicked and select the range of text.
  * When the start and end range are the same the caret will insert instead
  * of selecting a range.  
  * 
  * i.e. select input on 1st click, position caret or select text there after.  
  *
  * @function onClickStyledInputText
  * 
  * @param {Object} event on click of input tag.
  */
  function onClickStyledInputText(event) {
      let newClickTime = Date.now();
      event.persist();
      
      let delay = 200;
      let clickCount = clickCounter+1;
      const lastClickTime = lastClickTimer;
      
      //appFunctions.logLocalHostOnly("mouse clk "+clickCount);
      let timer;
      if (clickCount === 1) {
        //appFunctions.logLocalHostOnly("sgl before clk "+clickCount);
          timer = setTimeout(() => {
              //appFunctions.logLocalHostOnly("sgl in timer "+clickCount);
              onSingleClickStyledInputText(event);
              clickCount=0;
              //appFunctions.logLocalHostOnly("sgl done timer "+clickCount);
            }, delay);
          //appFunctions.logLocalHostOnly("sgl clk after timer "+clickCount);
      }
      //appFunctions.logLocalHostOnly("clk after single check "+clickCount);
      if (clickCount === 2) {
        clearTimeout(timer);
        const elapsed = newClickTime - lastClickTime; 
        //appFunctions.logLocalHostOnly("dbl clk elp=",elapsed);
        onDoubleClickStyledInputText(event)
        clickCount=0;
      };
      //appFunctions.logLocalHostOnly("clk after double check "+clickCount);
      clickCounter = clickCount;
      lastClickTimer = newClickTime;
  }

  /**
  * Click on input, initial click sets background to styledInputTextClickedColor,
  *  styledInputTextClicked to true and select the input contents.  
  * 
  * Upon subsequent clicks, the position of mouseMove start and end are used
  * to calculate the character position clicked and select the range of text.  
  *
  * When the start and end range are the same the caret will insert instead
  * of selecting a range.  
  * 
  * i.e. select on click.
  *
  * @function onSingleClickStyledInputText
  * 
  * @param {Object} event on click of input tag.
  */
  function onSingleClickStyledInputText(event) {
      let selection = window.getSelection();
      let range = document.createRange();

      let downOffsetX =  event.nativeEvent.offsetX;
      let upOffsetX =  event.nativeEvent.offsetX;
      let moveOffsetXStart =  event.nativeEvent.offsetX;
      let moveOffsetX =  event.nativeEvent.offsetX;
      let leftCharacterPosition = 0;
      let rightCharacterPosition = 0;
      let renderString = event.target.value;
      //appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" click id="+event.target.id);
      if(styledInputTextClicked) {
          if(moveOffsetX !== downOffsetX) {
            //appFunctions.logLocalHostOnly("moved ",moveOffsetX, downOffsetX, downOffsetX-moveOffsetX)
          }
          if(styledInputTextMouseDown && styledInputTextMouseUp) {
              downOffsetX =  styledInputTextMouseDownEvent.nativeEvent.offsetX;
              upOffsetX =  styledInputTextMouseUpEvent.nativeEvent.offsetX;
              if(styledInputTextMouseEndMove) {
                moveOffsetX =  styledInputTextMouseMoveEndEvent.nativeEvent.offsetX;
                moveOffsetXStart =  styledInputTextMouseMoveStartEvent.nativeEvent.offsetX;
                //appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" move while down="+event.target.id,moveOffsetXStart,moveOffsetX,moveOffsetXStart-moveOffsetX);
              }
          }
          let sizeOfCharacters=0;
          leftCharacterPosition=-1;
          rightCharacterPosition=-1;
          let lrPos;
          for(let c in renderString) {
            if(styledInputTextMouseEndMove) {
              if(moveOffsetXStart<moveOffsetX) {
                lrPos = getLeftRightPosition(leftCharacterPosition, rightCharacterPosition, moveOffsetXStart, moveOffsetX, event.target.value[c], sizeOfCharacters);
              } else {
                lrPos = getLeftRightPosition(leftCharacterPosition, rightCharacterPosition, moveOffsetX, moveOffsetXStart, event.target.value[c], sizeOfCharacters);
              }
            } else {
              lrPos = getLeftRightPosition(leftCharacterPosition, rightCharacterPosition, downOffsetX, upOffsetX, event.target.value[c], sizeOfCharacters);    
            }
            leftCharacterPosition=lrPos.left;
            rightCharacterPosition=lrPos.right;
            sizeOfCharacters=lrPos.size;
          }
          if(leftCharacterPosition < 0) leftCharacterPosition = 0;
          if(rightCharacterPosition < 0) rightCharacterPosition = 0;
          //appFunctions.logLocalHostOnly(event.clientX, event.target.clientWidth, leftCharacterPosition, rightCharacterPosition);
          event.target.setSelectionRange(leftCharacterPosition, rightCharacterPosition);
          styledInputTextMouseDown = false;
          styledInputTextMouseUp = false;
          styledInputTextMouseStartMove = false;
          styledInputTextMouseEndMove = false;
          styledInputTextMouseDrag = false;
          return;
      }

      //initiate clicked, set background to styledInputTextClickedColor,
      // styledInputTextColor, styledInputTextActiveOpacity, swapped 
      // border and all text selected
    // event.preventDefault();
      styledInputTextClicked = true;
     setStyleForInputMode(event.target, styledInputTextClickedColor);
      event.target.style.color = styledInputTextColor;
      //event.target.style.opacity = styledInputTextActiveOpacity; 
      setStyleForInputBorder(event.target, true);
      event.target.select();
  }
  /**
  * Double click on input, set background to styledInputTextDblClickedColor,
  *  styledInputTextActive to true and select the input contents.  
  * 
  * i.e. select on dblclick.  
  *
  * @function onDoubleClickStyledInputText
  * 
  * @param {Object} event on click of input tag.
  */
  function onDoubleClickStyledInputText(event) {
      //appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" dblclick "+event.target.id);
      let selection = window.getSelection();
      let range = document.createRange();
      let downOffsetX =  event.nativeEvent.offsetX;
      let upOffsetX =  event.nativeEvent.offsetX;
      let moveOffsetX =  event.nativeEvent.offsetX;
      let moveOffsetXStart =  event.nativeEvent.offsetX;
      if(styledInputTextDblClicked) {
        if(styledInputTextMouseDown && styledInputTextMouseUp) {
          downOffsetX =  styledInputTextMouseDownEvent.nativeEvent.offsetX;
          upOffsetX =  styledInputTextMouseUpEvent.nativeEvent.offsetX;
          if(styledInputTextMouseEndMove) {
            moveOffsetX =  styledInputTextMouseMoveEndEvent.nativeEvent.offsetX;
            moveOffsetXStart =  styledInputTextMouseMoveStartEvent.nativeEvent.offsetX;
            if(moveOffsetX !== downOffsetX) {
              //appFunctions.logLocalHostOnly("moved ",moveOffsetX, downOffsetX, moveOffsetXStart-moveOffsetX)
            }
          }
      }
      
      let sizeOfOneCharacter;
      let sizeOfCharacters=0;
      let leftCharacterPosition=-1;
      let rightCharacterPosition=-1;
      for(let c in event.target.value) {
          let lrPos = getLeftRightPosition(leftCharacterPosition, rightCharacterPosition, downOffsetX, upOffsetX, event.target.value[c], sizeOfCharacters)
          if(styledInputTextMouseEndMove) {
            if(moveOffsetXStart<moveOffsetX) {
              lrPos = getLeftRightPosition(leftCharacterPosition, rightCharacterPosition, moveOffsetXStart, moveOffsetX, event.target.value[c], sizeOfCharacters);
            } else {
              lrPos = getLeftRightPosition(leftCharacterPosition, rightCharacterPosition, moveOffsetX, moveOffsetXStart, event.target.value[c], sizeOfCharacters);
            }
          } else {
            lrPos = getLeftRightPosition(leftCharacterPosition, rightCharacterPosition, downOffsetX, upOffsetX, event.target.value[c], sizeOfCharacters);    
          }
          leftCharacterPosition=lrPos.left;
          rightCharacterPosition=lrPos.right;
          sizeOfCharacters=lrPos.size;
      }
      if(leftCharacterPosition < 0) leftCharacterPosition = 0;
      if(rightCharacterPosition < 0) rightCharacterPosition = 0;
      //appFunctions.logLocalHostOnly(event.clientX, event.target.clientWidth, leftCharacterPosition, rightCharacterPosition);
      event.target.setSelectionRange(leftCharacterPosition, rightCharacterPosition);
      styledInputTextMouseDown = false;
      styledInputTextMouseUp = false;
      styledInputTextMouseStartMove = false;
      styledInputTextMouseEndMove = false;
      styledInputTextMouseDrag = false;
      return;
      }
      //event.preventDefault();
      
      styledInputTextDblClicked = true;
      setStyleForInputMode(event.target, styledInputTextDblClickedColor);
      event.target.style.color = styledInputTextColor;
      //event.target.style.opacity = styledInputTextActiveOpacity; 
      setStyleForInputBorder(event.target, true);
      event.target.select();
  }

  /**
  * Get the left and right positions of text selected after mouse click.  
  * 
  * Translate left/right pixel offsets, from left of control, 
  * to character left and right positions.  
  * 
  * Convert a character to width in pixels, accumulating the size left and right
  * values while the size of characters is less than the left offset continue
  * with the right value while less than the right offset.  
  *
  * @function getLeftRightPosition
  * 
  * @prop {number} left the current left character position of selection.
  * @prop {number} right the current right character position of selection.
  * @prop {number} leftOffset the left most mouse move position.
  * @prop {number} rightOffset the right most mouse move position.
  * @prop {String} text the string value that may have a selection.
  * @prop {number} charSize the size, in pixels, of selection.
  *
  * @return {Object} 
  */
  function getLeftRightPosition(left, right, leftOffset, rightOffset, text, charSize) {
    let leftCharacterPosition = left;
    let rightCharacterPosition = right;
    let sizeOfOneCharacter = getPixelWidthOfTextForNamedFontSize(text, styledInputTextFontSize, styledInputTextFont, false);
    let sizeOfCharacters = charSize + (sizeOfOneCharacter-1);
    if(sizeOfCharacters<=leftOffset) {
        leftCharacterPosition++;
        rightCharacterPosition++;
    } else {
      if(sizeOfCharacters<=rightOffset) {
          rightCharacterPosition++;
      }
    }
    return {left:leftCharacterPosition, right:rightCharacterPosition, size:sizeOfCharacters};
  }

  /**
  * Select on input, set background to styledInputTextSelectColor,
  *  styledInputTextSelected to true and select the input contents.  
  * 
  * i.e. , onClick 
  *
  * @function onSelectStyledInputText(event) 
  * 
  * @param {Object} event on select of input tag.
  */
  function onSelectStyledInputText(event) {
      //appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" select");
      if(styledInputTextSelected) {
          event.target.setSelectionRange(event.target.selectionStart, event.target.selectionEnd);
          return;
      }
      event.preventDefault();
      styledInputTextSelected = true;
      setStyleForInputMode(event.target, styledInputTextSelectColor);
      //event.target.style.background = styledInputTextSelectColor;
      event.target.style.color = styledInputTextColor;
     // event.target.style.opacity = styledInputTextActiveOpacity;
      //appFunctions.logLocalHostOnly("onClick styledInputText "+event.currentTarget.textContent.toLowerCase());  
      setStyleForInputBorder(event.target, true);
      event.target.setSelectionRange(event.target.selectionStart, event.target.selectionEnd);
    //event.target.select();
  }

  /**
   * Prevent mouse down from allowing drag on input.  
   * 
   * Track mouse down event and reset mouse up.
   *
   * @function onMouseDownStyledInputText
   * 
   * @param {Object} event on mouse down of input tag.
   */
  function onMouseDownStyledInputText(event) {
    event.preventDefault();
    event.persist();
    styledInputTextMouseDown = true;
    styledInputTextMouseUp = false;
    styledInputTextMouseStartMove = false;
    styledInputTextMouseEndMove = false;
    styledInputTextMouseDownEvent = event;
  }

  /**
   * Mouse up event sets styledInputTextMouseUp and styledInputTextMouseUpEvent.  
   * 
   * i.e., Track mouse up event, happens after down and move but before click.  
   *
   * @function onMouseUpStyledInputText
   * 
   * @param {Object} event on mouse up  of input tag.
   */
  function onMouseUpStyledInputText(event) {
    event.preventDefault();
    event.persist();
    styledInputTextMouseUp = true;
    styledInputTextMouseUpEvent = event;
    if(styledInputTextDblClicked) {
      //appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" mouse up double click")
    }
  }

   /**
   * Mouse move sets styledInputTextMouseMoveStartEvent and styledInputTextMouseMoveEndEvent
   * to track mouse move event.  
   *
   * @function onMouseMoveStyledInputText
   * 
   * @param {Object} event mouse moveo of input tag.
   */
  function onMouseMoveStyledInputText(event) {
    ////appFunctions.logLocalHostOnly("styledInputText mouse move");
    event.preventDefault();
    if(styledInputTextMouseDown && !styledInputTextMouseUp) {
      event.persist();
      if(!styledInputTextMouseEndMove) {
        styledInputTextMouseMoveStartEvent = event;
      }
      styledInputTextMouseMoveEndEvent = event;
      styledInputTextMouseEndMove = true;
      //appFunctions.logLocalHostOnly("styledInputText mouse down move"+event.nativeEvent.offsetX," dblclk=",styledInputTextDblClicked);
    }
  }

  /**
   * Return a inputTextInstance of an HTML input tag when inputTextType is text or
   * an HTML textarea tag when inputTextType is textarea.  
   *
   * A inputTextControlled value of true sets the tags property value to props.text, otherwise
   * sets the tags property defaultValue to props.text.  Controlled text of false is the default
   * and prefered mode as changes can be reverted to the defaultValue.  
   *
   */

  appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" render pt="+currentText+" ");
  let inputTextInstance;
  if(inputTextType === 'textarea' ) {
    inputTextInstance = (<textarea 
      id={props.keyId}
      name={props.keyId}
      title={"area: "+props.title}
      wrap={styledInputTextWrap}
      cols={areaColumns}
      rows={areaRows}
      style={{
        position: 'relative',
        backgroundColor: styledInputTextBackgroundColor,
        color: styledInputTextColor,
        fontSize: styledInputTextFontSize,
        font: styledInputTextFont,
        textAlign: styledInputTextAlign,
        margin: '0px 0px',
        paddingTop: '5px',
        paddingLeft: styledInputTextPadLeft,
        paddingBottom: '5px',
        paddingRight: styledInputTextPadRight,
        cursor: 'pointer',
        borderRadius: '10px',
        boxSizing: 'border-box', 
        borderTop: styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+styledInputTextBorderTopColor,
        borderLeft: styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+styledInputTextBorderLeftColor,
        borderBottom: styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+styledInputTextBorderBottomColor,
        borderRight: styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+styledInputTextBorderRightColor
        
      }}
      key={"inputstyledInputTextArea"+props.keyId}
      defaultValue={currentText}
      //value={state.controlledText}
      placeholder={placeHolder}
      //onSelect={onSelectStyledInputText}
      //onMouseDown={onMouseDownStyledInputText}
      //onMouseUp={onMouseUpStyledInputText}
      onMouseOver={onMouseOverStyledInputText}
      onMouseOut={onMouseOutStyledInputText}
      onFocus={onFocusStyledInputText}
      onBeforeInput={onBeforeInputsStyledInputText}
      onInput={onInputStyledInputText}
      onChange={onChangeStyledInputText}
      onBlur={(event) => {
        appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" onBlur "+event.type+" "+event.target.name+' '+event.target.value);
        onBlurStyledInputText(event);
      }}
      //onClick={onClickStyledInputText}
      //onMouseMove={onMouseMoveStyledInputText}
    /> );
  } else {
    inputTextInstance = (<input 
      id={props.keyId}
      name={props.keyId}
      type='text' 
      size={syledInputTextSize}
      title={props.title}
      style={{
        position: 'relative',
        backgroundColor: styledInputTextBackgroundColor,
        color: styledInputTextColor,
        fontSize: styledInputTextFontSize,
        fontKerning: 'none',
        font: styledInputTextFont,
        textAlign: styledInputTextAlign,
        margin: '0px 0px',
        paddingTop: '5px',
        paddingLeft: styledInputTextPadLeft,
        paddingBottom: '5px',
        paddingRight: styledInputTextPadRight,
        cursor: 'pointer',
        borderRadius: '10px',
        boxSizing: 'border-box', 
        borderTop: styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+styledInputTextBorderTopColor,
        borderLeft: styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+styledInputTextBorderLeftColor,
        borderBottom: styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+styledInputTextBorderBottomColor,
        borderRight: styledInputTextBorderWidth+" "+styledInputTextBorderStyle+" "+styledInputTextBorderRightColor
        
      }}
      key={"inputstyledInputText"+props.keyId}
      defaultValue={currentText}
      //value={state.controlledText}
      placeholder={placeHolder}
      //onSelect={onSelectStyledInputText}
      //onMouseDown={onMouseDownStyledInputText}
      //onMouseUp={onMouseUpStyledInputText}
      onMouseOver={onMouseOverStyledInputText}
      onMouseOut={onMouseOutStyledInputText}
      onFocus={onFocusStyledInputText}
      onBeforeInput={onBeforeInputsStyledInputText}
      onInput={onInputStyledInputText}
      onChange={onChangeStyledInputText}
      onBlur={(event) => {
        appFunctions.logLocalHostOnly("styledInputText "+props.keyId+" onBlur "+event.type+" "+event.target.name+' '+event.target.value, oldValue);
        onBlurStyledInputText(event);
      }}
      // onClick={onClickStyledInputText}
      //onMouseMove={onMouseMoveStyledInputText}
    />);
  }
  //appFunctions.logLocalHostOnly("render text instance "+props.title);
  return (
    inputTextInstance
  )
  
}
