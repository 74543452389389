/** 
 * @fileOverview A styled input button component with mouse over highlighing, selectable
 * and activatable.  
 * 
 * @module StyledInputButtonCompFunction
 *
 * @author Philip Gottfried
 */
import React, { Component, useState} from 'react';
import styled from 'styled-components';

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";

const StyledInputButtonBaseInstance = styled.input.attrs((props) => ({
        key:"styledbutton"+props.keyId+props.text,
        title:props.title || "",
        active:false,
        type:props.type || 'button',
        name:props.name || ''
})) `
    position: relative;
    z-Index:0;
    border-radius: 15px;
    border-top:thick ridge rgba(150,150,150,1);
    border-left:thick ridge rgba(150,150,150,1);
    border-right:thick ridge rgba(75,75,75,1);
    border-bottom:thick ridge rgba(75,75,75,1);
    width: ${props => props.width || ""};
    color: ${props => props.color || appDefaults.controlColor};
    opacity: 1;
    background-color: ${props => props.backgroundColor || appDefaults.controlBackgroundColor};
    font-size: ${props => (appFunctions.isMobileDevice() ? '2vw' : '1vw')};
    text-align: ${props => props.align || 'center'};
    margin: 0px 0px;
    padding-top: 5px;
    padding-left: ${props => props.padLeft || '5px'};
    padding-bottom: 5px;
    padding-right: ${props => props.padRight || '5px'};
    cursor: 'pointer';
    `; 
/**
* Implements input button with embossed look, mouse over highlighing, selectable,
* activatable and stylable.  
* 
* Uses:  
* <ul>
* <li>A link button for a tag or a Link component.</li>
* <li>A menu item that returns the selected value.</li>
* <li>A group item that returns the selected value. i.e., a radio button.</li>
* <li>A navigation button that shows active for matching last node of url.</li>
* </ul>
*
* 
* Initialize with color, background, active and border colors, 
* defaults are: white, black, gray and gainsboro/silver.  
* 
* Also width, align, border width, pad left/right,  
* 
* The top/left border is lighter than the bottom/right for an embossed look.  
* 
* @function StyledInputButtonCompFunction
*
* @param {Object} props property object
* 
* @prop {String} text value for button.
* @prop {String} align value for text alignment. i.e. left, right or center.
* @prop {String} color of text.
* @prop {String} backgroundColor of buttton.
* @prop {String} activeColor of mouse over.
* @prop {String} keyId for key uniqueness.
* @prop {function} selectionHandler to return text property on click.
* @prop {number} width for fixed width button, as in a menu.
* @prop {String} borderColorTL of top and left color for border.
* @prop {String} borderColorBR of bottom and right color for border.
* @prop {String} padLeft of button in pixels.
* @prop {String} padright of button in pixels.
* @prop {String} borderWidth of button in pixels.
* @prop {String} borderStyle of button. i.e. none, solid, ridge ...
* @prop {String} toggle button type, no, yes, route. default no.
*
* @return {Object} input button tag
*/
export default function StyledInputButtonCompFunction(props) {
   /* state = {
        activeToggleName: ""
    };*/
    const [activeToggleName, setActiveToggleName] = useState("");
    if(props.keyId.indexOf("field")>=0) {
            appFunctions.logLocalHostOnly("IB keyId=",props.keyId);
    }
    let styledInputButtonAlign = "center";
    if(props.align !== undefined) {
        styledInputButtonAlign = props.align;
    }
    let styledInputButtonToggleOpacity=1;
    let styledInputButtonToggleActiveOpacity=0.6;
        
    let styledInputButtonFont = "1vw";
    if(appFunctions.isMobileDevice()) {
        styledInputButtonFont = "2vw";
    }
    let styledInputButtonColor=appDefaults.controlColor;
    let styledInputButtonBackgroundColor=appDefaults.controlBackgroundColor;
    let styledInputButtonActiveColor=appDefaults.controlActiveColor;
    let styledInputButtonSelectedColor=appDefaults.controlSelectedColor;
    if(props.color !== undefined) {
        styledInputButtonColor=props.color;
    }
    if(props.backgroundColor !== undefined) {
        styledInputButtonBackgroundColor=props.backgroundColor;
    }
    if(props.activeColor !== undefined) {
        styledInputButtonActiveColor=props.activeColor;
    }

    let styledInputButtonToggle = "no";
    if(props.toggle !== undefined) {
        styledInputButtonToggle = props.toggle;
    }

    let styledInputButtonBorderWidth = appDefaults.borderWidth;
    if(props.borderWidth !== undefined) {
        if(isNaN(props.borderWidth)) {
        styledInputButtonBorderWidth = props.borderWidth;
        } else {
        styledInputButtonBorderWidth = props.borderWidth+"px";
        }
    }
    let styledInputButtonBorderStyle = appDefaults.borderStyle;
    if(props.borderStyle !== undefined) {
        styledInputButtonBorderStyle = props.borderStyle;
    }
    let styledInputButtonPadLeft = "5px";
    if(props.padLeft !== undefined) {
        styledInputButtonPadLeft = props.padLeft+"px";
    }
    let styledInputButtonPadRight = styledInputButtonPadLeft;
    if(props.padRight !== undefined) {
        styledInputButtonPadRight = props.padRight+"px";
    }
    let styledInputButtonBorderTopColor=appDefaults.borderColorTL;
    let styledInputButtonBorderBottomColor=appDefaults.borderColorBR;
    let styledInputButtonBorderLeftColor=styledInputButtonBorderTopColor;
    let styledInputButtonBorderRightColor=styledInputButtonBorderBottomColor;
    if(props.borderColorTL !== undefined) {
        styledInputButtonBorderTopColor=props.borderColorTL;
        styledInputButtonBorderLeftColor=props.borderColorTL;
    }
    if(props.borderColorBR !== undefined) {
        styledInputButtonBorderBottomColor=props.borderColorBR;
        styledInputButtonBorderRightColor=props.borderColorBR;
    }


  /**
   * Mouse is over toggle button, set background to styledInputButtonActiveColor.
   * 
   * @function mouseOverStyledButton
   *
   * @param {Object} event for mouse over.
   */
  function mouseOverStyledButton(event) {
    event.preventDefault();
    if(isToggleRouteActive()) {
      event.target.style.background = styledInputButtonSelectedColor;
      event.target.style.opacity = styledInputButtonToggleOpacity;
      event.target.style.borderTop=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderTopColor;
      event.target.style.borderLeft=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderLeftColor;
      event.target.style.borderBottom=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderBottomColor;
      event.target.style.borderRight=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderRightColor;

    } else {
      event.target.style.background = styledInputButtonActiveColor;
      event.target.style.opacity = styledInputButtonToggleActiveOpacity;
      ////appFunctions.logLocalHostOnly("mouse over styledInputButton "+event.currentTarget.textContent.toLowerCase());  
      event.target.style.borderTop=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderBottomColor;
      event.target.style.borderLeft=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderRightColor;
      event.target.style.borderBottom=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderTopColor;
      event.target.style.borderRight=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderLeftColor;
    }
}

/**
  * Mouse is out of toggle button, set background to styledInputButtonActiveColor and 
  * when href matches the textContext otherwise use the styledInputButtonBackgroundColor.  
  * 
  * i.e. highlighted when over.
  *
  * @function mouseOutStyledButton
  * 
  * @param {Object} event for mouse out.
  */
 function mouseOutStyledButton(event) {
    event.preventDefault();
    let ref = appFunctions.getRouteFromLocationHref();
    if(!(event.target.textContent.toLowerCase() === ref.toLowerCase())) {
      //appFunctions.logLocalHostOnly("ref="+ref+" t="+event.target.textContent.toLowerCase());
      event.target.style.background = styledInputButtonBackgroundColor;
      event.target.style.opacity = styledInputButtonToggleOpacity;
      event.target.style.borderTop=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderTopColor;
      event.target.style.borderLeft=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderLeftColor;
      event.target.style.borderBottom=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderBottomColor;
      event.target.style.borderRight=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderRightColor;
    } else {
        //appFunctions.logLocalHostOnly("ref="+ref+" t="+event.target.textContent.toLowerCase());
        if(isToggleRouteActive()) {
          event.target.style.background = styledInputButtonSelectedColor;
        } else {
          event.target.style.background = styledInputButtonActiveColor;
        }
        event.target.style.opacity = styledInputButtonToggleActiveOpacity;
        event.target.style.borderTop=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderBottomColor;
        event.target.style.borderLeft=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderRightColor;
        event.target.style.borderBottom=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderTopColor;
        event.target.style.borderRight=styledInputButtonBorderWidth+" "+styledInputButtonBorderStyle+" "+styledInputButtonBorderLeftColor;
    }
    //appFunctions.logLocalHostOnly("mouse out taggle button "+event.currentTarget.textContent.toLowerCase());   
  }

  /**
   * Prevent mouse down from allowing drag on button.
   *
   * @function mouseDownStyledButton
   * 
   * @param {Object} event for mouse down.
   */
  function mouseDownStyledButton(event) {
    event.preventDefault();
  }

  /**
   * Upon did mount, when toggle property is route, set the activeToggleName and 
   * when the text property matches the current route. i.e., the last node of href.  
   * 
   * So the button shows active selection on first render when
   * the button matchs the end of the url.
   *
   * @function componentDidMount
   */
  function componentDidMount() { 
    let curl = window.location.href;
    let refs = curl.split("/");
    let ref = refs[refs.length-1];
    ////appFunctions.logLocalHostOnly("did mount styled button "+props.text.toLowerCase()+" ref="+ref.toLowerCase()); 
    if(isToggleRoute && props.text.toLowerCase() === ref.toLowerCase()) {
      //appFunctions.logLocalHostOnly("set styled activeToggleName "+ref); 
     // setState({ activeToggleName: ref });
      setActiveToggleName(ref);
    } 
  }

  /**
   * Determine if the toggle property is not no. i.e. button toggles.  
   *
   * @function isToggle
   */
  function isToggle() {
    return styledInputButtonToggle.toLowerCase() !== "no";
  }

  /**
   * Determine if the toggle property is for a route. i.e. is active when text property 
   * matches the last node of the url.
   *
   * @function isToggleRoute
   */
  function isToggleRoute() {
    return styledInputButtonToggle.toLowerCase() === "route";
  }

  /**
   * Determine if the activeToggleName matches the text property. i.e. the text property
   * matches the last node of the url.  
   *
   * @function isToggleRouteActive
   */
  function isToggleRouteActive() {
    let activeToggle = false;
    if(isToggleRoute()) {
     // activeToggle=state.activeToggleName.toLowerCase() === props.text.toLowerCase();
      activeToggle=activeToggleName.toLowerCase() === props.text.toLowerCase();
    }
    return activeToggle;
  }

  let curl = window.location.href;
  let refs = curl.split("/");
  let ref = refs[refs.length-1];
  //appFunctions.logLocalHostOnly("styledInputButton render pt="+props.text+" st="+state.activeToggleName+" ref="+ref);
  return (
      <StyledInputButtonBaseInstance
          key={"styledinputbutton"+props.keyId+props.text}
          id={"styledinputbutton"+props.keyId+props.text}
          name={"styledinputbutton"+props.keyId+props.text}
          value={props.text}
          onClick={(event) => {
              //appFunctions.logLocalHostOnly("styledInputButton clicked "+props.text+"ref="+ref);
              if(props.selectionHandler !== undefined) {
                props.selectionHandler(event);
                return;
              }
              if(isToggle()) {
                setActiveToggleName(props.text);
              }
            }}
          onMouseDown={mouseDownStyledButton}
          onMouseOver={mouseOverStyledButton}
          onMouseOut={mouseOutStyledButton}
      />
  )
}
