/** 
 * @fileOverview Display a form for editing data using schema properties with
 * an option to preview and save edited data.  Provides for special contact form
 * that emails input to the Portfolio contact email.  
 * 
 * @module FormJSONFunction
 *
 * @author  Philip Gottfried
 *
 */
import React, {useState, useEffect, createRef, forwardRef} from "react";
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
import * as appMetaTags from "../modules/AppMetaTags";
import * as appCache from "../modules/AppCache";

import FormMenuFunction from "./FormMenuFunction";
import InputTextCompFunction from "./InputTextCompFunction";
import StyledInputButtonModFunction from "./StyledInputButtonModFunction";

/**
* Implements display of data using schema properties as an editable form.  
*
* The schema is a json object of field and type information.  
* The json is a list of field name keys with key/values for field attributes:  
* <ul>
* <li>type - data format for name.</li>
* <ul>
* <li>String - any valid string.</li>
* <li>StringArray - an array of strings.</li>
* <li>Object - an array of json objects with key fields.</li>
* <ul>
* <li>fields - only when type is Object.</li>
* <ul>
* <li>name - string name of field.</li>
* <li>type - data format for field.</li>
* <ul>
* <li>String - any valid string.</li>
* <li>StringArray - an array of strings.</li>
* </ul> 
* <li>label - string for display of name.</li>
* <li>width - default width used for input.</li>
* <li>validation - input validation value.</li>
* <li>placeHolder - input place holder pattern.</li>
* <li>minimum - optional minimum value for validation..</li>
* <li>maximum - optional maximum value for validation..</li>
* <li>canBeEmpty - boolean indicates an empty value is valid.</li>
* <ul>
* <li>None - can contain any character.</li>
* <li>String - only letters</li>
* <li>Integer - only integer value.</li>
* <li>Real - only real value.</li>
* <li>URL - only valid url.</li>
* </ul>
* </ul>
* </ul>
* </ul>
* <li>label - string for display of name.</li>
* <li>width - default width used for input.</li>
* <li>validation - input validation value.</li>
* <li>placeHolder - input place holder pattern.</li>
* <li>minimum - optional minimum value for validation..</li>
* <li>maximum - optional maximum value for validation..</li>
* <li>canBeEmpty - boolean indicates an empty value is valid.</li>
* <ul>
* <li>None - can contain any character.</li>
* <li>String - only letters</li>
* <li>Integer - only integer value.</li>
* <li>Real - only real value.</li>
* <li>URL - only valid url.</li>
* </ul>
* </ul>
* 
* The data is a json object of data to display.
*
* Initialize base form settings.
* 
* @function FormJSONFunction
*
* @param {Object} props property object
* 
* @prop {object} schema json object of field data.
* @prop {object} jsonFormData json object of form data.
* @prop {String} formTitle for display.
* @prop {String} formName for id, name and key values for controls. i.e. no spaces.
* @prop {String} proot for key uniqueness.
* @prop {String} fontSize default size of font for form input, default 2vw.
* @prop {String} size default number of characters for form input text, default 35.
* @prop {String} areaRows default number of rows for form input textarea, default 10.
* @prop {String} areaColumns default number of columns for form input area, default 35.
* @prop {String} cookie session value for post. default is email.
* @prop {String} fromRoute route to display after form submit handler. 
*/
export default function FormJSONFunction(props) {
    const navigate = useNavigate();
    const formRef = createRef();
    const [formChanged, setFormChanged] = useState(false);

   useEffect(() => {
                   // if(liveFormData === undefined) {
            let js = JSON.stringify(props.jsonFormData);
            //liveFormData = JSON.parse(js);//copy original data before any edits
       // } 

        return () => {
            let v=0;
        }
        }, []);

     useEffect(() => {
      // if(liveFormData === undefined) {
            let js = JSON.stringify(props.jsonFormData);
            liveFormData = JSON.parse(js);//copy original data before any edits
       // } 

        return () => {
            let v=0;
        }
        }, []);

    let formInvalid = false;
    /*
    * dataStatus, with the FormMenu, allows the render() to not rerender 
    * all the form fields while also providing an indicators that the 
    * form is saved, in preview or email mode.
    */
    let dataStatus = "Saved";
    if(props.jsonFormDataStatus !== undefined) {
        dataStatus = props.jsonFormDataStatus;
    }
    if(dataStatus === "Reload") {
        dataStatus = "Saved";
    }

    let formMode='Initialized';
    let fromRoute = '/';
    if(props.fromRoute !== '') {
        fromRoute = props.fromRoute;
    }
    if(formChanged) {
        let s=0;
        formMode='Thankyou';
 
    }
    let keySeparator = ":";
    let undoPrefix = 'undo';
    let emptyIndicator = "@@empty@@";

    let size = '35';
    if(props.size !== undefined) {
        size = props.size;
    }

    let areaColumns = "35";
    if(props.areaColumns !== undefined) {
        areaColumns = props.areaColumns;
    }
    let areaRows = "10";
    if(props.areaRows !== undefined) {
        areaRows = props.areaRows;
    }

    let fontSize = "2vw";
    if(props.fontSize !== undefined) {
        fontSize = props.fontSize;
    }

    let objectEdits = false;//form has array and object lists.
    let fieldsEdits = false;//form is editting a schema 

    let invalidFields = [];//list of field name
    let invalidMessages = [];//list of associated error messages

    let contactThankyouData = {}
    let contactMessage = {}
    let inputRef = {}
    let undoObject = {};
    let tableRowCounter = 0;
    let widestEdit = 0;

    //let useStatesWithInputs = false;
    /*if(useStatesWithInputs) {
        let qt = setFormItemStates(props.jsonFormData, props.proot);
        appFunctions.logLocalHostOnly("state start");
    }*/
    let liveFormData = [];


        
    /**
    * Set formChanged state to reversed value.  
    *
    * @function swapFormChanged
    */
    function swapFormChanged() {
        dataStatus = "Edited";
        //const { formChanged } = state;
        //setState({formChanged:!formChanged});
        setFormChanged(!formChanged);
    }
    /**
     * Indicate if any objects are found in the data property,
     * the form input states are set.   
     * 
     * When no objects, the form table does not include columns
     * for the Add , Index and Delete of inputs.  
     *
     * note: so objectEdits can be evaluated in a render return.  
     *
     * @function isObjectEdits
     *
     * @return {Boolean} value of objectEdits
     */
    function isObjectEdits() {
        return objectEdits;
    }

    /**
     * Indicate if any fields are found in the data property,
     * the form input states are set.  
     * 
     * When no fields, the form table does not include columns
     * for the Add , Index and Delete of inputs.  
     *
     * note: so fieldsEdits can be evaluated in a render return.  
     *
     * @function isFieldsEdits
     *
     * @return {Boolean} value of fieldsEdits
     */
    function isFieldsEdits() {
        return fieldsEdits;
    }

    /**
     * Indicate if the formTitle is Contact Form.  
     * 
     * Contact Form uses a different set of font and input sizes.  
     *
     * note: so props.formTitle can be evaluated in a render return.  
     *
     * @function isContactForm
     *
     * @return {Boolean} True when formTitle property equals Contact Form.
     */
    function isContactForm() {
        return props.formTitle === 'Contact Form';
    }

    /**
     * Indicate dataStatus property is status value.  
     *
     * Note: So dataStatus can be evaluated in a render return.  
     * 
     * @function isDataStatus
     *
     * @param {String} status to compare
     *
     * @return {Boolean} True when dataStatus equals dataStatus property.
     */
    function isDataStatus(status) {
        return dataStatus === status;
    }

    /**
     * Indicate formMode property is mode value. 
     *
     * note: so formMode can be evaluated in a render return.  
     * 
     * @function isFormMode
     *
     * @param {String} mode to compare
     *
     * @return {Boolean} True when mode equals formMode property, otherwise false.
     */
    function isFormMode(mode) {
        return formMode === mode;
    }

    /**
     * Is object field of string array determines if labelListLen greater than or equal to five.  
     *
     * note: so labelListLen can be evaluated in a render return.  
     * 
     * @function isObjectFieldOfStringArray
     *
     * @param {Number} labelListLen value to check if 
     * 
     * @return {Boolean} True when labelListLen greater than or equal to five, otherwise false.
     */
    function isObjectFieldOfStringArray(labelListLen) {
        let length = 5;
        if(isFieldsEdits()) {
            length = 6;
        }
        return labelListLen >= length;;
    }

    /**
     * Get schema record for field name or default schema record when field name
     * is not found.  
     *
     * A default schema record for the field name has a type of object with the
     * field name as the label, width of default size, no validation rule and
     * can be empty.
     * 
     * @function getSchemaRecord
     *
     * @param {String} fieldName of schema record. 
     * 
     * @return {Object} schema record for fieldName or default schema record.
     */
    function getSchemaRecord(fieldName) {
        let schemaRecord = {
                "type": "Object",
                "fields":[
                    {"name": "type", "type": "String", "label": "Type","width":size,"validation": "None","default": ""},
                    {"name": "label", "type": "String", "label": "Label","width":size,"validation": "None","default": ""},
                    {"name": "width", "type": "String", "label": "Width","width":size,"validation": "None","default": ""},
                    {"name": "validation", "type": "String", "label": "Validation","width":size,"validation": "None","default": ""},
                    {"name": "canBeEmpty", "type": "String", "label": "Can be empty","width":size,"validation": "None","default": ""}
                ],
                "label":fieldName,
                "width":size,
                "validation": "None",
                "canBeEmpty": "true"
        };
        if(props.schema !== undefined) {
            if(props.schema[fieldName] !== undefined) {
                schemaRecord = props.schema[fieldName];
            } else {
                appFunctions.logLocalHostOnly(fieldName+" is not a schema prperty!");
            }
        }
        return schemaRecord;
    }
    /**
    * Handle validation result visuals on input key stroke by
    * using indexed list of invalid fields and messages.
    * 
    * Look up fieldName with getInvalidFieldIndex.  
    *  when invalid, update add field and message list.  
    *  when valid remove from field and message list if it exists and
    *    if the field list is empty, set the form as valid.  
    *
    * @function handleFocusOutResult
    *
    * @param {Object} result with invalid, fieldName and message members.
    * @param {form} form that contains input text field.
    */
    function handleFocusOutResult(result, form) {
        appFunctions.logLocalHostOnly("forms - handleFocusOutResult "+result['fieldName']+" "+result['invalid']+' '+result['message']);
        let index = getInvalidFieldIndex(result['fieldName']);
        if(result['invalid']) {//invalid or incomplete
            formInvalid = true;
            if(index>=0) {
                invalidMessages[index] = result['message'];
            } else {
                invalidFields.push(result['fieldName']);
                invalidMessages.push(result['message']);
            }
//            swapFormChanged();
        } else {//valid, remove fieldName from list
            if(index>=0) {
                invalidFields.splice(index,1);
                invalidMessages.splice(index,1);
//                swapFormChanged();
            }
            if(invalidFields.length>0) {
                formInvalid = true;
            } else {
                formInvalid = false;
            }
        }
        //handleInputChangeResult(result);
   //     swapFormChanged();//focus out for input
    }
    /**
     * Handle input change result to indicate form has been 
     * edited after onChange event for StyledInputText components.  
     * 
     * StyledInputText onChange validation rejects invalid key
     * strokes, the value could still be invalid and is checked on
     * focusOut in handleFocusOutResult.
     *
     * may be depricatedinpts use onInput instead of onChange.
     *
     * @function handleInputChangeResult
     *
     * @param {Object} result of change event validation result.
     */
    function handleInputChangeResult(result) {
        swapFormChanged();//input changed 
    }

    /**
    * Set liveFormData value using labelNames as index and schemaRecord for
    * field type to set.  
    * 
    * @function setLiveFormValueFromIndex
    *
    * @param {String} value to put in liveFormData.
    * @param {Array} labelNames list of index values.
    * @param {Object} schemaRecord json schema for form data.
    *
    * @return {String} comma delimited string of liveFormData index or value at index of liveFormData.
    */
    function setLiveFormValueFromIndex(value, labelNames, schemaRecord) {
        switch(schemaRecord.type) {
            case "StringArray":
                liveFormData[labelNames[0]][labelNames[1]] = value;
                break;
            case "Object":
                const objectFields = schemaRecord.fields;
                let fieldType = "";
                for(let objectField in objectFields) {
                    if(objectFields[objectField].name === labelNames[2]) {
                        fieldType = objectFields[objectField].type;
                        break;
                    }
                }
                if(fieldType === "String") {
                    liveFormData[labelNames[0]][labelNames[1]][labelNames[2]] = value; 
                }
                if(fieldType === "StringArray") {
                    liveFormData[labelNames[0]][labelNames[1]][labelNames[2]][labelNames[3]] = value;
                } 
                break;
            case "String":
            case "TextArea":
            default:
                liveFormData[labelNames[0]] = value;
                break;  
        }
    }
    /**
    * Get liveFormData value or index for labelNames.
    *
    * @function getLiveFormIndexOrValue
    *
    * @param {String} type indicate requested value is either index or value.
    * @param {Array} labelNames list of index values.
    * @param {Object} schemaRecord json schema for form data.
    *
    * @return {String} comma delimited string of liveFormData index or value at index of liveFormData.
    */
    function getLiveFormIndexOrValue(type, labelNames, schemaRecord) {
        let liveFormIndex = "";
        let liveFormValue = "";
        switch(schemaRecord.type) {
            case "StringArray":
                liveFormIndex = labelNames[0]+","+labelNames[1];
                liveFormValue = liveFormData[labelNames[0]][labelNames[1]];
                break;
            case "Object":
                const objectFields = schemaRecord.fields;
                let fieldType = "";
                for(let objectField in objectFields) {
                    if(objectFields[objectField].name === labelNames[2]) {
                        fieldType = objectFields[objectField].type;
                        break;
                    }
                }
                if(fieldType === "String") {
                    liveFormIndex = labelNames[0]+","+labelNames[1]+","+labelNames[2];
                    liveFormValue = liveFormData[labelNames[0]][labelNames[1]][labelNames[2]]; 
                }
                if(fieldType === "StringArray") {
                    liveFormIndex = labelNames[0]+","+labelNames[1]+","+labelNames[2]+","+labelNames[3];
                    liveFormValue = liveFormData[labelNames[0]][labelNames[1]][labelNames[2]][labelNames[3]];
                } 
                break;
            case "String":
            case "TextArea":
            default:
                liveFormIndex = labelNames[0];
                liveFormValue = liveFormData[labelNames[0]];
                break;  
        }
        if(type === "index") {
            return liveFormIndex;
        } 
        return liveFormValue;
    }
 
    /** 
    * Add button click insert field to end of list or before
    * addIndex value after confirm.  
    *
    * @function handleAddButtonClick
    *
    * @param {String} dataType item to add. 
    * @param {Array} labelNames list of fields.
    * @param {Object} schemaRecord for field.
    *
    * @return {Boolean} True when add confirmed, otherwise false.
    */
    function handleAddButtonClick(event, labelNames, schemaRecord) {
        //appFunctions.logLocalHostOnly("addButton click");
        let confirm = false;
        let dataChanged = false
        let dataType = schemaRecord.type;
        let index = parseInt(labelNames[2]);
        let stateKey = "";
        let clone = {};
        let dataArray = [];
        let si = event.target.name.indexOf(keySeparator)+1;
        let li = event.target.name.length-4;
        let fieldSufix = event.target.name.substring(si,li);
        //let indexName="addIndex"+keySeparator+fieldSufix+keySeparator+"0"+keySeparator;
        //let doc = document.getElementById(indexName);
        let indexName="addIndex"+keySeparator+fieldSufix;
        let doc = document.getElementById(indexName);
        let indexInput = doc.value;
      /*  if(useStatesWithInputs) {
            indexInput = state["addIndex"+fieldSufix];
        }*/
        switch(dataType) {
            case "StringArray":
                dataArray = liveFormData[labelNames[1]];
                if(indexInput === "") {
                    indexInput = dataArray.length.toString();
                }
                indexInput = indexInput * 1;//force numeric value
                confirm = window.confirm("Add "+labelNames[1]+" at index "+indexInput);
                if(confirm) {
                    if(indexInput > dataArray.length-1) {
                        dataArray.push("");
                    }
                    if(indexInput >= 0 && indexInput <= dataArray.length-1) {
                        dataArray.splice(indexInput,0,"");
                    }
                    /*if(useStatesWithInputs) {
                        stateKey=labelNames[1]+keySeparator+indexInput.toString()+keySeparator;
                        setState({[stateKey]: ""});
                    }*/
                    dataChanged = true;
                }
                break;
            case "Object":
                const objectFields = schemaRecord.fields;
                let fieldData = {};
                let confirmMessage = "";
                let labelCount = labelNames.length;
                if(objectFields !== undefined) {
                    for(let objectField in objectFields) {
                        if(objectFields[objectField].type === "String") {
                            fieldData[objectFields[objectField].name] = "";
                            confirmMessage = confirmMessage +" >"+objectFields[objectField].name+keySeparator+"\n";
                        }
                        if(objectFields[objectField].type === "StringArray") {
                            fieldData[objectFields[objectField].name] = [""];
                            confirmMessage = confirmMessage +" >"+ objectFields[objectField].name+"[]"+keySeparator+"\n";
                        }
                    } 
                }
                switch(labelNames.length) {
                    case 5:
                        dataArray = liveFormData[labelNames[1]];
                        dataArray = dataArray[labelNames[2]];
                        dataArray = dataArray[labelNames[3]];
                        if(indexInput === "") {
                            index = dataArray.length;
                        }
                        confirm = window.confirm("Add "+labelNames[3]+" at index "+index+"\nfor field "+labelNames[1]+" of #"+labelNames[2] +"\n");
                        break;
                    default:
                        dataArray = liveFormData[labelNames[1]];
                        if(indexInput === "") {
                            index = dataArray.length;
                        }
                        confirm = window.confirm("Add to "+labelNames[1]+" at index "+index+"\nwith fields:\n"+confirmMessage);
                }
                if(confirm) {
                    switch(labelNames.length) {
                        case 5:
                            let fieldArray = liveFormData[labelNames[1]];
                            fieldArray = fieldArray[labelNames[2]];
                            if(indexInput === "" || indexInput > dataArray.length-1) {
                                dataArray.push("");
                                index =(dataArray.length);
                            }
                            if(indexInput !== "" && indexInput >= 0 && indexInput <= dataArray.length-1) {
                                dataArray.splice(indexInput,0,"");
                                index = indexInput;
                            }
                            /*if(useStatesWithInputs) {
                                stateKey=labelNames[1]+keySeparator+index.toString()+keySeparator+labelNames[3]+keySeparator+indexInput+keySeparator;
                                setState({[stateKey]:fieldArray});
                            }*/
                            break;
                        default:
                            if(indexInput === "" || indexInput > dataArray.length-1) {
                                liveFormData[labelNames[1]].push(fieldData);
                                index =(dataArray.length-1);
                            }
                            if(indexInput !== "" && indexInput >= 0 && indexInput <= dataArray.length-1) {
                                liveFormData[labelNames[1]].splice(indexInput,0,fieldData);
                                index =(dataArray.length-1);
                            }
                            /*if(useStatesWithInputs) {
                                for(let objectField in objectFields) {
                                    if(objectFields[objectField].type === "String") {
                                        stateKey=labelNames[1]+keySeparator+index.toString()+keySeparator+objectFields[objectField].name+keySeparator;
                                    }
                                    if(objectFields[objectField].type === "StringArray") {
                                        stateKey=labelNames[1]+keySeparator+index.toString()+keySeparator+objectFields[objectField].name+keySeparator+"0"+keySeparator;
                                    }
                                    setState({[stateKey]:dataArray});
                                }
                            }*/
                    }
                    dataChanged = true;
                }
                break;
            default:
        }
        return dataChanged;
    }

    /** 
    * Delete button click removes field after confirm.  
    *
    * @function handleDelButtonClick
    *
    * @param {String} dataType item to delete. 
    * @param {Array} labelNames list of fields.
    * @param {Object} schemaRecord for field.
    *
    * @return {Boolean} True when delete confirmed, otherwise false.
    */
    function handleDelButtonClick(dataType, labelNames, schemaRecord) {
        let confirm = false;
        let dataChanged = false;
        let clone = {};
        let dataArray = [];
        let stateKey = "";
        switch(dataType) {
            case "StringArray":
                dataArray = liveFormData[labelNames[1]];
                stateKey=labelNames[1]+keySeparator+labelNames[2]+keySeparator;
               /* if(useStatesWithInputs) {
                    confirm = window.confirm("Delete \n"+labelNames[1]+keySeparator+" at index "+labelNames[2]);
                } else {*/
                    confirm = window.confirm("Delete \n"+labelNames[1]+keySeparator+" at index "+labelNames[2]);
                //}
                if(confirm) {
                    liveFormData[labelNames[1]].splice(labelNames[2],1);
                    /*if(useStatesWithInputs) {// not sure about this
                        clone = Object.assign({}, state);
                        delete clone[stateKey];
                        setState(clone);
                    }*/
                    dataChanged = true;
                }
                break;
            case "Object":
                const objectFields = schemaRecord.fields;
                let confirmMessage = "";
                switch(labelNames.length) {
                    case 6:
                        confirmMessage = labelNames[3]+" #"+labelNames[4]+"\n";
                        dataArray = liveFormData[labelNames[1]];
                        dataArray = dataArray[labelNames[2]];
                        dataArray = dataArray[labelNames[3]];
                        confirm = window.confirm("Delete \n"+labelNames[1]+" at index "+labelNames[2]+"\nfor field:\n"+confirmMessage);
                        break;
                    case 7:
                        confirmMessage = labelNames[3]+" #"+labelNames[4]+" anmed "+labelNames[5]+"\n";
                        dataArray = liveFormData[labelNames[1]];
                        dataArray = dataArray[labelNames[2]];
                        dataArray = dataArray[labelNames[3]];
                        dataArray = dataArray[labelNames[4]];
                        confirm = window.confirm("Delete \n"+labelNames[1]+" at index "+labelNames[2]+"\nfor field:\n"+confirmMessage);
                        break;
                    default:
                        confirmMessage = labelNames[1]+" #"+labelNames[2]+"\n";     
                        dataArray = liveFormData[labelNames[1]];
                        confirm = window.confirm("Delete \n"+labelNames[1]+" at index "+labelNames[2]);
                }
                if(confirm) {
                    /*if(useStatesWithInputs) {
                        clone = Object.assign({}, state);
                        for(let objectField in objectFields) {
                            stateKey=labelNames[1]+keySeparator+labelNames[2]+keySeparator+objectFields[objectField].name+keySeparator;
                            delete clone[stateKey];
                        }
                        setState(clone);
                    } else {*/
                        switch(labelNames.length) {
                            case 6:
                                dataArray.splice(labelNames[4],1);
                                break;
                            case 7:
                                delete dataArray[labelNames[5]];
                                break;
                            default:
                                dataArray.splice(labelNames[2],1);
                        }
                    //}
                    dataChanged = true;
                }
                break;
            default:
        }
        return dataChanged;
    }
    /**
     * Handle click of checkbox, add: and del: items.
     * 
     * @function clickAddDelHandler
     *
     * @param {Object} event button click event.
     */
    function clickAddDelHandler(event) {
        let confirm = false;
        let name = event.target.name;
        const labelNames =event.target.name.split(keySeparator);
        const schemaRecord = getSchemaRecord(labelNames[1]);
        const dataType = schemaRecord.type;
        let index = parseInt(labelNames[2]);
        let stateKey = "";
        let clone = {};
        let dataArray = [];
        let dataChanged = false;
        if(event.target.type === 'button') {
            let nl = name.length;
            let buttonCode = name.substring(nl-4,nl);
            if(buttonCode === keySeparator+"Add") {
                dataChanged = handleAddButtonClick(event, labelNames, schemaRecord);
            }
            if(buttonCode === keySeparator+"Del") {
                dataChanged = handleDelButtonClick(dataType, labelNames, schemaRecord);
            }
            if(dataChanged) {
               /* if(!useStatesWithInputs) {
                    let qt = setFormItemStates(liveFormData, props.proot);
                    appFunctions.logLocalHostOnly("qt");
                }*/
                swapFormChanged();//add/delete button click caused change
            }
            return;
        }
        //i.e. not a button event
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        /*if(useStatesWithInputs) {
            setState({[event.target.name]: value});
        } else {*/
            document.getElementById(event.target.name).innerHTML = value;
            liveFormData[labelNames[1]] = value;
            swapFormChanged();//
        //}
    }
    /**
     * Form was submitted or preview, so set formMode and contact info.  
     * 
     * @function formWasSubmitted
     *
     * @parm {String} name of form
     * @parm {String} mode of form display
     * @parm {String} message for display mode
     * @param {String} data in json format for form
     */
    function formWasSubmitted(name, mode, message, data) {
        switch(mode) {
            case "Reload":
                //if(props.updateWithFormChanges !== undefined) {
                    /*
                    need to remove the forms JSON data from the local store cache.
                    also the schema.

                    Identity, Resume, Contact simple as straignt delete
                    project needs to update local store projectData with
                    the JSON and the project deleted.
                    */
                    appCache.deleteLocalStore(name);
                    window.location.reload();
                    //history.push(name);//just reload route
                    //props.updateWithFormChanges(name, mode, message, data);
                    return;//as enclosing component will refresh form
                //}
                break;
            case "Preview":
            case "Saved":
                if(mode === "Preview") {
                    dataStatus = "Preview";
                } else {
                    dataStatus = "Saved";
                }
               // if(props.updateWithFormChanges !== undefined) {
                    appCache.deleteLocalStore(name);
                    window.location.reload();
                    //history.push(name);//just reload route
                    //props.updateWithFormChanges(name, mode, message, data);
                    return;//as enclosing component will refresh form
               /* } else {//for form with no enclosing component handler
                    liveFormData = JSON.parse(data);//copy edited data
                }*/
                break;
            case "Thankyou":
                contactThankyouData = data;
                contactMessage = message;
                break;
            default:

        }
        formMode = mode;
        swapFormChanged();//after submitted
    }

    /**
     * Form was reset.  Parses json string created from props.jsonFormData
     * to update the liveFormData with original load values.
     * 
     * @function formWasReset
     *
     * @param {String} name of form
     * @param {String} mode of Reset
     * @param {String} message for display mode
     * @param {String} data json string for reset of form
     */
    function formWasReset(name, mode, message,  data) {
        formMode = mode;
        dataStatus = "Saved";
        formInvalid = false;
        invalidFields = [];
        invalidMessages = [];
        liveFormData = JSON.parse(data);
        swapFormChanged();//after form reset
    }

    /**
     * Form had errors so set formMode and invalid info which is an integer index
     * array of invalidField and invalidMessages.  Use getInvalidFieldIndex to
     * lookup the field to get the index for the invalidMessages, if it exists.
     * 
     * @function formHasErrors
     *
     * @parm {String} name of form
     * @parm {String} invalidFields comma delimited list of invalid fields. 
     * @parm {String} invalidMessages comma delimited list of invalid messages for invalid fields.
     */
    function formHasErrors(name, invalidFields, invalidMessages) {
        formMode = "Submitted";
        invalidFields = invalidFields.split(",");
        invalidMessages = invalidMessages.split(",");
        swapFormChanged();//after form error
    }
  
    /**
     * Get invalid field index for field.
     * 
     * @function getInvalidFieldIndex
     *
     * @param {String} field value of invalidFields array element to get index of.
     * 
     * @return {Number} index of field found, otherwise -1;
     */
    function getInvalidFieldIndex(field) {
        let found = -1;
        if(invalidFields !== undefined) {
            if(invalidFields.length>0) {
                let fields = invalidFields.length;
                for(let i=0;i<fields;i++) {
                    if(invalidFields[i] === field) {
                        found = i;
                        break;
                    }
                }
            }
        }
        return found;
    }
    /**
     * Submit form elements as json string in a fetch POST to the deployed target.  
     *
     * The formName, cookie and stamp are included the posted json string.
     * 
     * A formName of Contact sends an email to the IdentityData.identityEmail 
     * formatted contact information with reply email set.  
     * A formName of Log sends an email to the IdentityData.identityEmail 
     * formatted as a JSON_PRETTY_PRINT
     * A formName of Identity or Resume saves the json to a data file.
     * 
     * All calls to php script causes and email to send to the IdentityData.company.Email,
     * thus logging all contacts, form changes and any access by nefarious entities.  
     *  
     * The fetch response sets the formMode to:
     * <ul>
     * <li>Initialize - New constructor.</li>
     * <li>Posted - Fetch html POST.</li>
     * <li>Preview - Preview button click.</li>
     * <li>Cancel - Cancel button click.</li>
     * <li>Thankyou - Fetch successfully sent form data as email.</li>
     * <li>Submitted - Fetch successfully saved form data.</li>
     * <li>Error - Fetch failure.</li>
     * </ul>
     * 
     * The jsonResponse has the formName, cookie and stamp that 
     * should match the posted values.
     *
     * Additionally the response has a status of Success or Error.  
     * When status is Error, the fields and message response values are comma ddelimited lists of the errors.  
     * When status is Success and the updateWithFormChanges property is set the calling componet is redisplayed,
     * in either case the form title shows Saved.  
     * When the formName is Contact and the status is Success a thankyou message is displayed, any error
     * will redisplay the contact form.
     * 
     * The form will prevent the submit or preview unless the values are valid, however the php script checks
     * any values for safety.  
     * 
     * @function handleSubmit
     *
     * @param {Object} event for click on form's Submit button.
     */
    function handleSubmit(event) {
        event.preventDefault();
        if(formInvalid) {
            let confirm = window.confirm("You must fix errors before submit!");
            return;
        }
        const form = event.target.form;

        let jsonResponse = {};
        let jsonData = readFormDataToJson(form); 

        let fieldNames = '';
        let invalidMesages = '';

        //add additional values to json post
        let milliseconds = new Date().getTime();
        jsonData['stamp'] = milliseconds.toString();

        let formName = '';
        if(props.formName !== undefined) {
            formName = props.formName;
            jsonData['formName'] = formName;
        } else {
            formName = "Log";
            jsonData['formName'] = formName; 
        }
        let cookie = 'email';
        if(props.cookie !== undefined) {
            cookie = props.cookie;
        }
        if(props.cookie !== undefined) {
            jsonData['cookie'] = cookie;
        }
        let jsonText = JSON.stringify(jsonData);
        let iscontact = isContactForm();
        let formMode = "Posted";
        let formMessage = "";
       // let formHasErrors = formHasErrors;
       //???? let formWasSubmitted = formWasSubmitted;
        let confirm = false;
        const requestOptions = {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            headers: { 
                'Content-Type': 'text/plain' 
                },
            body: jsonText
        };
        fetch(appDefaults.deployTarget+'/sendcontactlog.php?foo='+milliseconds, 
            requestOptions
        )
        .then(function(response) 
            {
                return response.text();
            }
        )
        .then(function(formResponse) 
            {
                //appFunctions.logLocalHostOnly(formResponse);
                jsonResponse = JSON.parse(formResponse);
                if(jsonResponse.cookie !== cookie) {
                    formMode = "Error";
                    formMessage = "cookie error";
                } else {
                    if(jsonResponse.status === "Success") {
                        if(iscontact) {
                            formMode = "Thankyou";
                            formMessage = jsonResponse.message;
                        }
                        else {
                            formMode = "Saved";
                            formMessage = jsonResponse.message;
                        }
                    } else {
                        formMode = "Error";
                        formMessage = jsonResponse.message+"\n\r"+jsonResponse.errors;
                        fieldNames = jsonResponse.fields;
                        invalidMesages = jsonResponse.errors;
                    }
                }
                //appFunctions.logLocalHostOnly("POST response ",jsonResponse.status,formMessage);
                switch(jsonResponse.status) {
                    case "Error":
                        formHasErrors(formName, fieldNames, invalidMesages);
                        break;
                    case "Success":
                        formWasSubmitted(formName, formMode, formMessage, jsonText);
                        break;
                    case "Posted":
                        confirm = window.confirm("There was a fetch error before submitting form "+formName+"!");
                        break;
                    default:
                        confirm = window.confirm("There was a fetch error of "+formMode+" submitting form "+formName+"!");
                }
            }
        );

        //appFunctions.logLocalHostOnly("POST submitted");
    }

    /**
     * Read the form elements and convert to key/values in json format.
     * 
     * Skip when elements label starts with addIndex.
     * 
     * @function readFormDataToJson
     *
     * @param {Object} form to read and convert to json values.
     * 
     * @return {Object} json of form values.
     */
    function readFormDataToJson(form) {
        const data = new FormData(form);
        let schemaIndex = {};
        if(props.schema !== undefined) {
            schemaIndex = Object.keys(props.schema);
        } else {
            schemaIndex = Object.keys(props.jsonFormData);
        }
        let schemaRecord = {};
        let jsonData={};
        for (let name of data.keys()) {
            const input = form.elements[name];
            const tagName = input.tagName;
            let defaultValue = input.defaultValue;
            let value = input.value;
            if(defaultValue !== value) {
                //appFunctions.logLocalHostOnly("dif");
                defaultValue = value;
            }
            const labelNames =name.split(keySeparator);
            const fieldName = labelNames[0];
            //appFunctions.logLocalHostOnly("submit",name,input, defaultValue, tagName);
            if(fieldName === "addIndex") {
                continue;
            }
            if(fieldName === "jobs") {
                //appFunctions.logLocalHostOnly("jobs");
            }
            let si = schemaIndex.indexOf(fieldName);
            if(si>=0) {
                schemaIndex.splice(si,1);
            }
            schemaRecord = getSchemaRecord(fieldName);
            const dataType = schemaRecord.type;
            //appFunctions.logLocalHostOnly("submit type",dataType);
            switch(dataType) {
                case "String":
                    jsonData[fieldName]=defaultValue;
                    break;
                case "TextArea":
                    jsonData[fieldName]=defaultValue;
                    break;
                case "StringArray":
                    if(labelNames[1] === "0") {
                        jsonData[fieldName]=[];
                    }
                    jsonData[fieldName].push(defaultValue);
                    break;
                case "Object":
                    let fieldData= {};
                    let fieldArray= [];
                    const objectFields = schemaRecord.fields;
                    let fieldIndex = -1;
                    for(let key in objectFields) {
                        if(objectFields[key].name === labelNames[2]) {
                            fieldIndex = key;
                            break; 
                        }
                    }
                    if(labelNames[1] === "0" && labelNames[2] === objectFields[0].name) {
                        if(objectFields[0].type === "String") {
                            jsonData[fieldName]=[];
                            fieldData[labelNames[2]]=defaultValue;
                            jsonData[fieldName].push(fieldData);
                        }
                        if(objectFields[0].type === "StringArray") {
                            jsonData[fieldName]=[];
                            fieldData[labelNames[2]].push(defaultValue);
                            jsonData[fieldName].push(fieldData);
                        }
                    } else {
                        if(objectFields[fieldIndex].type === "String") {
                            const object = jsonData[fieldName];
                            if(object.length-1 === parseInt(labelNames[1])) {
                                fieldData = object[parseInt(labelNames[1])];
                            }
                            fieldData[labelNames[2]]=defaultValue;
                            jsonData[fieldName].splice(parseInt(labelNames[1]), 1, fieldData);
                        }
                        if(objectFields[fieldIndex].type === "StringArray") {
                            const object = jsonData[fieldName];
                            fieldData = object[parseInt(labelNames[1])];
                            if(labelNames[3] === "0" && labelNames[2] === objectFields[fieldIndex].name) {
                                fieldArray.push(defaultValue);
                                fieldData[labelNames[2]] = fieldArray;
                                jsonData[fieldName].splice(parseInt(labelNames[1]), 1, fieldData);
                            } else {
                                fieldArray = fieldData[labelNames[2]];
                                fieldArray.push(defaultValue);
                                fieldData[labelNames[2]] = fieldArray;
                                jsonData[fieldName].splice(parseInt(labelNames[1]), 1, fieldData);
                            }
                        }
                    }
                    break;
                default:
            }
        }
        for (let fieldName of schemaIndex) {
            schemaRecord = getSchemaRecord(fieldName);
            switch(schemaRecord.type) {
                case "String":
                case "TextArea":
                    jsonData[fieldName] = "";
                    break;
                case "StringArray":
                case "Object":
                    jsonData[fieldName] = [];
                    break;
                default:
            }
        }
        return jsonData;
    }
 
    /**
     * Preview button click reads the form json and passes it to formWasSubmitted,
     * when the form is valid.
     * 
     * A ContactForm typically does not have the Preview button.
     * 
     * Allows the preview of the data on a enclosing component without
     * updating the file on the server. i.e. no permanent change.
     * 
     * @function previewClick
     *
     * @param {Object} event for Preview button click.
     */
    function previewClick(event) {
         if(formInvalid) {
            let confirm = window.confirm("You must fix errors before preview!");
            return;
        }
        let jsonData = readFormDataToJson(event.target.form);
        formWasSubmitted(props.formName, 'Preview', "Preview Form", JSON.stringify(jsonData));
        //appFunctions.logLocalHostOnly("preview click ");
    }
    /**
     * Set form item states using label value as key for the jsonFormData field value.  
     *
     * Note: useStatesWithInputs defaults to false so no state controlled inputs are used.  
     * Loads the undo array from the data.  
     * 
     * Add state for each item on form using property schema and passed jsonFormData.  
     * Any schemaRecord for an Array or Object has one add state set.  
     * Any jsonFormData with schemaRecord of Array or Object has one del state set.  
     * Each jsonFormData item has a state set.  
     * The state name is the jsonFormData.name plus the keySeparator.  
     * The state name for an Array has the index plus keySeparator added.  
     * The state name of Array of objects has the object key plus keySeparator added.  
     * 
     * Example state names:
     * 
     * - name: - non array item  
     * - head1List:0: - array of strings  
     * - links:0:href: - array of object with key  
     * - add:head1List: - add state name of array of strings  
     * - del:head1List: - del state name of array of string item  
     * - add:links: - add state name of array of objects  
     * - del:links:0:href: - del state name of array of object with key  
     *  
     * Note: Directly sets state, which is ok as it is done from the constructor.  
     * 
     * The undoObject is set with the same values as the state, except the key is
     * prefixed with undoPrefix.  Allows a submit handler to detect changes from
     * original/edited value.  A undo on an edited value returns the previously edited
     * value rather than the original value.  
     *
     * When any level one json elements are of StringArray or Object then objectEdits
     * property is set true, to indicate add/delete buttons are needed on the form.  
     *
     * @function setFormItemStates
     *
     * @param {Object} formData json data to be edited.
     * @param {String} proot uniqueness key.
     * 
     * @deprecated the inputs are no longer contrroled from this component form.  
     */
    function setFormItemStates( formData, proot ) {
        undoObject = {};
        let schemaIndex = {};
        objectEdits = false;
        fieldsEdits = false;
        if(props.schema !== undefined) {
            schemaIndex = Object.keys(props.schema);
        } else {
            schemaIndex = Object.keys(props.jsonFormData);
        }
        let schemaRecord = {};
        let objects = Object.keys(formData);
        //appFunctions.logLocalHostOnly("state start");
        {schemaIndex.map((field) =>
            {
                schemaRecord = getSchemaRecord(field);
                //appFunctions.logLocalHostOnly("form field="+field);
                let ewq =schemaRecord.width;
                if(ewq > widestEdit) {
                    widestEdit = ewq;
                }
                if(schemaRecord.type === "StringArray" || 
                    schemaRecord.type === "Object") {
                    objectEdits = true;
                   /* if(useStatesWithInputs) {
                        state={...state,["add"+keySeparator+field+keySeparator]: "add"};
                        state={...state,["addIndex"+keySeparator+field+keySeparator]: ""};
                    }*/
                 } 
                const data = formData[field];
                if(data === undefined) {
                    //appFunctions.logLocalHostOnly("undefined data for "+field);
                }
                
                if (typeof(data) === 'undefined' || data === null || data.length === 0) {
                    //appFunctions.logLocalHostOnly('state field no data',field, typeof(data));
                } else {
                    //appFunctions.logLocalHostOnly('state field',field, typeof(data), data.length);
                }
                if (data instanceof Array) {
                    //appFunctions.logLocalHostOnly(field,'is array');
                    setFormItemStateForArray(data, schemaRecord, field, objects);
                } 
                else {
                    //appFunctions.logLocalHostOnly(field,' is not array value is', data);
                    /*if(useStatesWithInputs) {
                        state={...state,[field+keySeparator]:data};
                    }*/
                    undoObject[undoPrefix+field+keySeparator] = data;
                    return;
                }
            }
        )}
    }
    /**
     * Set form item states for data array items found on level one of jsonFormData.  
     *
     * @function setFormItemStateForArray
     *
     * @param {Array} data found at level one of jsonFormData
     * @param {Object} schemaRecord for jsonFormData
     * @param {String} field name of level one item
     * 
     * @deprecated the inputs are no longer contrroled from this component form.  
     */
    function setFormItemStateForArray(data, schemaRecord, field) {
        //appFunctions.logLocalHostOnly(field,'is array');
        {data.map((datum, index) => 
            {
                if (!(datum instanceof Object)) {
                    //appFunctions.logLocalHostOnly(field,'does not have Object',proot, field, index, datum);
                    /*if(schemaRecord.type === "StringArray" || schemaRecord.type === "Object") {
                        if(useStatesWithInputs) {
                            state={...state,["del"+keySeparator+field+keySeparator+index.toString()+keySeparator]: "del"};
                        }
                    }*/
                    /*if(useStatesWithInputs) {
                        state={...state,[field+keySeparator+index.toString()+keySeparator]:datum};
                    }*/
                    undoObject[undoPrefix+field+keySeparator+index.toString()+keySeparator] = datum;
                    return;
                } else {
                    setFormItemStateForObjects(datum, index, schemaRecord, field);
                    //appFunctions.logLocalHostOnly(field,'is Object', proot+field, index, objects.length);
                }
            }
        )}
    }

    /**
     * Set form item states for json object items found in data array item found on level one of jsonFormData.  
     *
     * @function setFormItemStateForObjects
     *
     * @param {Object} datum json object found in data array item at level one of jsonFormData.
     * @param {Number} index of datum in array item.
     * @param {Object} schemaRecord for jsonFormData.
     * @param {String} field name of level one item.
     * 
     * @deprecated the inputs are no longer contrroled from this component form.  
     */
    function setFormItemStateForObjects(datum, index, schemaRecord, field) {
        let objects = Object.keys(datum);
        //appFunctions.logLocalHostOnly(field,'is Object', proot+field, index, objects.length);
        {objects.map((object, index2) => 
            {
                //appFunctions.logLocalHostOnly(field,'is Object ', proot+field, index, object, index2, datum[object]);
                let outputString = true;
                //if(schemaRecord.type === "StringArray" || schemaRecord.type === "Object") {
                if(schemaRecord.type === "Object") {
                    let fieldType = "";
                    let schemaEdit = false;
                    if(schemaRecord.fields !== undefined) { 
                        let fieldRecord = schemaRecord.fields;
                        for(let i in fieldRecord) {
                            if(fieldRecord[i].name === object) {
                                fieldType = fieldRecord[i].type;
                                break;
                            }
                        }
                        if(fieldType === '') {//happens when edit of schema has fields
                            fieldType = 'String';
                            if(object === "fields") {
                                fieldType = 'StringArray';
                                schemaEdit = true;
                            }
                        }
                    } else {
                        fieldType = 'String';
                    }
                   /* if(fieldType === "String") {
                        //appFunctions.logLocalHostOnly("ft=",fieldType);
                        if(useStatesWithInputs) {
                            state={...state,[field+keySeparator+index.toString()+keySeparator+object+keySeparator]:objects[object]};
                        }
                    }*/
                    if(fieldType === "StringArray") {
                        //appFunctions.logLocalHostOnly("ft=",fieldType);
                         setFormItemStateForStingArray(datum[object], field, index, object, schemaEdit);
                        outputString = false;
                    }
                }
                //}
                if(outputString) {
                    /*if(useStatesWithInputs) {
                        if(index2 === 0) {
                            state={...state,["del"+keySeparator+field+keySeparator+index.toString()+keySeparator+object+keySeparator]: "del"};
                        }
                        state={...state,[field+keySeparator+index.toString()+keySeparator+object+keySeparator]:datum[object]};
                    }*/
                    appFunctions.logLocalHostOnly("undo ",undoPrefix+field+keySeparator+index.toString()+keySeparator+object+keySeparator);
                    undoObject[undoPrefix+field+keySeparator+index.toString()+keySeparator+object+keySeparator] = datum[object];
                }
                return;
            }
        )}
    }

    /**
     * Set form item states for array item of json object list found in data array 
     * item found on level one of jsonFormData.  
     *
     * When schemaEdit is true an array item can be a field object. i.e. a json object
     * that defines the json elements to be expected in a json array of json objects.  
     * The fieldsEdits global is set to true when a field object is found.  
     * 
     * @function setFormItemStateForStingArray
     *
     * @param {Array} datumObjects list of items to be edited.
     * @param {String} field name of object that contains object list.
     * @param {Number} index of field name object.
     * @param {String} object name of json object containing array.
     * @param {Boolean} schemaEdit true when editing schema data, otherwise false.
     * 
     * @deprecated the inputs are no longer contrroled from this component form.  
     */
    function setFormItemStateForStingArray(datumObjects, field, index, object, schemaEdit) {
        let datumObjectKeys = Object.keys(datumObjects);
        for(let d in datumObjectKeys) {
            /*if(d === '0') {
                if(useStatesWithInputs) {
                    state={...state,["add"+keySeparator+field+keySeparator+index.toString()+keySeparator+object+keySeparator+d+keySeparator]: "add"};
                    state={...state,["addIndex"+keySeparator+field+keySeparator+index.toString()+keySeparator+object+keySeparator+d+keySeparator]: ""};
                }
            }
            if(useStatesWithInputs) {
                state={...state,["del"+keySeparator+field+keySeparator+index.toString()+keySeparator+object+keySeparator+d+keySeparator]: "del"};
                state={...state,[field+keySeparator+index.toString()+keySeparator+object+keySeparator+d+keySeparator]:datumObjects[d]};
            }*/
            if(!schemaEdit) {
                undoObject[undoPrefix+field+keySeparator+index.toString()+keySeparator+object+keySeparator+d+keySeparator] = datumObjects[d];
            } else {
                if(appDefaults.isSchemaFields) {
                    let datumObjectKeys2 = Object.keys(datumObjects[d]);
                    let datumObjects2 = datumObjects[d];
                    fieldsEdits = true;
                    for(let d2 in datumObjectKeys2) {
                        let x = appFunctions.isLocalHost();
                        appFunctions.logLocalHostOnly("undo fields ",undoPrefix+field+keySeparator+index.toString()+keySeparator+object+keySeparator+d+keySeparator+datumObjectKeys2[d2]+keySeparator);
                        undoObject[undoPrefix+field+keySeparator+index.toString()+keySeparator+object+keySeparator+d+keySeparator+datumObjectKeys2[d2]+keySeparator] = datumObjectKeys2[d2];
                    }
                } else {
                    undoObject[undoPrefix+field+keySeparator+index.toString()+keySeparator+object+keySeparator+d+keySeparator] = datumObjects[d];
                }
            }
        }
    }

    /**
     * Build form items for formData items that may include add/delete buttons.  
     *
     * Uses SetInputField for each element of the schema and jsonFormData.  
     * 
     * An element with schema type of StringArray will have an add and add index input 
     * included with the first item with each label and input also including a delete
     * button.  The label is the field identifier.  
     * 
     * An element with schema type of Object will have an add and add index input
     * included with the first item of the Object.  A delete button
     * is included on the first item of each Object.  Each item of the Object will have 
     * a label that is the field identifier.  i.e. the json level names and index for 
     * each fields key.  Any elements of the Object that have schema types of StringArray 
     * or Object have the add/delete buttons in the same manner.  
     * 
     * An element of schema type String will only have the schema label and input.  
     * 
     * A String field found in the schema but not in the data will have a table row with a label and input.
     * 
     * A StringArray or Object field found in the schema but not in the data will have only an add button
     * for the field to be edited.
     * 
     * @function buildForm
     *
     * @param {Object} formData json data with keys from schema keys.
     * @param {String} proot value for making component keys unique.
     * 
     * @return {String} HTML TABLE tag with a row for each field in schema property.
     */
   function buildForm(formData, proot ) {
        //const schemaIndex = Object.keys(props.schema);
        let schemaIndex = {};
        if(props.schema !== undefined) {
            schemaIndex = Object.keys(props.schema);
        } else {
            schemaIndex = Object.keys(props.jsonFormData);
        }
        let schemaRecord = {};
        let objects = Object.keys(formData);
        let addButton = false;
        let delButton = false;
        tableRowCounter = -1;
        return (
        <table key="edittable">
        <tbody key="editbody">
        {schemaIndex.map((field) =>
            {
                schemaRecord = getSchemaRecord(field);
                let hasElements = false;
                if(schemaRecord.type === "StringArray" || 
                   schemaRecord.type === "Object") {
                    objectEdits = true;
                    addButton = true;
                    delButton = true;
                    hasElements = true; 
                } else {
                    addButton = false;
                    delButton = false;
                }
                const data = formData[field];
                ////appFunctions.logLocalHostOnly('input field',field, typeof(data), data.length);
                if(hasElements) {
                    if (typeof(data) === 'undefined' || data === null || data.length === 0) {
                        //appFunctions.logLocalHostOnly('input field no data',field, typeof(data));
                        return SetInputField(schemaRecord, addButton, false, proot, field+keySeparator, emptyIndicator)
                    }
                }
                if (data instanceof Array) {
                    //appFunctions.logLocalHostOnly(field,'is array');
                    if(data.length === 0) {
                        //appFunctions.logLocalHostOnly(field,'is array is empty')
                        return SetInputField(schemaRecord, addButton, false, proot, field+keySeparator, emptyIndicator)
                    }
                    let bm = buildFormWithButtons(data, field, proot, schemaRecord, addButton, delButton);
                    return (<>
                    {bm}
                    </>);
                } 
                else {
                        //appFunctions.logLocalHostOnly(field,' is not array value is', data);
                        return SetInputField(schemaRecord, addButton, delButton, proot, field+keySeparator, data);
                }
            }
        )}
        </tbody>
        </table>
        );
    }
    /**
     * Build form items for data array items found on level one of jsonFormData that
     * may include add/delete buttons.  
     *
     * @function buildFormWithButtons
     *
     * @param {Array} data found at level one of jsonFormData
     * @param {String} field name of level one item
     * @param {String} proot value for making component keys unique.
     * @param {Object} schemaRecord for jsonFormData
     * 
     * @return {String} HTML TR tags for each field in data with add/del buttons as determined.
     */
    function buildFormWithButtons(data, field, proot, schemaRecord) {
        let objects = {};
        let addButton = true;
        let delButton = true;
        return (<>
            {data.map((datum, index) => 
                {
                    if(index > 0) {
                        addButton = false;
                    }
                    
                    if (!(datum instanceof Object)) {
                        //appFunctions.logLocalHostOnly(field,'does not have Object',proot, field, index, datum);
                        return SetInputField(schemaRecord, addButton, delButton, proot, field+keySeparator+index.toString()+keySeparator, datum); 
                    } else {
                        objects = Object.keys(datum);
                        //appFunctions.logLocalHostOnly(field,'is Object', proot+field, index, objects.length);
                        addButton = true;
                        if(index > 0) {
                            addButton = false;
                        }
                        delButton = true;
                        if(objects.length === 0) {
                            //appFunctions.logLocalHostOnly(field,'is object is empty')
                        }
                        return (<>
                            {objects.map((object, index2) => 
                                {
                                    if(index2 > 0) {
                                        addButton = false;
                                        delButton = false;
                                    }
                                    let fieldEdit = getFieldEdit(object, schemaRecord);
                                    if(fieldEdit["fieldType"] === "Object") {
                                        appFunctions.logLocalHostOnly("ob");
                                    }
                                    if(fieldEdit["fieldType"] === "StringArray") {
                                        //appFunctions.logLocalHostOnly("ft=",fieldType);
                                        let datumObjectKeys = Object.keys(datum[object]);
                                        let datumObjects = datum[object];

                                        let addButtonForField = true;
                                        let delButtonForField = true;
                                        let fieldObjectKeys = Object.keys(datum[object]);
                                        let fieldObjects = datum[object];
                                        return (<>
                                            {fieldObjects.map((fieldObject, index3) => 
                                                {
                                                    if(index3 > 0) {
                                                        addButtonForField = false;
                                                        delButtonForField = true;
                                                    }
                                                    if(fieldObjects.length === 1) {
                                                        delButtonForField = false;
                                                    }
                                                    //appFunctions.logLocalHostOnly(field,'is fieldObject ', proot+field, index, object, index2, datum[object],fieldObject, index3,fieldObjects[fieldObject]);
                                                    if(!fieldEdit["schemaEdit"]) {
                                                        return SetInputField(schemaRecord, addButtonForField, delButtonForField, proot, field+keySeparator+index.toString()+keySeparator+object+keySeparator+index3+keySeparator, fieldObject);//fieldObjects[fieldObject]);
                                                    } else {
                                                        if(appDefaults.isSchemaFields) {
                                                            fieldsEdits = true;
                                                            let addButtonForField4 = true;
                                                            let delButtonForField4 = true;
                                                            let fieldObjectsKeys2 = Object.keys(fieldObject);//Object.keys(fieldObjects[fieldObject]);
                                                            let fieldObjects2 = fieldObject;//fieldObjects[fieldObject];
                                                            let fm = buildFormForField(fieldObject, index3, field, index, proot, object, schemaRecord);
                                                            return (<>
                                                                {fm}
                                                            </>);
                                                        } else {
                                                            return SetInputField(schemaRecord, addButtonForField, delButtonForField, proot, field+keySeparator+index.toString()+keySeparator+object+keySeparator+index3+keySeparator, fieldObjects[fieldObject].name);
                                                        }
                                                    }
                                                }
                                            )}
                                            </>
                                        );
                                    }
                                    //appFunctions.logLocalHostOnly(field,'is Object ', proot+field, index, object, index2, datum[object]);
                                    return SetInputField(schemaRecord, addButton, delButton, proot, field+keySeparator+index.toString()+keySeparator+object+keySeparator, datum[object])
                                }
                            )}
                            </>
                        );
                    }
                }
            )}
        </>);
    }

    /**
     * Build form items for fieldObject array items found on field, a level one of jsonFormData that
     * may include add/delete buttons.  
     *
     * @function buildFormForField
     *
     * @param {Array} fieldObject found on field, a level one of jsonFormData.
     * @param {String} index3 of fieldObject.
     * @param {String} field name of level one item.
     * @param {String} index name of field, a level one item.
     * @param {String} proot value for making component keys unique.
     * @param {String} object name of fieldObject.
     * @param {Object} schemaRecord for jsonFormData.
     * 
     * @return {String} HTML TR tags for each field in fieldObject.
     */
    function buildFormForField(fieldObject, index3, field, index, proot, object, schemaRecord) {
        fieldsEdits = true;
        let addButtonForField = true;
        let delButtonForField = true;
        let addButtonForField4 = true;
        let delButtonForField4 = true;
        let fieldObjectsKeys2 = Object.keys(fieldObject);//Object.keys(fieldObjects[fieldObject]);
        let fieldObjects2 = fieldObject;//fieldObjects[fieldObject];
        return (<>
        {fieldObjectsKeys2.map((fieldObject2, index4) => 
            {
                if(index4 > 0) {
                    addButtonForField = false;
                    delButtonForField = false;
                    addButtonForField4 = false;
                    delButtonForField4 = true;
                }
                if(fieldObjects2.length === 1) {
                    delButtonForField4 = false;
                }
                let fieldSchema =  {
                    type: "StringArray",
                    label:fieldObject2,
                    width:schemaRecord.width,
                    validation: "None",
                    canBeEmpty: "true"
                }
                appFunctions.logLocalHostOnly(fieldSchema, addButtonForField4, delButtonForField4, proot, field+keySeparator+index.toString()+keySeparator+object+keySeparator+index4+keySeparator+fieldObject2+keySeparator, fieldObjects2[fieldObject2]);
                return SetInputField(fieldSchema, addButtonForField4, delButtonForField4, proot, field+keySeparator+index.toString()+keySeparator+object+keySeparator+index3+keySeparator+fieldObject2+keySeparator, fieldObjects2[fieldObject2]);
            }
        )}
        </>
        );
    }
    /**
    * get fieldEdit object with fieldType and schemaEdit members.  
    *
    * fieldType member String is type for editing object, default empty.  
    * schemaEdit member Boolean indicates form is editing schema data, default false.  
    *
    * @function getFieldEdit
    *
    * @param {String} object field name
    * @param {Object} schemaRecord for field name.
    *
    * @return {Object} with fieldType and schemaEdit members.
    */
    function getFieldEdit(object, schemaRecord) {
        let fieldEdit = {fieldType: "", schemaEdit:false};
        if(schemaRecord.fields !== undefined) { 
            let fieldRecord = schemaRecord.fields;
            for(let i in fieldRecord) {
                if(fieldRecord[i].name === object) {
                    fieldEdit["fieldType"] = fieldRecord[i].type;
                    break;
                }
            }
            if(fieldEdit["fieldType"] === '') {//happens when edit of schema has fields
                fieldEdit["fieldType"] = "String";
                if(object === "fields") {
                    fieldEdit["fieldType"] = "StringArray";
                    fieldEdit["schemaEdit"] = false;
                }
            }
        } else {
            fieldEdit["fieldType"] = "String";
        }
        return fieldEdit;
    }

    /**
     * Set an input field item to it's value and its properties based
     * on the schemaRecord, addButton and delButton.  
     * 
     * Each table row returned will have six table cells:  
     * <ul>
     *  <li>Cell 1 - is 25px wide with an Add button if addButton is true, otherwise empty.</li>
     *  <li>Cell 2 - is 25px wide with an Add index input if addButton is true, otherwise empty.</li>
     *  <li>Cell 3 - is 25px wide with an Del button if delButton is true, otherwise empty.</li>
     *  <li>Cell 4 - is 25px wide, when a row is an input of type Object and a field type of StringArray, its Add/Insert/Del use col2,3 & 4</li>
     *  <li>Cell 5 - a label for field from schema with width from labelWidth property.</li>
     *  <li>Cell 6 - a text or textarea input for field based on schema type with width from editWidth property or
     * the length of the field's state value.</li>
     * </ul>
     * 
     * The Add button will add a table row for editing at the end of its group or at the position
     * set in the Add index. 
     * 
     * The Del button will remove a table row from editing.  
     * 
     * The Submit button will update the json file that the form data came from.  
     * 
     * @function SetInputField
     *
     * @param {Object} schemaRecord for input/field
     * @param {Boolean} addButton indicate a button is to be displayed for input.
     * @param {Boolean} delButton indicate a button is to be displayed for input.
     * @param {String} proot string to make keys unique.
     * @param {String} field name of form item to edit.
     * @param {String} data value of item to edit. i.e., from jsonFormData.
     * 
     * @return {Object} HTML TR tag with row edit tags. i.e. add/delete buttons, label/input for field.
     */
    function SetInputField( schemaRecord, addButton, delButton, proot, field, data ) {
        //appFunctions.logLocalHostOnly("input field=",field);
    /*    if(field.indexOf("field")>=0) {
            appFunctions.logLocalHostOnly("input field=",field);
        }*/
        tableRowCounter++;
        let DelButton = "";
        let AddButton = "";
        let AddIndex = "";
        let DelButton2 = "";
        let AddButton2 = "";
        let AddIndex2 = "";
        let InputText = "";
        let InputArea = "";
        let invalidFieldIndex = getInvalidFieldIndex(field);
        let invalidMessage = " ";
        let toolTip = field;
        let label = field;
        let eSize = size;
        let fontSize = props.fontSize;
        let AddInputSchemaRecord = {
                                    "name": label,
                                    "type": "String",
                                    "label": "addIndex"+keySeparator+label,
                                    "width": "50",
                                    "validation": "Integer",
                                    "minimum": "0",
                                    "maximum": "10",
                                    "canBeEmpty": "true"
                                    }
        if(isFieldsEdits()) {
            AddInputSchemaRecord = {
                                    "name": label,
                                    "type": "String",
                                    "label": "addIndex"+keySeparator+label,
                                    "width": "100",
                                    "validation": "String",
                                    "canBeEmpty": "true"
                                    }
        }
        //let lWidth = labelWidth;
        let eWidth = schemaRecord.width;
        let labelLen = label.length;
        let labelList = label.split(keySeparator);
        let labelListLen = labelList.length;

        let labelPop;
        let labelIndex;
        let baseLabelPop;
        let baseLabelIndex;
        let schemaLabelPop;
        let schemaLabelIndex;
        let schemaDelButtonLabel;
        let schemaFieldLabel;
        let buttonDelLabel;
        let buttonAddLabel;
        let buttonDelLabelType;
        let buttonAddLabelType;
        let rowStyle="";
        let rowColor="";
        let firstInGroup = false;
        let lastInGroup = false;
        let firstInField = false;
        let lastInField = false;

        let controlSize = '5%';//four of these is 20%
        let labelSize = '20%';//20%
        let inputSize = '70%';//90%
        let invalidMsgSize = '10%';//100%
        if(isObjectEdits()) {
            labelSize = '15%';//35%
            inputSize = '55%';//90%
            invalidMsgSize = '10%';//100%
        } 
        switch(labelListLen) {
                case 2:
                    labelPop = labelList.pop();//removes empty element at end
                    buttonDelLabel = labelList.join(keySeparator);
                    buttonAddLabel = labelList.join(keySeparator);
                    baseLabelPop = labelList.pop();
                    baseLabelIndex = -1;
                    buttonDelLabelType = "";
                    buttonAddLabelType = "";
                    firstInGroup = false;
                    firstInField = false;
                    if(parseInt(tableRowCounter)%2 === 0) {
                        rowColor='rgba(255,255,255,.9)';
                    } else {
                        rowColor='rgba(244,244,244,.9)';
                    }
                    break;
                case 3:
                    labelPop = labelList.pop();//removes empty element at end
                    buttonDelLabel = labelList.join(keySeparator);
                    baseLabelIndex = labelList.pop();
                    buttonAddLabel = labelList.join(keySeparator);
                    baseLabelPop = labelList.pop();
                    buttonDelLabelType = "item";
                    buttonAddLabelType = "item";
                    if(baseLabelIndex === '0') {
                        firstInGroup = true;
                        firstInField = false;
                    }
                    if(parseInt(baseLabelIndex)%2 === 0) {
                        rowColor='rgba(240,240,240,.9)';
                    } else {
                        rowColor='rgba(230,230,230,.9)';
                    }
                    break;
                case 4:
                    baseLabelPop = labelList.pop();//removes empty element at end
                    labelPop = labelList.pop();
                    buttonDelLabel = labelList.join(keySeparator);
                    baseLabelIndex = labelList.pop();
                    buttonAddLabel = labelList.join(keySeparator);
                    baseLabelPop = labelList.pop();
                    buttonDelLabelType = "fields";
                    buttonAddLabelType = "fields";
                    if(baseLabelIndex === '0') {
                        firstInGroup = true;
                        firstInField = false;
                    }
                    if(tableRowCounter%2 === 0) {
                        rowColor='rgba(220,220,255,.9)';
                    } else {
                        rowColor='rgba(230,230,255,.9)';
                    }
                    break;
                case 5:
                    baseLabelPop = labelList.pop();//removes empty element at end
                    buttonDelLabel = labelList.join(keySeparator);
                    labelIndex = labelList.pop();
                    buttonAddLabel = labelList.join(keySeparator);
                    labelPop = labelList.pop();
                    baseLabelIndex = labelList.pop();
                    baseLabelPop = labelList.pop();
                    buttonDelLabelType = "field item";
                    buttonAddLabelType = "field item";
                    if(baseLabelIndex === '0') {
                        firstInGroup = true;
                    }
                    if(labelIndex === '0') {
                        firstInField = true;
                    }
                    if(parseInt(labelIndex)%2 === 0) {
                        rowColor='rgba(210,210,210,.9)';
                    } else {
                        rowColor='rgba(220,220,220,.9)';
                    }
                    break;
                case 6:
                    baseLabelPop = labelList.pop();//removes empty element at end
                    buttonDelLabel = labelList.join(keySeparator);
                    schemaLabelPop = labelList.pop();
                    labelIndex = labelList.pop();
                    
                    buttonAddLabel = labelList.join(keySeparator);
                    schemaFieldLabel = labelList.pop();
                   // buttonDelLabel = labelList.join(keySeparator);
                    //labelIndex = labelList.pop();
                    
                   // labelPop = labelList.pop();
                   // baseLabelPop = labelList.pop();
                    baseLabelIndex = labelList.pop();
                    baseLabelPop = labelList.pop();
                    buttonDelLabelType = "field item";
                    buttonAddLabelType = "field item";
                    if(baseLabelIndex === '0') {
                        firstInGroup = true;
                    }
                    if(labelIndex === '0') {
                        firstInField = true;
                    }
                    if(parseInt(labelIndex)%2 === 0) {
                        rowColor='rgba(210,210,210,.9)';
                    } else {
                        rowColor='rgba(220,220,220,.9)';
                    }
                    break;
                default:
                    break;
        }
        if(invalidFieldIndex>=0) {
            invalidMessage = invalidMessages[invalidFieldIndex];
            //toolTip = invalidMessage;
            rowColor='rgba(255,192,203,.9)';
        }
        let editValue = data;
        let indexValue = "";
        /*if(useStatesWithInputs) {//controlled inputs are not used
            editValue = state[field];
            indexValue = state["addIndex"+keySeparator+buttonAddLabel+keySeparator];
        } else {

        }*/
        rowStyle="none";
        //appFunctions.logLocalHostOnly('input', schemaRecord.label ,schemaRecord.type, proot, field, data);
        if(addButton) {
            AddButton = (
                <StyledInputButtonModFunction id={"add"+keySeparator+buttonAddLabel+keySeparator} 
                    key={"add"+keySeparator+buttonAddLabel+keySeparator+proot} 
                    keyId={"add"+keySeparator+buttonAddLabel+keySeparator} 
                    type='button'
                    tabIndex="-1"
                    title={"Add "+buttonAddLabelType+" to end of "+buttonAddLabel+keySeparator+" list, or before index."}
                    text="Add"
                    selectionHandler={clickAddDelHandler}
                />  
            );
            //appFunctions.logLocalHostOnly("addIndex"+keySeparator+buttonAddLabel+keySeparator);
            AddIndex = (
                <InputTextCompFunction id={"addIndex"+keySeparator+buttonAddLabel+keySeparator}
                    key={"addIndex"+keySeparator+buttonAddLabel+keySeparator+proot} 
                    name={"addIndex"+keySeparator+buttonAddLabel+keySeparator}
                    type='text' 
                    tabIndex="-1"
                    style={{fontSize: {fontSize}}}
                    keyId={"addIndex"+keySeparator+buttonAddLabel}
                    title={"Index of "+buttonAddLabelType+" to add to "+buttonAddLabel+keySeparator+" list.  Insert before index, empty or max adds to end."}
                    value={indexValue}
                    onChangeResultHandler={handleInputChangeResult}
                    focusOutResultHandler={handleFocusOutResult}
                    width="3"
                    text=""
                    schemaRecord={AddInputSchemaRecord}
                    form={props.formName}
                    setLiveFormValueFromIndex={setLiveFormValueFromIndex}
                    keySeparator={keySeparator}
                    getSchemaRecord={getSchemaRecord}
                />
            );
        }
        
        switch(schemaRecord.type) {
            case "Object":// falls through
            case "StringArray":
                if(delButton) {
                    DelButton = (
                        <StyledInputButtonModFunction id={"del"+keySeparator+buttonDelLabel+keySeparator}
                            key={"del"+keySeparator+buttonDelLabel+keySeparator+proot}
                            keyId={"del"+keySeparator+buttonDelLabel+keySeparator} 
                            type='button'
                            tabIndex="-1"
                            title={"Delete "+buttonDelLabelType+" from "+buttonDelLabel+keySeparator}
                            text="Del"
                            selectionHandler={clickAddDelHandler}
                        /> 
                    );
                }
            // falls through and returns component of tr with td's
            case "String":
                if(editValue.length > parseInt(eSize)) {
                    eSize = editValue.length;
                }
                if(!(editValue === emptyIndicator)) {
                   // isSchemaFieldsEdit(label," ");
                    if(field.indexOf("field")>=0) {
                        appFunctions.logLocalHostOnly("bf field=",field);
                        InputText = (
                            <InputTextCompFunction tabIndex={tableRowCounter}
                                id={"sit"+keySeparator+label}
                                name={"sit"+keySeparator+label}
                                key={"sit"+keySeparator+label+proot}
                                keyId={label}
                                title={toolTip}
                                text={editValue}
                                controlled={false}
                                onChangeResultHandler={handleInputChangeResult}
                                focusOutResultHandler={handleFocusOutResult}
                                width={eSize}
                                schemaRecod={schemaRecord}
                                form={props.formName}
                                setLiveFormValueFromIndex={setLiveFormValueFromIndex}
                                keySeparator={keySeparator}
                                getSchemaRecord={getSchemaRecord}
                            />
                        );
                    } else {
                        InputText = (
                            <InputTextCompFunction tabIndex={tableRowCounter}
                                    id={"sit"+keySeparator+label}
                                    name={"sit"+keySeparator+label}
                                    key={"sit"+keySeparator+label+proot}
                                    keyId={label}
                                    title={toolTip}
                                    text={editValue}
                                    controlled={false}
                                    onChangeResultHandler={handleInputChangeResult} 
                                    focusOutResultHandler={handleFocusOutResult}
                                    width={eSize}
                                    form={props.formName}
                                    setLiveFormValueFromIndex={setLiveFormValueFromIndex}
                                    keySeparator={keySeparator}
                                    getSchemaRecord={getSchemaRecord}
                            />
                        );
                    }
                }
                return ( 
                  <tr key={"trtext"+label+proot} style={{ backgroundColor:rowColor, outline:rowStyle}}
                     >
                     {/*each td has own condition as limit of jsx not understaning that the td is in a tr */}
                        {isObjectEdits() &&
                            <td key={"tdonetext"+label+proot} style={{width:  controlSize}}>
                                {!isObjectFieldOfStringArray(labelListLen)
                                 ? AddButton 
                                 : <p> </p> 
                                 }
                            </td>
                        }
                        {isObjectEdits() &&
                            <td key={"tdtwotext"+label+proot} style={{width:  controlSize}}>
                                {isObjectFieldOfStringArray(labelListLen) 
                                    ? AddButton 
                                    : AddIndex 
                                }
                            </td>
                        }
                        {isObjectEdits() &&
                            <td key={"tdthreetext"+label+proot} style={{width:  controlSize}}>
                                {isObjectFieldOfStringArray(labelListLen) 
                                    ? AddIndex 
                                    : DelButton 
                                }
                            </td>
                        }
                        {isObjectEdits() && 
                            <td key={"tdfourtext"+label+proot} style={{width:  controlSize}}>
                                {isObjectFieldOfStringArray(labelListLen) 
                                    ? DelButton 
                                    : <p> </p> 
                                }
                            </td>
                        }
                        {!isObjectEdits() && !isContactForm() &&
                            <td key={"tdonetext"+label+proot} style={{width:  controlSize}}>
                                 <p></p> 
                            </td>
                        }
                        {!isObjectEdits() && !isContactForm() &&
                            <td key={"tdtwotext"+label+proot} style={{width:  controlSize}}>
                                <p></p> 
                            </td>
                        }
                        {!isObjectEdits() && !isContactForm() &&
                            <td key={"tdthreetext"+label+proot} style={{width:  controlSize}}>
                                <p></p> 
                            </td>
                        }
                        {!isObjectEdits() && !isContactForm() &&
                             <td key={"tdfourtext"+label+proot} style={{width:  controlSize}}>
                                <p></p> 
                            </td>
                        }
                        <td key={"tdfivetext"+label+proot} style={{width:  {labelSize}}}>
                            <div key={"textlabel"+label+proot} align="right">{label}</div>
                        </td>
                        
                        <td key={"tdsixtext"+label+proot} align="left" style={{width: {inputSize}}}>
                                {InputText}
                        </td>
                
                        <td key={"tdseventext"+label+proot} align="left" style={{width: {invalidMsgSize}}}
                                id={"invalid"+label}
                                name={"invalid"+label}>
                            {invalidMessage}
                        </td>
                    </tr>
                );
            case "TextArea":
                if(!(editValue === emptyIndicator)) {
                        InputArea = (
                            <InputTextCompFunction tabIndex={tableRowCounter}
                                id={"sia"+keySeparator+label}
                                name={"sia"+keySeparator+label} 
                                style={{fontSize: {fontSize}}}
                                key={"sia"+keySeparator+label+proot}
                                keyId={label}
                                title={toolTip}
                                text={editValue}
                                controlled={false}
                                onChangeResultHandler={handleInputChangeResult} 
                                focusOutResultHandler={handleFocusOutResult}
                                type='textarea'
                                rows={areaRows}
                                cols={areaColumns}
                                form={props.formName}
                                setLiveFormValueFromIndex={setLiveFormValueFromIndex}
                                keySeparator={keySeparator}
                                getSchemaRecord={getSchemaRecord}
                            />
                        );
                }
                return (
                    <tr key={"trarea"+label+proot} style={{backgroundColor:rowColor, outline:rowStyle}}
                     >
                        {!isContactForm() &&
                            <td key={"tdonearea"+label+proot} style={{width:  controlSize}}>
                                
                            </td>
                        }
                        {!isContactForm() &&
                            <td key={"tdtwoarea"+label+proot} style={{width:  controlSize}}>
                                
                            </td>
                        }
                        {!isContactForm() &&
                            <td key={"tdthreearea"+label+proot} style={{width:  controlSize}}>
                                
                            </td>
                        }
                        {!isContactForm() &&
                            <td key={"tdfourarea"+label+proot} style={{width:  controlSize}}>
                                
                            </td>
                        }
                        <td key={"tdfivearea"+label+proot} style={{width: {labelSize}}}>
                            <div key={"arealabel"+label+proot} align="right">{label}</div>
                        </td>
                        
                        <td key={"tdsixarea"+label+proot} align="left" style={{width: {inputSize}}}>
                                {InputArea}
                        </td>
                      
                        <td key={"tdsevenarea"+label+proot} align="left" style={{width: {invalidMsgSize}}}
                                id={"invalid"+label}
                                name={"invalid"+label}>
                            {invalidMessage}
                        </td>
                    </tr>
                );
            default:
        }
    }
    /**
     * Display form with title using liveFormData loaded from data property.  
     * The liveFormData is only initialized on 1st render only, so a reset
     * can happen, unless a preview has been made. 
     * 
     * Based on the formMode and dataStatus the data is displayed for edit, a thank you response or 
     * an error display as needed.  
     * 
     * The inputs are controlled, in that they use onChange to prevent invalid key strokes
     * base on the schema data type and update liveData.  Additionally, on loss of focus, 
     * the whole value is validated.  
     * The result of onChange and onBlur events cause the form to indicate edited and flag any
     * invalid fields.  
     * 
     * Add/Del input buttons cause the form to indicate editied also.  
     * 
     * The Submit button saves the form and redisplays the enclosing component, if one is set.  
     * The Reset button cancels any changes made after any edits but before a Preview.  
     * The Preview button displays the enclosing component with the chaange without saving.  
     * Once the data is previewed either save the edited form or reload the saved data.
     */
   // render() {
       // if(liveFormData === undefined) {
            let js = JSON.stringify(props.jsonFormData);
            liveFormData = JSON.parse(js);//copy original data before any edits
       // } 
        let timestamper = '666';//new Date().getTime();
        let formMenuAlign = 'center';
        let headerFontR="4vw";
        if(appFunctions.isMobileDevice())  {
          headerFontR="8vw";
        }
        //?????
        appMetaTags.setMetaTag("description","Form for "+props.formName);
        appMetaTags.setMetaTag("author",'Phil Gottfried');
        appMetaTags.setMetaTag("keywords","ReactJS form");
        if(formMode==="Thankyou" && isContactForm()) {
            setTimeout(() => {
                navigate(fromRoute);
            }, 5000);
            formMode = "Submitted";//so click on identity email button redisplays form, instead of thankyou again.
  
            appMetaTags.setMetaTag("keywords","Thanks for contacting Phil Gottfried!");
            return (
                    <div key="mainthankyou" align={formMenuAlign} valign='top'  style={{ display: 'block'}}>
                        <div key="viewthankyou" align={formMenuAlign} valign='top' style={{ display: 'inline-block'}}>
                           
                            {isContactForm() &&
                                <div key="bodythankyou" align={formMenuAlign}>
                                    <p key="bodythankyoup" className="text3d" 
                                        style={{position: 'relative', zIndex:'0', 
                                                float: 'center',
                                                margin: '0px 0px',
                                                padding: '0px 0px',
                                                textAlign: 'center',
                                                fontSize: headerFontR,
                                                fontWeight: 'bold',
                                                color: 'black'
                                                }}
                                    >
                                        Thanks! I will get back to you.
                                      
                                    </p> 
                                    
                                </div>
                            }
                        </div>
                    </div>
            );
       }
       let color = appDefaults.divGroupBackground;
       if(formInvalid || invalidFields.length>0)  {
           color = appDefaults.controlErrorColor;
       }
       if(isDataStatus("Edited")) {
           return (<></>);
       }

        switch(formMode) {
            case "Error":
            case "Submitted":
            case "Reset":
            case "Saved":
            default:
                let menuParms={
                    id:"formMenu" ,
                    key:"formMenu",
                    formTitle:props.formTitle,
                    formName:props.formName,
                    formInvalid:formInvalid,
                    invalidFields:invalidFields,
                    dataStatus:dataStatus,
                    previewClick:previewClick,
                    handleSubmit:handleSubmit,
                    isContactForm:isContactForm,
                    isDataStatus:isDataStatus
                }
                return (
                    
                    <div key="mainform" align={formMenuAlign} valign='top'  
                            style={{ display: 'block',backgroundColor: color}}
                    >
                    
                    <div key="viewform" align={formMenuAlign} valign='top' style={{ display: 'inline-block'}}>
                        {!isDataStatus("Edited") &&
                            <form name={props.formName}
                                id={props.formName}
                                key={props.formName}
                                method="post"
                                align='center'
                                onSubmit={handleSubmit}>
                                <FormMenuFunction {...menuParms}
                                /> 
                                <div key="formAlignInputs"  align={formMenuAlign}>
                                    <div key="inputFields" align='center'>
                                        {buildForm(liveFormData, props.proot+timestamper)}

                                    </div>
                                </div>
                            </form>
                        }
                        </div>
                    </div>
                );
            
        }
}
