/** 
 * @fileOverview A div set to display as flex with a left, center and right div
 * set to display inline-block.  Any children are placed in the center div that
 * has rounded corners, a border and background color properties.  
 *
 * @module HomeFunction
 * 
 * @author Philip Gottfried
 */
import React , { useState, useEffect, useRef, useCallback } from "react";
import {useNavigate} from 'react-router-dom';

import DivGroupFunction from "./DivGroupFunction";
import DropdownFunction from "./DropdownFunction";
import ProjectViewFunction from "./ProjectViewFunction";

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
import * as appMetaTags from "../modules/AppMetaTags";
import '../index.css';

const newImage = new Image();
const images = []
/**
* Implements a div with three columns and place any children in the center div.
* The width of the div's default to 5%, 90% and 5% with the left and right div
* set to diplay as transparent and the center div is set to have a partially
* transparent background and a border with rounded corners.
* 
* Uses AppDefaults to set default properties.  
*  
* Mobile uses 1%, 98% and 1% for the column widths.  
* 
* @function HomeFunction
*
* @param {Object} props property object
* 
* @prop {String} leftWidth of left div
* @prop {String} centerWidth of center div
* @prop {String} rightWidth of right div
* @prop {String} borderRadius for center div
* @prop {String} backgroundColor for center div
* @prop {String} borderTopColor for center div
* @prop {String} borderBottomColor for center div
* @prop {object} projectData json object of project data.
*
* @returns {Object} div with three divs with default widths of 5%,90,5% or for mobile 1%, 98%, 1%
*       and styled with a beveled border, rounded corners and partially transparent white background.
*/
export default function HomeFunction(props) {
    let navigate = useNavigate();
    const [showcaseOver, setShowcaseOver] = useState(false);
    const [overComponent, setOverComponent] = useState(emptyComponent());
    let navMenuWidth=150;
    let navMenuHeight=50;
    let scrollWidth =  window.innerWidth - document.documentElement.clientWidth;
    let windowWidth= window.innerWidth-scrollWidth;
    let menuWidth = windowWidth *.9;
    let menuHeight = navMenuHeight;
    let iframeWidth = windowWidth *.9;
    let iframeHeight = iframeWidth*.66;
    let leftWidth = "5%";
    let centerWidth = "90%";
    let rightWidth = "5%";
    if(appFunctions.isMobileDevice()) {
        leftWidth = "1%";
        centerWidth = "98%";
        rightWidth = "1%";
         menuWidth = windowWidth *.98;
    let menuHeight = navMenuHeight;
        iframeWidth = windowWidth *.98;
        iframeHeight = iframeWidth*.66;
    }
    if(props.leftWidth !== undefined) {
        leftWidth = props.leftWidth;
    }
    if(props.centerWidth !== undefined) {
        centerWidth = props.centerWidth;
    }
    if(props.leftWidth !== undefined) {
        rightWidth = props.rightWidth;
    }
    navMenuWidth = parseInt(iframeWidth/props.homeData.length);
    let extra = parseInt((iframeWidth - (navMenuWidth * props.homeData.length))/2); //parseInt(iframeWidth*.05);//
    //navMenuWidth =  navMenuWidth;// + extra;
    navMenuHeight = navMenuWidth*.5;
    let idFontR="3vw";
    let headerFontR="3vw";
    if(appFunctions.isMobileDevice())  {
        idFontR="5vw";
        headerFontR="5vw";
    }

    let menuMaps=makeMenuItems();
   // menuMap.menuItems["Previous"]="/Previous";
//let overComponent = emptyComponent();
/* if(showcaseOver) {
    overComponent= makeProjectView(selectedMenuItem, menuIndex)
 }*/

    function makeMenuItems() {
        let maps=[];
        let showcase = {}
        let links = {}
        let mi = []
        for(let i=0; i<props.homeData.length; i++) {
            showcase = props.homeData[i];
            mi = []
            for(let j=0;j<showcase.showcaseLinks.length;j++) {
                links = showcase.showcaseLinks[j];
                mi[links.text] = links.href;
                
            }
            maps[i]={menuItems:mi};
        }
        return maps;
    }
    /**
    * Make a dropdown menu of showcase icons
    *
    * @function makeDropdown
    */
    function makeDropdown() {
        let maps=[];
        let showcase = {}
        let links = {}
        let showcaseTitle = showcase.showcaseTitle;
        showcaseTitle = "View swipable list of propjects.";
        return (
            menuMaps.map((map,index) => {
                showcase = props.homeData[index];
                showcase["index"] = index;
                return (
                    <div key={"divHomeButtonItem"+showcase.showcaseId} align="center"
                        style={{  width: navMenuWidth, height: navMenuHeight,
                            zIndex:'0',  position: 'relative',
                            display: 'inline-block'
                        }}
                    >
                    <img src={showcase.showcaseIcon}
                        alt={showcase.showcaseTitle}
                        title={showcaseTitle}
                        width={navMenuWidth}
                        onClick={()=>{
                                navigate(props.projectData.portfolios[0].name);
                            }}
                    />
                {/*    <DropdownFunction 
                        direction="left"
                        offset={0}
                        menuMap={map}
                        showcase={showcase}
                        width={navMenuWidth}
                        height={navMenuHeight}
                        selectionHandler={gotoSelectedLocation}
                        overAction={overAction}
                        outAction={outAction}
                        iconImage={showcase.showcaseIcon}
                        color='black'
                        backgroundColor="white"
                    />*/}
                    </div>
                )
            }
            )
        )
    }

/**
*
* @function makeProjectViews
*/
function makeProjectViews() {
        let maps=[];
        let showcase = {}
        let links = {}
        return (
            menuMaps.map((map,index) => {
                showcase = props.homeData[index];
                showcase["index"] = {index}
                links = showcase.showcaseLinks;
                return (
                    links.map((item, idNo) => {
                        let ty=0;
                        if(item.href.indexOf("/") === 0 && item.href.indexOf("/Lightbox") !== 0) {
                        return (
                            <ProjectViewFunction key={"home"+item.text} 
                                       // projectData={props.projectData.portfolio}
                                        projectData={appFunctions.getData(props.projectData,item.href.replace("/",""), 'portfolios')}
                                        projectSchema={appFunctions.getData(props.projectData,'ProjectSchema', 'schemas')}
                                        identityData={appFunctions.getData(props.projectData,'IdentityData', 'data')}
                                        identitySchema={appFunctions.getData(props.projectData,'IdentitySchema', 'schemas')}
                                        root='root'
                                        isAppLoggedIn={false}
                                        fontName={appDefaults.projectViewTextFontName}
                            /> 
                        )
                        } else {
                            return(
                            <iframe src={item.href}
                                    width={iframeWidth-scrollWidth}
                                    height={iframeHeight}
                            >
                            </iframe>
                            )
                        }
                        }
                    ) 
                )
            }
            )
        )
    }

    /**
    * Make a project view for selected item of menu index.  
    *
    * @function makeProjectView
    *
    * @props {String} selectedMenuItem text.
    * @props {String} menuIndex for menu.
    */
    function makeProjectView(selectedMenuItem, menuIndex) {
        let maps=[];
        let showcase = {}
        let links = {}
        let index = menuIndex;
        let map = menuMaps[index];
        return (

            menuMaps.map((map,index) => {
                showcase = props.homeData[index];
                showcase["index"] = {index}
                links = showcase.showcaseLinks;
                return (
                    links.map((item, idNo) => {
                        let ty=0;
                        appFunctions.logLocalHostOnly("look="+selectedMenuItem+" found ="+item.text+" menu "+menuIndex);
                        if(selectedMenuItem === item.text && index === menuIndex) {
                            if(item.href.indexOf("/") === 0 && item.href.indexOf("/Lightbox") !== 0) {
                                appFunctions.logLocalHostOnly("route ="+item.text+" at "+menuIndex);
                            return (
                                <ProjectViewFunction key={"home"+item.text}
                                            projectData={appFunctions.getData(props.projectData,item.href.replace("/",""), 'portfolios')}
                                            projectSchema={appFunctions.getData(props.projectData,'ProjectSchema', 'schemas')}
                                            identityData={appFunctions.getData(props.projectData,'IdentityData', 'data')}
                                            identitySchema={appFunctions.getData(props.projectData,'IdentitySchema', 'schemas')}
                                            root='root'
                                            isAppLoggedIn={false}
                                            fontName={appDefaults.projectViewTextFontName}
                                /> 
                            )
                            } else {
                                appFunctions.logLocalHostOnly("iframe ="+item.text+" href="+item.href);
                                return(
                                <iframe src={item.href}
                                        width={iframeWidth-scrollWidth}
                                        height={iframeHeight}
                                >
                                </iframe>
                                )
                            }
                        }
                    }
                    ) 
                )
            }
            )
        )
    }

    /**
    * Over action sets over now and runs overAction property if it exists.   
    * 
    * @function overAction
    *
    * @param {Object} over event. 
    * @param {String} itemSelected for select action.
    * @param {Number} index of showcase. i.e. menu index
    */
    function overAction(event, itemSelected, index) {
       appFunctions.logLocalHostOnly("overAction on menuItem "+index+" "+itemSelected);
       setShowcaseOver(true);
       setOverComponent(makeProjectView(itemSelected, index));
    }

    /**
    * Out action sets over now and runs outAction property if it exists.   
    * 
    * @function outAction
    *
    * @param {Object} over event. 
    * @param {String} itemSelected for select action.
    * @param {Number} index of showcase. i.e. menu index
    */
    function outAction(event, itemSelected, index) {
       appFunctions.logLocalHostOnly("outAction on menuItem "+index+" "+itemSelected);
      setShowcaseOver(false);
      setOverComponent(emptyComponent());
    }
    /**
     * Goto selected location.  
     * 
     * When location starts with http a window is opened in new tab,
     * otherwise location is replaced on navigate.
     *
     * @function gotoSelectedLocation
     * 
     * @param {*} selection the menuItem selected.
     * @param {Number} index of showcase. i.e. menu index
     */
    function gotoSelectedLocation(selection, index) { 
        let menuMapX = menuMaps[index];
        let location = menuMapX.menuItems[selection];
        if(location.toLowerCase().indexOf("http")===0) {
           window.open(location, "menuclick") || window.location.replace(location);
           return;
        } 
        if(location.toLowerCase().indexOf("/previous")===0) {
            navigate(-1);
            return;
         }
         if(location.toLowerCase().indexOf("/forward")===0) {
            navigate.goForward();
            return;
         }
        navigate(location);
    }

    /**
    * Empty component.  
    *
    *@function emptyComponent
    *
    * @return {Object} An empty react fractment.
    */
    function emptyComponent() {
        return (<></>)
    }
    let identity = appFunctions.getData(props.projectData,"IdentityData","data");
    let desc = "Practiced in web technologies, multiple programming languages, "+
        "development environments, systems analysis and business operations as well "+
        "as operating systems and hardware maintenance."
    let keywords = "ReactJS Developer, Programmer, Java, PHP, C++, HTML/CSS, Photopshop, Lenticular, 3D Photography"
    appMetaTags.setMetaSEOData(identity.identityName,desc,keywords);
    appFunctions.logLocalHostOnly("render ="+showcaseOver);
 
    return (
        <DivGroupFunction>
            <p title = "View swipable list of propjects."
                    style={{position: 'relative', zIndex:'0', 
                            display: 'block',
                            float: 'center',
                            margin: '0px 0px',
                            padding: '0px 0px',
                            textAlign: 'left',
                            fontSize: headerFontR,
                            fontWeight: 'bold',
                            color: 'black'}}
                            onClick={()=>{
                                navigate(props.projectData.portfolios[0].name);
                            }}>
                &nbsp;&nbsp;{desc}
            </p>
            <br></br>
            <p title = "View swipable list of propjects."
                    style={{position: 'relative', zIndex:'0', 
                            display: 'block',
                            float: 'center',
                            margin: '0px 0px',
                            padding: '0px 0px',
                            textAlign: 'center',
                            fontSize: idFontR,
                            fontWeight: 'bold',
                            color: 'black'}}
                            onClick={()=>{
                                navigate(props.projectData.portfolios[0].name);
                    }}>
                    See what has been done with these tools!
            </p> 
            <DivGroupFunction noBorder={true} height={menuHeight}
                onClick={()=>{
                        navigate(props.projectData.portfolios[0].name);
                }}
            >
                {makeDropdown()}
            </DivGroupFunction>

            {showcaseOver &&
                <div key="divHomeOverFlexGroup" align="center"
                    style={{  width: iframeWidth, height: iframeHeight,
                        zIndex:'0',  position: 'relative',
                        display: 'flex'
                    }}
                >
                    <div key="divHomeOverGroup" align="center"
                        style={{  width: iframeWidth, height: iframeHeight,
                            zIndex:'0',  position: 'relative',
                            display: 'block'
                        }}
                    >
                        {overComponent}
                    </div>
                </div>
            }
        </DivGroupFunction>
    )
}