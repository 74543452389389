/** 
 * @fileOverview Display a header with identity values with Mamoto view tracking.  
 * 
 * @module IdentityFunctionalComponent

 * @author Philip Gottfried
 */
import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import * as appFunctions from "../modules/AppFunctions";
import * as appDefaults from "../modules/AppDefaults";
import * as appTracking from "../modules/AppTracking";

import {getPixelWidthOfTextForFontSize} from "../modules/AppFontSizes";
import {getFontSizeOfTextFitToViewportForFontSize} from "../modules/AppFontSizes";
import {vwTOpx} from "../modules/AppFontSizes";
import LinkMenuFunction from "./LinkMenuFunction";
import NavMenuFunction from "./NavMenuFunction";
import StyledButtonModFunction from "./StyledButtonModFunction";

/**
* Implements the page title, and identity links and menu buttons.  
*
* This is a functional component as it supports the Mamoto view tracking.
*
* @function IdentityFunctionalComponent
*
* @param {Object} props property object
* 
* @prop {object} auth the auth0Client instance, passed to LinkMenuFunction
* @prop {Boolean} isUseLogIn indicating login controls and methods are active.  Passed to LinkMenuFunction and NavMenuFunction.
* @prop {Boolean} isAppLoggedIn true when valid logIn, otherwise false.  Passed to LinkMenuFunction and NavMenuFunction.
* @prop {String} userName of user if logged in, otherwise Guest.  Current not used.
* @prop {String} keyId value to id which buttons display.  i.e. resume, contactform, identity, schema
* @prop {Number} titleFontSize for page title.
* @prop {function} setReactiveFontSize to return the font size of title fit to view port.
* @prop {String} prefix for key uniqueness.
* @prop {object} projectData json object of project and identify data.
* @prop {Number} beginIndex first image index when identity is for a project.
*/
export default function IdentityFunctionalComponent(props) {
    const [value, setValue] = useState(0); 
    let appData=appFunctions.getData(props.projectData,'AppData', 'data')
    let headerFontSize = parseInt(appData.titleFontSize*(32/appData.titleFontSize));

    let buttonFont = 18;

    let minimumTitleFontSize = 16;
    let minimumHeaderFontSize = 16;

    let pixelWidth = require('string-pixel-width');
    let isUseLogIn = false;
    if(appData.isUseLogIn !== undefined) {
      isUseLogIn = appData.isUseLogIn;
    }
    let isAppLoggedIn = false;
    if(appData.isAppLoggedIn !== undefined) {
      isAppLoggedIn = appData.isAppLoggedIn;
    }
    let userName = "Guest";
    if(appData.userName !== undefined) {
      userName = appData.userName;
    }

    let identityData=appFunctions.getData(props.projectData,'IdentityData', 'data')
    //let identitySchema=appFunctions.getData(props.projectData,'IdentitySchema', 'schemas')
    //let homeData=appFunctions.getData(props.projectData,'HomeData', 'data')
    //let projectSchema=appFunctions.getData(props.projectData,'ProjectSchema', 'schemas')

  /**
   * Determines if keyId property is not email contact.  
   * 
   * When the route is EmailContact the Contact button is not shown.
   *
   * @function isNotEmailContact
   * 
   * @return {Boolean} True if keyId property is contactform, otherwise false.
   */
  function isNotEmailContact() {
    return !(props.keyId === "contactform");
  }

  /**
   * Determines if keyId property is not email contact.  
   * 
   * When the route is EmailContact the Contact button is not shown.  
   *
   * @function isNotThankyou
   * 
   * @return {Boolean} True if keyId property is contactform, otherwise false.
   */
  function isNotThankyou () {
    return !(props.keyId === "thankyou");
  }

  /**
   * Determines if index property is not resume.  
   * 
   * When the route is Resume the Resume button is not shown.  
   *
   * @function isNotResume
   * 
   * @return {Boolean} True if index property is resume, otherwise false.
   */
  function isNotResume() {
    return !(props.keyId === "resume");
  }

    /**
   * Determines if keyId property is not identity.  
   * 
   * When the route is Identity the Identity button is not shown.  
   *
   * @function isNotIdentity
   * 
   * @return {Boolean} True if keyId property is identity, otherwise false.
   */
  function isNotIdentity() {
    return !(props.keyId === "identity");
  }

      /**
   * Determines if keyId property is not schema.  
   * 
   * When the route is Schema the Schema button is not shown.  
   *
   * @function isNotSchema
   * 
   * @return {Boolean} True if keyId property is schema, otherwise false.
   */
  function isNotSchema() {
    return !(props.keyId === "schema");
  }

  /**
   * Determines if keyId property is not lightbox.  
   * 
   * When the route is Lightbox the Resume and Contact buttons are not shown.  
   *
   * @function isNotLightbox
   * 
   * @return {Boolean} True if keyId property is lightbox, otherwise false.
   */
  function isNotLightbox() {
    return !(props.keyId === "lightbox");
  }

  /**
   * Resume button menu for viewing PDF, downloading PDF/Doc file and close.  
   *
   * @function resumeButtons
   */
  function resumeButtons() {
    return (
      <div key="divresumebuttons" align="center">
          <a key={"hcresumetab"+props.keyId} href={identityData.resumeFilePDF} target="resume" rel='noopener'>
              <StyledButtonModFunction key={"hcresumetabbutton"+props.keyId} text="View PDF" title={"Open tab for "+identityData.identityName+"'s PDF resume"}
              />
          </a>
          <a key={"hcdownloadPDF"+props.keyId} href={identityData.resumeFilePDF} download={identityData.resumeNamePDF}>
              <StyledButtonModFunction key={"hcdownloadbutton"+props.keyId} text="Download PDF" title={"Download "+identityData.identityNameF+"'s PDF resume to file: "+identityData.resumeNamePDF}
              />
          </a>
          <a key={"hcdownloadDOC"+props.keyId} href={identityData.resumeFileDOC} download={identityData.resumeNameDOC}>
              <StyledButtonModFunction key={"hcdownloadbutton"+props.keyId} text="Download DOC" title={"Download "+identityData.identityName+"'s DOC resume to file: "+identityData.resumeNameDOC}
              />
          </a>
          <StyledButtonModFunction key={"hcclosebutton"+props.keyId} text="Close" 
            title={"Close resume for "+identityData.identityName}
            selectionHandler={() => {
              window.history.back();
            }}
          />
        </div>
    );
  }

  /**
   * Phone number button, when mobile includes a link to phone number.  
   *
   * @function phoneButton
   */
  function phoneButton() {
    if(appFunctions.isMobileDevice()) {
      return <a  key={"hcphonelink"+props.keyId} 
                href={"tel: "+identityData.identityPhone}>
                <StyledButtonModFunction  
                  key={"hcphonebuttondial"+props.keyId} 
                  text={identityData.identityPhone} 
                  title={"Open phone# link to call "+identityData.identityName}
                />
            </a>
    } else {
      return <StyledButtonModFunction 
                key={"hcphonebuttonnodial"+props.keyId} 
                text={"Ph#: "+identityData.identityPhone} 
                title={identityData.identityName+"'s phone number"}
                />
    }
  }
  /**
   * Text number button, when mobile includes a link to open text message app on mobile.  
   *
   *
   * @function textButton
   *
   * @param {String} label for sms link button.
   * @param {String} phone to send sms message to, when empty messanger app prompts for number.
   * @param {String} message body of sms to send to phone.
   *
   * @return {Object} a tag with styled button using label for sms phone and message.
   */
  function textButton(label,phone, message) {
    if(appFunctions.isMobileDevice()) {
      let body = message.replace(" ", "%20");
      let href = "sms:"+phone+"?&body="+body;
      let t=0;
      return <a  key={"hcptextlink"+props.keyId} 
                href={href}>
                <StyledButtonModFunction  
                  key={"hctextbuttondtype"+props.keyId} 
                  text={label} 
                  title={"Open sms# link to "+label}
                  />
            </a>
    } else {
      return null
    }
  }

  /**
   * Resume button when keyId is not for a resume.  
   *
   * @function resumeButton
   */
  function resumeButton() {
    if(isNotResume()) {
      return (<Link key={"hcresumelink"+props.keyId} to="/Resume">
              <StyledButtonModFunction 
                key={"hcresumelinkbutton"+props.keyId}
                text="Resume"
                title={"View "+identityData.identityName+"'s resume."}
                />
            </Link>
      );
    }
  }

   /**
   * Identity button when keyId is not for a resume.  
   *
   * @function identityButton
   */
  function identityButton() {
    if(appFunctions.isLocalHost() && isNotIdentity() ) {
      return (<Link key={"hcidentitylink"+props.keyId} to="/Identity">
              <StyledButtonModFunction 
                key={"hcidentitylinkbutton"+props.keyId}
                keyId={props.keyId}
                text="Identity"
                title={"View "+identityData.identityName+"'s identity form."}
                />
            </Link>
      );
    }
  }

  /**
   * Schema button when keyId is not for a schema.  
   *
   * @function schemaButton
   */
  function schemaButton() {
    if(appFunctions.isLocalHost() && isNotSchema() ) {
      return (<Link key={"hcschemalink"+props.keyId} to="/Schema">
              <StyledButtonModFunction 
                key={"hcschemalinkbutton"+props.keyId}
                text="Schema"
                title={"View "+identityData.identityName+"'s schema form."}
                />
            </Link>
      );
    }
  }

  /**
   * Contact button with link to ContactForm.  
   *
   * @function contactButton
   */
  function contactButton() {
    return (
            <Link  key={"hccontactform"+props.keyId} to="/ContactForm" >
              <StyledButtonModFunction key={"hchccontactbutton"+props.keyId} 
                          text={identityData.identityEmail} 
                          title={"Contact Form for "+identityData.identityName+" at "+identityData.identityEmail}
                          />
            </Link>
      );
  }
  
  let isHomePage = false;
  let seoTitle = appFunctions.getRouteFromLocationHref();
  if(seoTitle === "") {
    isHomePage = true;
    seoTitle = identityData.identityName+"'s Software Developer Resume and Portfolio";
  }
  if(seoTitle === "Resume") {
    seoTitle = "Resume for "+identityData.identityName+" Software Developer"
  } else {
    if(props.title !== undefined) {
      seoTitle = props.title;
    }
  }
  document.title = seoTitle;
  if(appDefaults.isReactTracker) {
    appTracking.trackingScript(document.title);
  }


  const styles = {};
  
  styles.fill = {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  };
  
  styles.content = {
    ...styles.fill,
    top: '40px',
    textAlign: 'center'
  };
  
  styles.nav = {
    padding: 0,
    margin: 0,
    position: 'absolute',
    top: 0,
    height: '40px',
    width: '100%',
    display: 'flex'
  };
  
  styles.navItem = {
    textAlign: 'center',
    flex: 1,
    listStyleType: 'none',
    padding: '1px'
  };
  let vpWidth = window.innerWidth;//vvp is experimental - window.visualViewport.width;
  let outerWidth = window.outerWidth;
  let innerWidth = window.innerWidth;
  let zoom = Math.round(((outerWidth / innerWidth)*100))/100;
  let zoomMsg = "";
  if(zoom>1.1 || zoom <.95) {
    zoomMsg = "View Portfolio at 100% zoom is "+zoom+".";
  }

  let responsiveColWidthConstraint = outerWidth;//innerWidth;
  let leftColWidth = 75;
  let leftColHeight = 25;
  if(appFunctions.isMobileDevice())  {
    leftColWidth = 50;
    leftColHeight = 40;
    if(responsiveColWidthConstraint > 500) {
      leftColWidth = 75;
      leftColHeight = 75;
    }
    if(responsiveColWidthConstraint > 560) {
      leftColWidth = 200;
      leftColHeight = 100;
    }
  } else {
    if(responsiveColWidthConstraint > 575) {
      leftColWidth = 95;
      leftColHeight = 30;
    }
    if(responsiveColWidthConstraint > 650) {
      leftColWidth = 105;
      leftColHeight = 40;
    }
    if(responsiveColWidthConstraint > 750) {
      leftColWidth = 115;
      leftColHeight = 50;
    }
  }
  leftColHeight=leftColWidth;
  let centerColWidth = 600;//now 100%
  let centerColHeight = leftColHeight;
  let rightColWidth = leftColWidth;
  let rightColHeight = leftColHeight;
  let widthOfColumns = leftColWidth+centerColWidth+rightColWidth;

  let viewRatio = vpWidth/widthOfColumns;

  let vpDebug = zoomMsg+" isM="+appFunctions.isMobileDevice()+" vpw="+vpWidth+" oW="+outerWidth+" iW="+innerWidth+" leftcolW="+leftColWidth+" leftcoH="+leftColHeight;


  let titleText = "Portfolio of \n"+identityData.identityName;
  
  let titleFont = vwTOpx(5);
  if(appFunctions.isMobileDevice())  {
    titleFont = vwTOpx(10);
  }

  let titlePixelWidth = getPixelWidthOfTextForFontSize(titleText, titleFont, true);

  let spaceCharacter="X";
  let spaceFontSizeInPixels = vwTOpx(2.1);
  let spaceCharPixelWidth = getPixelWidthOfTextForFontSize(spaceCharacter, spaceFontSizeInPixels, true);

  let totalSpaceInPixels = (centerColWidth*viewRatio) - titlePixelWidth;
  let totalSpaceChars = parseInt((totalSpaceInPixels / spaceCharPixelWidth)/4);
  //let spacerText=spaceCharacter.repeat(totalSpaceChars);

  let spaceDebug = "tsp="+totalSpaceInPixels+" #c="+totalSpaceChars;

  let iconWidthInPixels=49;
  
      
  let sizeDebug = vpDebug+" titleW="+titlePixelWidth+" spW="+spaceCharPixelWidth;
  
  let titleFitFontSize = 1 * getFontSizeOfTextFitToViewportForFontSize(titleText, appData.titleFontSize, true, iconWidthInPixels);
  let sizeRatio = titleFitFontSize/appData.titleFontSize;
// props.setReactiveFontSize(titleFitFontSize);
//    let sizeDebug2 = sizeDebug+". fit size ="+titleFitFontSize.toString()+" newWidth="+getPixelWidthOfTextForFontSize(titleText, titleFitFontSize, true )+". ratio="+sizeRatio;

  let sizeDebug2 = vpDebug;//sizeDebug+" sp="+totalSpaceInPixels+spaceDebug;
  let showDebug=false;

  let headerFitFontSize = headerFontSize;
  if(sizeRatio*headerFitFontSize<16) {
    headerFitFontSize = minimumHeaderFontSize;
  } else {
    headerFitFontSize = parseInt(sizeRatio*headerFitFontSize);
  }

  let headerText = "";
  if(isNotEmailContact() || isHomePage) {
    headerText = "Software Developer Portfolio";
  }
  if(!isNotThankyou()) {
    headerText = "Thank you for your contact!";
  }
  if(!isNotResume()) {
    headerText = "Resume";
  }
  let ref = appFunctions.getRouteFromLocationHref();
  if(!isNotLightbox()) {
    headerText = "Lightbox for "+ref.substring(8);
  }
  
  let logoWidth="50%";
  let titleFontR="5vw";
  let idFontR="7vw";
  let headerFontR="4vw";
  buttonFont="3vw";
  if(appFunctions.isMobileDevice())  {
    logoWidth="100%";
    titleFontR="10vw";
    idFontR="11vw";
    headerFontR="8vw";
  }
  let centerCol2Width="100%";
  let hrefForSMS="sms:?&body=Check%20out%20"+identityData.identityName.replace(" ","%20")+"'s%20resume%20<a%20href='https://"+appFunctions.getHost()+"/Resume'>resume</a>";
    return (
      <div key={"divmain"+props.keyId} 
          style={{ position: 'relative', display: 'block',zIndex:'30'
          }}
      > 
        <div key={"flexboxdivtitle"+props.keyId} 
              style={{display: 'flex', zIndex:'30', top: '0px',
                position: 'sticky',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-between:',
                border: '0px',
                backgroundImage: 'linear-gradient(rgba(255,255,255,1), rgba(255,255,255,.99), rgba(255,255,255,.95), rgba(255,255,255,.1))'
              }} 
        >
          <div key={"flexboxdivmobilemenu"+props.keyId} 
                style={{
                          width: leftColWidth,
                          height: leftColHeight, zIndex:'30'
                        }}
          >
            <LinkMenuFunction key={"link"+leftColWidth+leftColHeight}
              auth={appData.auth}
              width={leftColWidth}
              height={leftColHeight}
              isUseLogIn={isUseLogIn}
              isAppLoggedIn={isAppLoggedIn}
            />
          </div>
          {appFunctions.isMobileDevice() 
            ? <div key="flexboxdivtitlegroup" align="center"
                    style={{  width: centerCol2Width,
                        height: centerColHeight,
                        position: 'relative', display: 'block', zIndex:'30'
                    }}
                >
                  {contactButton()}
                </div>
            : <div key="flexboxdivtitlegroup" align="center"
                    style={{  width: centerCol2Width,
                        height: centerColHeight*.75,
                        position: 'relative', display: 'block',zIndex:'30',
                        margin: '0',
                        top: '0%',
                        transform: 'translateY(25%)'
                    }}
              >
                {contactButton()}
              </div>
          }
          <div key={"flexboxdivreplymenu"+props.keyId}  align="right" right="60px"
              style={{ width: rightColWidth, 
                  height: rightColHeight,zIndex:'30',
                  color: 'rgba(0,0,0,0)'
                }}
          >
           <NavMenuFunction key={"nav"+rightColWidth+rightColHeight}
                width={rightColWidth}
                height={rightColHeight}
                isAppLoggedIn={appData.isAppLoggedIn}
            />
          </div>
        </div>{/*end of flex-box with row of left, right and centered buttons*/}
        <div key="dividentityButtonGroup" align="center"
                  style={{  width: centerCol2Width,
                    zIndex:'0',  position: 'sticky',
                    display: 'block'
                  }}
        >
          {phoneButton()}
          {resumeButton()}
          {identityButton()}
          {schemaButton()}
          </div>{/*end of flex-box with row of centered buttons*/}
          <div key="dividentityIDFlex" align="center"
                style={{display: 'flex', zIndex:'0', top: '0px',
                    position: 'relative',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between:',
                    border: '0px'
                }} 
          >
            <div key={"dividentityIDleft"}  width="5%"  align="left"
                style={{position: 'relative',display: 'inline-block', zIndex:'0', 
                    color: 'rgba(0,0,0,0)'
                }}
            >
            xxxx
            </div>
            <div key="dividentityIDGroup" className="divGroup" width="90%" align="center"
                      style={{  width: centerCol2Width,
                        zIndex:'0',  position: 'relative',
                        display: 'block'
                      }}
            >
              <p className="text3d" style={{position: 'relative', zIndex:'0', 
                            display: 'block',
                            float: 'center',
                            margin: '0px 0px',
                            padding: '0px 0px',
                            textAlign: 'center',
                            fontSize: idFontR,
                            fontWeight: 'bold',
                            color: 'black'}}>
                    {identityData.identityName}
              </p> 
              <p className="text3d" style={{position: 'relative', zIndex:'0', 
                          float: 'center',
                          margin: '0px 0px',
                          padding: '0px 0px',
                          textAlign: 'center',
                          fontSize: headerFontR,
                          fontWeight: 'bold',
                          color: 'black'
                        }}
              >
                  {headerText}
              </p> 
            </div>
            <div key={"dividentityIDright"}  width="5%"  align="left"
                    style={{position: 'relative',display: 'inline-block', zIndex:'0', 
                        color: 'rgba(0,0,0,0)'
                    }}
                >
                xxxx
            </div>
        </div>
        {!isNotResume()&& !appFunctions.isMobileDevice() &&
          <div key="divresumebuttongroup" align="center">
            {resumeButtons()}
          </div>
        }
        {!isNotResume() && appFunctions.isMobileDevice() &&
          <div key="divresumebuttongroup" align="center">
            <a  key={"hcptextlink"+props.keyId} 
                  href={hrefForSMS}>
                  <StyledButtonModFunction  
                    key={"hctextbuttondtype"+props.keyId} 
                    text={"Share"} 
                    title={"Open sms# share link"}
                    />
            </a>
            {resumeButtons()}
          </div>
        }
        {showDebug &&
          <div key="divzoomwarning" align="center"
                style={{  position: 'relative', display: 'block', zIndex:'0'
                }}
          >
            <p style={{position: 'relative', zIndex:'0', 
                                display: 'inline-block',
                                float: 'center',
                                margin: '0px 0px',
                                padding: '0px 0px',
                                textAlign: 'center',
                                fontSize: '4vw',
                                fontWeight: 'bold',
                                color: 'red'}}>
                    {sizeDebug2}
            </p>
          </div>
        }

       
      </div>
    );
}