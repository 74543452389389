/**
* @fileOverview Utility methods for tracking user actions.
*
* @module AppTracking
*/
import * as React from 'react';

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
/**
* Track button event for type, textContent and title.  
*
* @function trackButtonEvent
*
* @param {Object} event click event.  
* 
*/
export const trackButtonEvent = function(event) {
    appFunctions.logLocalHostOnly("trackButtonEvent type="+event.type+" content="+event.target.textContent+' event-'+event.target.title);
 /*   var _paq = window._paq = window._paq || [];//set global for matomo.js
    let f = findPaqKey(_paq,'trackEvent');
    if(f <0) {
     _paq.push(['trackEvent', event.type, event.target.textContent,  'event-'+event.target.title]);
    } else {
        _paq[f][1] = event.type;
        _paq[f][2] = event.target.textContent;
        _paq[f][3] = 'event-'+event.target.title;
    }*/
}
/**
* Track route event for type and route.  
*
* The route event is the result of a navigation by click, drag, swipe 
* or auto advance.
*
* @function trackRouteEvent
*
* @param {String} type of route to track.  i.e. next, previous, left, right
* @param {String} route name to track.
* 
*/
export const trackRouteEvent = function(type, route) {
    appFunctions.logLocalHostOnly("trackRouteEvent type="+type+" route="+route);
    var _paq = window._paq = window._paq || [];//set global for matomo.js
    _paq.push(['trackEvent', 'route', type,  'event-'+route]);
}

/**
* Track page view with message by adding trackPageview and message to window._paq.  
*
* 
* @function trackPageView
*
* @param {String} message for tracking page view.  
*
*/
export const trackPageView = function(message) {
 //   var _paq = window._paq = window._paq || [];//set global for matomo.js
//    _paq.push(['trackPageView',  'script-'+message]);
}

/**
* Track page view with message by adding/updating matomo javascript.  
*  
* Mamoto tracker adds javascript for current page using pageName.  
*
* Initially the first script will be the static/js/bundle.js and the mamoto.js script
* is added before the bundle.js.  Subsequently the matomo.js script replaces the first
* script from then on.
* 
* @function trackPageView
*
* @param {String} message for tracking page view.  
*
*/
export const trackingScript = function(message) {
    var _paq = window._paq = window._paq || [];//set global for matomo.js
    let f = findPaqKey(_paq,'trackPageView')
    if(f < 0) {
        _paq.push(['trackPageView', 'script-'+message])
    } else {
        _paq[f][1] = 'script-'+message;
    }
    f = findPaqKey(_paq,'enableLinkTracking')
    if(f < 0) {
        _paq.push(['enableLinkTracking'])
    } 
    (function() {
        let u="https://digillax.com/analytics/";
        f = findPaqKey(_paq,'setTrackerUrl')
        if(f < 0) {
            _paq.push(['setTrackerUrl', u+'matomo.php'])
        } else {
            _paq[f][1] = u+'matomo.php';
        }
        f = findPaqKey(_paq,'setSiteId')
        if(f < 0) {
            _paq.push(['setSiteId', '2'])
        } else {
            _paq[f][1] = '2';
        }
     
        let d=document, g=d.createElement('script'),sa =d.getElementsByTagName('script'), s=d.getElementsByTagName('script')[0];
        g.async=true;
        g.src=u+'matomo.js';
        if(sa.length<2) {
            s.parentNode.insertBefore(g,s);
        } else {
            s.parentNode.replaceChild(g,s);
        }
    })();
}




export const findPaqKey = function(data, key) {
    let found = -1;
     for( let i=0; i< data.length;i++) {
        if(data[i][0] === key) {
            found = i;
        }
     }
     return found;
}