/**
* @fileOverview Mobile reactive methods for fontsizes.  
*
* @module AppFontSizes
*/
import * as appFunctions from "./AppFunctions";

   /**
   * Set reactiveFont state to fontSize.
   * 
   * @function setReactiveFontSize
   * @param {String} fontSize 
   */
  export const setReactiveFontSize = function(fontSize) {
    this.reactiveFontSize = fontSize;
   // appFunctions.logLocalHostOnly("setReactiveFontSize="+this.reactiveFontSize);
  }

   /**
   * Get reactive font ratio as the reactiveFontSize divided by the titleFontSize.
   * 
   * @function getReactiveFontRatio
   *
   * @return {Number} reactiveFontSize/titleFontSize
   */
  export const getReactiveFontRatio = function() {
    return this.reactiveFontSize/this.titleFontSize;
  }
/**
* Make number a string with px suffix for use in css styles.
*
* @function makeNumberPixels
*
* @param {Number} number to add suffix to
*/
export const  makeNumberPixels = function(number) {
    let s=number.toString();
    return s+"px";
}
/**
  * Convert relative length width units, vw, to pixels.
  *
  * @function vwTOpx
  *
  * @param {Number} value of vw units to convert to pixels.
  *
  * @return {Number} value converted to pixels.
  */
export const vwTOpx = function (value) {
  let w = window,
  d = document,
  e = d.documentElement,
  g = d.getElementsByTagName('body')[0],
  x = w.innerWidth || e.clientWidth || g.clientWidth,
  // eslint-disable-next-line no-unused-vars
  y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  let result = (x*value)/100;
  return result;
}

/**
  * Convert pixels to relative length width units, vw.
  *
  * @function pxTOvw
  *
  * @param {Number} value of pixels to convert to vw units 
  *
  * @return {Number} value converted to vw.
  */
export const pxTOvw = function (value) {
  let w = window,
  d = document,
  e = d.documentElement,
  g = d.getElementsByTagName('body')[0],
  x = w.innerWidth || e.clientWidth || g.clientWidth,
  // eslint-disable-next-line no-unused-vars
  y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  let result = (100*value)/x;
  return result;
}

/**
  * Get the pixel width of text for the font size and fontWeight of bold/normal.
  *
  * @function getPixelWidthOfTextForFontSize
  *
  * @param {String} text to get pixels width.
  * @param {Number} fontSize to get pixel width.
  * @param {Boolean} bold true for fontWeight of bold, otherwise false for fontWeight of normal.
  * 
  * @return {Number} width of text in pixels for fontSize and fontWeight.
  */
export const getPixelWidthOfTextForFontSize = function (text, fontSize, bold) {
  let pixelWidth = require('string-pixel-width');
  let textWidth = pixelWidth(text, { size: fontSize, bold: bold });
  return parseInt(textWidth.toString());
}

/**
  * Get the pixel width of text that is length characters for the font size and fontWeight of bold/normal.
  * .i.e. the default character of G is used to calulate pixel size 
  *
  * @function getPixelWidthOfTextLengthForFontSize
  *
  * @param {String} length of text to get pixels width.
  * @param {Number} fontSize to get pixel width.
  * @param {Boolean} bold true for fontWeight of bold, otherwise false for fontWeight of normal.
  * 
  * @return {Number} width of text of length in pixels for fontSize and fontWeight.
  */
export const getPixelWidthOfTextLengthForFontSize = function (length, fontSize, fontName, bold) {
  let pixelWidth = require('string-pixel-width');
  let text = "";
  for (let i=0; i < length; i++) {
    text = text + "G";
  }
  let textWidth = pixelWidth(text, { font:fontName, size: fontSize, bold: bold });
  return parseInt(textWidth.toString());
}
/**
  * Get the pixel width of text for the font name, size and fontWeight of bold/normal,
  * font size can be px or vw.  Default is px, vw is converted to px.
  *
  * @function getPixelWidthOfTextForNamedFontSize
  *
  * @param {String} text to get pixels width.
  * @param {Number} fontSize in px or vw, default is px to get pixel width, 
  * @param {String} fontName to get pixel width.
  * @param {Boolean} bold true for fontWeight of bold, otherwise false for fontWeight of normal.
  * 
  * @return {Number} width of text in pixels for fontName, fontSize and fontWeight.
  */
export const getPixelWidthOfTextForNamedFontSize = function (text, fontSize, fontName, bold) {
  let pixelWidth = require('string-pixel-width');
  let numericFontSize = parseInt(fontSize);
  if(fontSize.endsWith("vw")) {
    numericFontSize=vwTOpx(numericFontSize);
  }
  let textWidth = pixelWidth(text, { font:fontName, size: numericFontSize, bold: bold });
  return parseInt(textWidth.toString());
}

  /**
  * Get the font size of text to fit the view port for the font size and fontWeight of bold/normal,
  * with a left/right margin in pixels. i.e., width of left/right icons.
  * 
  * When the initial fontSize does not fit, finds the next
  * smaller fontSize that fits, down to the fontSizeMin or 16 pixels.
  * 
  * Defaults to minimum font size of 16 pixels unless fontSizeMin property defined.
  * 
  * i.e., at font size when fits, only shrink to fit using fontSizeMin or 16 pixels.
  *
  * @function getFontSizeOfTextFitToViewportForFontSize
  *
  * @param {String} text to fit in view port.
  * @param {Number} fontSize to fit text in view port in pixels. 
  * @param {Boolean} bold true for bold, otherw ise false.
  * @param {Number} iconWidthInPixels used as left/right margin for limiting space text must fit within.
  * @param {Number} fontSizeMin overrides fontSize even if it does not fit, undefined uses 16 pixels. 
  * @return {Number} font size that will fit text within margin on view port from fontSize and fontWeight,
  *    optionally overridden by the fontSizeMin, not defined uses 16 pixels as minimum.
  */
export const getFontSizeOfTextFitToViewportForFontSize = function (text, fontSize, bold, iconWidthInPixels, fontSizeMin) {
  let windowWidth = window.innerWidth-100;
  //appFunctions.logLocalHostOnly(text); 
  //appFunctions.logLocalHostOnly(fontSize); 
  //appFunctions.logLocalHostOnly(windowWidth);
  let fitFontSizeMin = 16;
  if(fontSizeMin !== undefined) {
      fitFontSizeMin = fontSizeMin;
  }
  iconWidthInPixels = iconWidthInPixels *2;
  let textWidth = getPixelWidthOfTextForFontSize(text, fontSize, bold);
  if((textWidth + iconWidthInPixels) < windowWidth) {
    //appFunctions.logLocalHostOnly("already fits "+fontSize);
    return fontSize;
  }
  let foundSize = fitFontSizeMin;
  let found = false;
  for (let i=fontSize-1; i > fitFontSizeMin; i--) { 
    textWidth = getPixelWidthOfTextForFontSize(text, i ,bold);
    if(!found && (textWidth + iconWidthInPixels) < windowWidth ) {
      foundSize = i;
      found = true;
    }
  }
  //appFunctions.logLocalHostOnly("done fit "+found, foundSize);
  return foundSize;
}
