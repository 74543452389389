/**
* @fileOverview Utility methods working with routes, reactive display and matomo tracking.  
*
* @module AppFunctions
*/
import * as React from 'react';
import { isMobile } from "react-device-detect";

import preval from 'preval.macro';

import * as appDefaults from "../modules/AppDefaults";


/**
* Determines if running on mobile device using screen.orientation and navigator.userAgent.  
*
* @function isMobileDevice
*
* @return {Boolean} True if mobile device, otherwise false.
*/
export const isMobileDevice = function() {
    return isMobile;
};
/**
* Determines if the documents body has a visible width of 450 or less pixels.  
*
* @function isSmallWindow
*
* @return {Boolean} True if visible body width is less than or equal to 450 pixels, otherwise false.
*/
export const isSmallWindow = function() {
    let d = document;
    let g = d.getElementsByTagName('body')[0];
    let x = g.clientWidth;
    return (x<=450);
};
/**
* Indicate window.location.hostname is localhost.  
*
* @function isLocalHost
*
* @return {Boolean} True if local host, otherwise false.
*/
export const isLocalHost = function () {
    let isLocal = false;
    let hostName = window.location.hostname;
    if(hostName.toLowerCase() === "localhost") {
        isLocal = true;
    }
    return isLocal;
};

/**
* Get the hostname from the location property of the window.  
*
* @function getHost
*
* @return {String} host name property of window.location.
*/
export const getHost = function () {
    return window.location.hostname;
};

/**
* Log only when running from local host.  
*
* The tag is applied against appDefaults.Tag list and only
* matched values are sent to console.
*
* @function logLocalHostOnly
*
* @param {String} tag for console message.
* @param {String} logString to set to console.
*/
export const logLocalHostOnly = function (...logString) {
    // let args = Array.from(arguments);
    //  let callee = arguments.callee.toString();
    let isLocal = isLocalHost();
    let tag = "All";
    //console.trace();
    if(isLocal || appDefaults.debugMode ) {
 //       if(appDefaults.debugTags.indexOf(","+tag+",")>=0) {
            // console.log(logString);
            console.debug(logString.toString());
 //       }
    }
};
/**
* Get the current path from the window.location.href.  
* 
* The protocol, host name and port number are removed.
*
* @function getCurrentPath
*
* @return {String} The current path. i.e., url with the domain removed.
*/
export const getCurrentPath = function () {
    let curl = window.location.href;
    let protocol = window.location.protocol;
    let hostname = window.location.hostname;
    let currentPath = curl.replace(protocol+"//","");
    currentPath = currentPath.replace(hostname,"");
    currentPath = currentPath.replace(":3000","");
    return currentPath;
};
/**
* Determines if slider property is off or on.  
* 
* When the route is a slider, nav buttons are not shown.
* 
* @return {Boolean} True if index property is lightbox, otherwise false.
*/
export function isNotSliderOn() {
    return !(appDefaults.isSliderOn);
}
/**
* Determines if json data should read from the default location or 
* from remote deployed target using the appDefaults.isRemoteJSON 
* property and isLocalHost method.  
*
* When the app is deployed, the json data is read directly using fetch.  
* When running localhost a fetch may error with the host name in the url,
* due to cors or other server setting.  A getjsondata.php script is run
* to get the json using a post method.  
* The purpose is so edits are against server data rather than localhost data.  
* 
* @return {Boolean} True if localhost and isRemoteJSON is false, otherwise false.
*/
export function isNotRemoteJSON() {
    let remote = false;
    if(isLocalHost() && appDefaults.isRemoteJSON ) {
        remote = true;
    }
    return !(remote);
}
/**
* Determines if the current path is an invalid route.  
* 
* @function isNotValidPath
*
* @param {String} validRoutes comma delimited list of valid routes.
* @return {Boolean} True for current path is not in validRoutes, otherwise false.
*/
export const isNotValidPath = function (validRoutes) {
    let curl = getCurrentPath();
    let routeFound = validRoutes.indexOf(","+curl+",");
    return routeFound < 0;
};
/**
* Get the last node of window.location.href.  
*
* @function getRouteFromLocationHref
*
* @return {String} last node of href.
*/
export const getRouteFromLocationHref = function () {
    let curl = window.location.href;
    let refs = curl.split("/");
    let ref = refs[refs.length-1];
    return ref;
};

/**
* Determine active route when name is the last node of window.location.href.  
*
* @function getRouteFromLocationHref
*
* @param {String} name to check if it is the current route.  
*
* @return {Boolean} True when name matches the last node of href.
*/
export const isActiveRoute = function (name) {
    let active = false;
    if(getRouteFromLocationHref() === name) {
        active = true;
    }
    return active;
};

  /**
   * Get schema array from schema JSON.  
   * 
   * @param {JSON} schema to get array from.
   * @returns {Array} 
   */
  export const getSchemaArray = function(schema) {
    let workArray = {};//{schema:[schema]};
    let workString = "{schema:[";
    let schemaArray = [];
    let x;
    for (x in schema) {
      //    let xs = schema[x].toString();
      //  workString = workString + JSON.stringify(schema[x]+",");
      //let workArray = [];
      let xa = [];
      xa.push( schema[x]);
      workArray[x] =xa;
      schemaArray.push(workArray);
    }
       return workArray;
  }

   /**
   * Get json data for name and type from json.  
   *
   * The json contains portfolios, data and schema data set types
   * with each having a name. i.e. get data for name and type.
   * 
   * @function getData
   * 
   * @param {JSON} json containing name and type of json to return.
   * @param {String} name to check for existing.
   * @param {String} type portfolios or schemas.
   * 
   * @returns {JSON} for name and type found in json, empty when not found.
   */ 
  export const getData = function(json, name, type) {
    let foundIndex = -1;
    if(type === 'portfolios') {
      foundIndex = json.portfolios.findIndex((value) => {
        return value.name === "/"+name;
      })
      if(foundIndex>=0) {
        return json.portfolios[foundIndex]
      }
    }
    if(type === 'data') {
      foundIndex = json.data.findIndex((value) => {
        return value['name'] === name;
      })
      if(foundIndex>=0) {
        return json.data[foundIndex]['data']
      }
    } 
    if(type === 'schemas') {
      foundIndex = json.schemas.findIndex((value) => {
        return value['name'] === name;
      })
      if(foundIndex>=0) {
        return json.schemas[foundIndex]['schemas']
      }
    }
    return {};
  }