/** 
 * @fileOverviewtion Display portfolio version informationa page footer with the 
 * React version, build date, current date and days since deployed.  
 *
 * @module CompiledVersionFunction
 */
import React from 'react';
import { Link } from 'react-router-dom';
import preval from 'preval.macro';
import dayjs from 'dayjs';

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
import * as appCache from "../modules/AppCache";

import DivGroupFunction from "./DivGroupFunction";

/**
* Implements display of a page footer with the React version, build date, current date
* and days since deployed.
*
* The buildTimeStamp property is set in the main App.js using a preval macro that
* runs as part of the build.
* 
* @function CompiledVersionFunction
*
* @param {object} props 
*
* @property {Number} buildTimeStamp is used as the most recent build date.
*/
export default function CompiledVersionFunction(props) {
    
    /**
     * Get buildTimeStamp property formatted as MM/DD/YYYY HH:mm:ss.  
     * 
     * @function getDateString 
     *
     * @return {String} Property buildTimeStamp formatted as MM/DD/YYY HH:mm:ss.
     */
    function getDateString() {
        const formattedDate = dayjs(props.buildTimeStamp).format('MM/DD/YYYY HH:mm:ss');
        return formattedDate;
    }; 

    /**
     * Get formatted current date as MM/DD/YYYY HH:mm:ss.
     *
     * @function getDateNowString
     *
     * @return {String} Date in MM/DD/YYYY HH:mm:ss format.
     */
    function getDateNowString() {
        const rightNow = dayjs();
        const formattedDate    = rightNow.format('MM/DD/YYYY HH:mm:ss');
        return formattedDate;
    };

    /**
    * Get year of current date.  
    * 
    * @function getDateNowYear
    *
    * @return {String} Year as four digits.
    */
   function getDateNowYear() {
        const rightNow = dayjs();
        const formattedDate = rightNow.format('YYYY');
        return formattedDate;
    };

    /**
     * Get time since deployed formatted with days since deployed message.  
     *
     * Possible days since depolyed messages.  
     *
     * <ul class='circle'>
     * <li>=0 : today</li>
     * <li>=1 : yesterday</li>
     * <li>>1 : 99 days ago</li>
     * </ul>
     * 
     * @function getTimeSinceDeployed
     *
     * @param {Number} buildTimeStamp date/time of runtime compile.
     *
     * @return {String} Days since depolyed message.
     */
    function getTimeSinceDeployed(buildTimeStamp) {
        const rightNow = dayjs();
        let diff = rightNow.diff(dayjs(buildTimeStamp),'day');
        switch(diff) { 
            case 0:
                diff='today';
                break;
            case 1:
                diff='yesterday';
                break;
            default:
                diff=diff+' days ago';
        }
        return diff;
    }

    /**
     * Get name and version string of compiled app from package.json 
     * environment variables.  
     *
     * Note: changes to dotenv require the localhost to restart for changes
     * to be loaded.  The data is embedded in the bundle.js, so do not put
     * any authorization or private info in the dotenv file.
     *
     * @function getNameVersionString
     *
     * @return {String} Name of application and version from env via package.json
     */
    function getNameVersionString() {
        const appName = process.env.REACT_APP_NAME;
        const version = process.env.REACT_APP_VERSION;
        return appName + " " + version;
    };
    
    /**
    * Display a DivGroupFunction with version information.
    *
    * Includes a copyright, todays date, react version, compile date
    * and the number of days since deployed. 
    */
    let today = getDateNowYear();
    return (
        <DivGroupFunction style={{font:appDefaults.projectViewTextFontName, fontSize: '1.5vw',position: 'relative'}}>
            <div key={"versioncenter"}>
                {getNameVersionString()}
                {" by Parallax Pictures "}
                {<Link to="/CopyrightPolicy">Copyright {today}</Link>}
                {", Today's Date: "}
                {getDateNowString()}
                {", ReactJS ver: "}
                {React.version}
                {' Compile Date: '}
                {getDateString()}
                {' Deployed '}
                {getTimeSinceDeployed(props.buildTimeStamp)}
                {'.'}
            </div>
        </DivGroupFunction>
    );
}

