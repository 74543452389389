/**
* @fileOverview Utility methods working with meta tags for SEO.  
*
* @module AppMetaTags
*/
import * as React from 'react';

import * as appDefaults from "../modules/AppDefaults";

/**
* Set metadata to provide seo info for a n identity and project.  
*
* @function setMetaData
*
* @param {JSON} identity to get author name.
* @param {JSON} project to get description and keywords.
*/
export const setMetaData = function(identity, project) {
    setMetaTag("description",project.head2P);
    setMetaTag("author",identity.identityName);
    setMetaTag("keywords",project.head1List.toString());
}

/**
* Set metadata to provide seo info for passed author, description and keywords.  
*
* @function setSEOData
*
* @param {String} author for meta tag author.
* @param {String} description for meta tag description.
* @param {String} keywords  for meta tag keywords. i.e. comma delimited list.
*/
export const setMetaSEOData = function(author, description, keywords) {
    setMetaTag("description",description);
    setMetaTag("author",author);
    setMetaTag("keywords",keywords);
}
/**
* Set metatags to provide seo info for a metaName and content  
*
* @function setMetaTag
*
* @param {String} metaName of meta tag to set.
* @param {String} content to set metaName to.
*/
export const setMetaTag = function(metaName,content) {
    document.querySelector("meta[name='"+metaName+"']").setAttribute("content", content);
}