/* eslint-disable no-unused-vars */
/** 
 * @fileOverview Display a div styled with an icon as a Dropdown menu of StyledButtons.  
 *  
 * @module DropdownFunction
 *
 * @author Philip Gottfried
 */
import React, {useState, useEffect} from 'react';

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";

import StyledButtonModFunction from "./StyledButtonModFunction";
import {pxTOvw} from "../modules/AppFontSizes";
import {vwTOpx} from "../modules/AppFontSizes";
import {getPixelWidthOfTextForFontSize} from "../modules/AppFontSizes";
import {makeNumberPixels} from "../modules/AppFontSizes";
import DivGroupFunction from "./DivGroupFunction";

/** 
* Implements a div button styled with an icon as a drop menu with 
* menu items of StyledButton in a ul of li.  
* Display a div to hold icon styled button consisting of:  
* <ul>
* <li>a div that acts as the dropdown button with an icon.</li>
* <li>a ul of li for each menuItem property.</li>
* <li>a StyledButton class.</li>
* </ul>
* 
* The dropdown button open/closes the dropdown menu.  
* A mouseOver/mouseOut high lights/resets dropdown button.  
* A dropdown menuItem click selects the menuItem.  
* A mouseOver/mouseOut high lights/resets menuItems.  
* A StyledButton is used for menuItems.  
* 
* Upon display of the menu a document listener is added after a delay, so
* any click outside the menu will cancel the menu, otherwise the document listener
* is removed.  
*
* @function DropdownFunction
*
* @param {Object} props property object
* 
* @prop {String} direction left or right alignment for dropdown menu, default left.
* @prop {number} offset number of pixels dropdown menu is from left or right of iconImage.
* @prop {number} width of dropdown in pixels, default 50.
* @prop {number} height of dropdown in pixels, default 50.
* @prop {function} selectionHandler is passed the menuItem's value for the selected key.
* @prop {String} iconImage name of image file, undefined uses hamburger svg for button image.
* @prop {String} menuMap list of key/values, key is label and value is the result.
* @prop {String} showcase list of additional key/values for menuMap. i.e. alt text, tool tip...
*
* @returns {div} containing drop down button styled with an icon and when 
* dropdownOpen is true displays dropdown menu of styled buttons.
*/


export default function DropdownFunction(props) {
   // appFunctions.logLocalHostOnly("app start "); 

    let listenerTimerDelay = 10;

    const [dropdownClicks, setDropdownClicks] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [buttonOverNow, setButtonOverNow] = useState(false);

    useEffect(() => {
        appFunctions.logLocalHostOnly("useEffect clicks "+dropdownClicks+' '+dropdownOpen);
    },[dropdownClicks]);

    useEffect(() => {
        //appFunctions.logLocalHostOnly("useEffect open "+dropdownClicks+' '+dropdownOpen);
        if(dropdownOpen) {
            setTimeout(() =>
                {document.addEventListener('click', cancelDropdownMenu)},
                800
            );
        }
    },[dropdownOpen]);

   // appFunctions.logLocalHostOnly("app after useEffect "+dropdownOpen+" "+dropdownClicks); 

    let overrideColors=true;
    let dropdownButtonColor="{255,255,255,1}";//"white, opaque";
    let dropdownButtonBackgroundColor="{0,0,0,.6}";//"black, partially transparent";
    let dropdownButtonActiveColor="{128,128,128,.6}";//"gray, partially transparent";
    let dropdownWidth=50;
    let dropdownHeight=50;
    if(props.width !== undefined) {
        dropdownWidth=props.width;
    }
    if(props.height !== undefined) {
        dropdownHeight=props.height;
    }

    let menuItemColor='black';
    let menuItemBackgroundColor="white";
    let menuItemActiveColor="gray";

    let mouseOverSVGback = false;
    let mouseIgnoreRect = false;

    let reactiveImage="images/assets/parallaxBurgerIcon.png";
    let reactiveWidth=parseInt(dropdownWidth);
    let reactiveHeight=parseInt(dropdownHeight);
    let reactiveLeftMargin=3;
    let reactiveTopMargin=3;
    let reactiveMenuPad=30;
    if(appFunctions.isMobileDevice()) {
        //reactiveWidth=reactiveWidth/2;
        //reactiveHeight=reactiveHeight/2;
        reactiveLeftMargin=reactiveLeftMargin/2;
        reactiveTopMargin=reactiveTopMargin/2;
        //reactiveMenuPad=15/2;
    } else {
        reactiveImage="images/assets/parallaxBurgerLogo2.png";
    //         reactiveWidth=reactiveWidth*2;
    //        reactiveHeight=reactiveHeight*2;
    }
    let buttonWidth=parseInt(reactiveWidth);
    let buttonHeight=parseInt(reactiveHeight);

    let dropDownNubW=0;
    let dropDownNubH=0;
    if(props.color === undefined) {
        dropDownNubW=10;
        dropDownNubH=10;
    }
    let dropDownNubColor="red";
    let dropDownNubBackgroundColor="maroon";
    let dropDownNubWidth=makeNumberPixels(buttonWidth+(dropDownNubW*5));
    let dropDownNubHeight=makeNumberPixels(buttonHeight+(dropDownNubH*5));

    let dropdownButtonWidth=buttonWidth+(dropDownNubW*4);
    let divButtonWidth=makeNumberPixels(dropdownButtonWidth);
    let divButtonHeight=makeNumberPixels(buttonHeight+(dropDownNubH*4));
    let divButtonColor="blue";
    let divButtonActiveColor="gray";
    let divButtonBackgroundColor="navy";

    let ulWidth=makeNumberPixels(buttonWidth+(dropDownNubW*3));
    let ulHeight=makeNumberPixels(buttonHeight+(dropDownNubH*3));
    let ulColor="green";
    let ulActiveColor="gray";
    let ulBackgroundColor="yellow";
    let ulLeft=0;

    let liWidth=makeNumberPixels(buttonWidth+(dropDownNubW*2));
    let liHeight=makeNumberPixels(buttonHeight+(dropDownNubH*2));
    let liColor="orange";
    let liActiveColor="gray";
    let liBackgroundColor="{0,0,0,.50}";//;"magenta"
    let liLeft=ulLeft;

    let divWidth=makeNumberPixels(buttonWidth+(dropDownNubW*1));
    let divHeight=makeNumberPixels(buttonHeight+(dropDownNubH*1));
    let divColor="white";
    let divActiveColor="gray";
    let divBackgroundColor="{0,0,0,.1}";//'black';

    let boxColor="orange";
    let strokeColor="pink";
    let strokeOpacity = 1;
    let strokeWidth = 1;
    let fillColor=strokeColor;
    let fillOpacity=1;

    let svgLeftMargin=reactiveLeftMargin;
    let svgTopMargin=reactiveTopMargin;
    let svgWidth=buttonWidth-(svgLeftMargin*2);
    let svgHeight=buttonHeight-(svgTopMargin*2);

    let leftMargin=reactiveLeftMargin; 
    let topMargin=reactiveTopMargin;
    let iconWidth=svgWidth-(leftMargin*2);
    let iconHeight=svgHeight-(topMargin*2);

    let w=iconWidth-leftMargin;
    let increment=(iconHeight-topMargin)/3;
    let h=increment/2;
    let x=leftMargin+svgLeftMargin;
    let y=topMargin+svgTopMargin+(h/2);
    let y1=y+increment;
    let y2=y+(increment*2);

    let menuItems=Object.keys(props.menuMap.menuItems);

    let maxLen = 0;
    let maxString = "";
    for (let name of menuItems) {
        if(name.length > maxLen) {
            maxLen=name.length+1;
            maxString = name;
        }
    }
    let myvwInpx = vwTOpx(3);
    if(appFunctions.isMobileDevice()) {
        myvwInpx = vwTOpx(6);
    }
    let maxMenuItemWidth = getPixelWidthOfTextForFontSize(maxString, myvwInpx, false);
    divWidth=makeNumberPixels(maxMenuItemWidth+reactiveMenuPad+2);
    ulWidth=makeNumberPixels(maxMenuItemWidth+reactiveMenuPad);
    liWidth=makeNumberPixels(maxMenuItemWidth+reactiveMenuPad);
    let liButtonWidth="100%";//makeNumberPixels(maxMenuItemWidth+reactiveMenuPad);
    let uloffset=0;
    if(props.direction !== undefined) {
        ulLeft=0;
        liLeft=0;
        if(props.offset !== undefined) {
            uloffset=props.offset;
        }
        if(props.direction.toLowerCase() === "left") {
            ulLeft=props.offset;
        }
        if(props.direction.toLowerCase() === "right") {
            ulLeft=((uloffset+maxMenuItemWidth+reactiveMenuPad-(dropdownButtonWidth+(dropDownNubW*4)))*-1);
            liLeft=ulLeft;//0;
            ulLeft=0;
        }
    }
    if(props.color !== undefined && overrideColors) {
        dropDownNubColor = props.color;
        divButtonColor = props.color;
        ulColor = props.color;
        liColor = props.color;
        divColor = props.color;
        strokeColor = props.color;
        fillColor = props.color;
    }
    if(props.backgroundColor !== undefined && overrideColors) {
        dropDownNubBackgroundColor = "rgba(0,0,0,0)";//props.backgroundColor;
        divButtonBackgroundColor = "rgba(0,0,0,0)";//props.backgroundColor;
        ulBackgroundColor = "rgba(0,0,0,0)";//props.backgroundColor;
        liBackgroundColor = "rgba(0,0,0,0)";//props.backgroundColor;
        divBackgroundColor = "rgba(255,255,255,.69)";//;
        boxColor = props.backgroundColor;
    }
    //appFunctions.logLocalHostOnly("about to render "+dropdownOpen+" "+dropdownClicks);
    return (
        <div key="divForDropdown"
            style={{position: 'relative', zIndex:'40', 
                    display: 'flex', top: '0px',
                    position: 'relative',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between:',
                    color:dropDownNubColor, backgroundColor: dropDownNubBackgroundColor,  
                    width:dropDownNubWidth, border: '0px',
                    padding: '0px', margin: '0px'
            }}
        >
            <div key="divForDropdownButton"
                id = "divButton"
                style={{position: 'relative', zIndex:'40', display: 'inline-block',
                    color:divButtonColor, backgroundColor: divButtonBackgroundColor, 
                    width:divButtonWidth,  padding: '0px', margin: '0px',
                    flexGrow: '1'
                    //,
                    //top:'50%',left:'50%',
                    //translateX:'-50%',
                    //translateY:'-50%'
                }}
            > 
                {isIconImage() &&
                    <img id="imgsvg" src={props.iconImage} 
                        alt="menu" width={divButtonWidth}
                        onMouseDown={mouseDownHandler}
                        onClick={clickedDropdownMenu}
                        onMouseOver={mouseOverDropdownButton}
                        onMouseOut={mouseOutDropdownButton}
                     /*   onTouchStart={mouseDownHandler}
                        onTouchMove={mouseOverDropdownButton}
                        onTouchEnd={mouseOutDropdownButton}*/
                    />
                }
                {!(isIconImage()) &&
                    <img id="imgsvg" src="images/assets/parallaxBurgerLogo.svg" 
                        alt="menu" width={divButtonWidth}
                        onMouseDown={mouseDownHandler}
                        onClick={clickedDropdownMenu}
                        onMouseOver={mouseOverDropdownButton}
                        onMouseOut={mouseOutDropdownButton}
                     /*   onTouchStart={mouseDownHandler}
                        onTouchMove={mouseOverDropdownButton}
                        onTouchEnd={mouseOutDropdownButton}*/
                    /> 
                }
            </div>
            {dropdownOpen 
            ?   (
                    <div key="divULdropdownMenu"
                        id = "divULdropdownMenu"
                        style={{position: 'relative', zIndex:'40', 
                            color:divColor, backgroundColor: divBackgroundColor, 
                            width:divWidth, 
                            padding: '0px', margin: '0px',
                            display: 'inline-block',
                            borderRadius: appDefaults.divGroupBorderRadius,
                            borderStyle: appDefaults.borderStyle,
                            borderWidth: appDefaults.borderWidth,
                            borderTop: appDefaults.borderColorTL,
                            borderLeft: appDefaults.borderColorTL,
                            borderBottom: appDefaults.borderColorBR,
                            borderRight: appDefaults.borderColorBR,
                            textAlign: 'center',
                            flexGrow: '1'
                        }} 
                    > 
                    <ul key="ulForDropdown"
                        style={{position: 'relative', zIndex:'40', margin: '0', top: '0',
                        padding:'0px', left:makeNumberPixels(ulLeft), color:ulColor, 
                        backgroundColor: ulBackgroundColor, width:ulWidth, height: '1'}}>
                        {menuItems.map((menuItem, menuItemIndex) =>
                            {
                                let b=0;
                                let menuItemValue = props.menuMap.menuItems[menuItem];
                                let altText = menuItem+" returns: "+menuItemValue;
                                if(props.showcase !== undefined) {
                                    altText = (props.showcase.showcaseLinks[menuItemIndex]).alt
                                }
                                //appFunctions.logLocalHostOnly("add item to menu "+menuItemValue);
                                return (
                                    <li key={"liForDropdownItem"+menuItem}
                                        title={menuItem+" returns: "+menuItemValue}
                                            style={{ position: 'relative', zIndex:'40', display: 'block',
                                            margin: '0', top: '0', padding:'1px', left:makeNumberPixels(liLeft), 
                                            color:liColor, backgroundColor: liBackgroundColor, 
                                            width:liWidth, height: '1', textDecoration: 'none', borderBottom: 'none'}}>
                                        <StyledButtonModFunction  
                                            key={"dropdownmenuitem"+menuItemIndex+menuItem}
                                            keyId={"dropdownmenuitem"+menuItemIndex+menuItem}
                                            title={menuItem+" returns: "+menuItemValue}
                                            alt={altText}
                                            selectionHandler={selectedMenuItem}
                                            overAction={overAction}
                                            outAction={outAction}
                                            width={liButtonWidth}
                                            text={menuItem}
                                            align="left"
                                        />
                                    </li> 
                                )
                            }
                        )}
                        
                    </ul>
                    </div>
                )
            :   (
                null
                )
            }
        </div>
    ); 

    /**
    * Selection handler passed to the styled buttons of the menu, which
    * returns the buttons label when clicked, the itemSelected.  
    *
    * Optionally run the selectionHandler property passed to dropdown menu.  
    * 
    * The dropdown is closed and the document event is removed.  
    * 
    * @function selectedMenuItem
    *
    * @param {String} itemSelected for select action. 
    */
    function selectedMenuItem(itemSelected) {
       // appFunctions.logLocalHostOnly("click on menuItem "+dropdownOpen+" "+itemSelected);
        setDropdownOpen(false);
        if(props.selectionHandler !== undefined) {
            if(props.showcase !== undefined) {
                setTimeout(
                        () =>
                        {props.selectionHandler(itemSelected, props.showcase.index)},
                        listenerTimerDelay
                    );
            } else {
                setTimeout(
                        () =>
                        {props.selectionHandler(itemSelected)},
                        listenerTimerDelay
                    );
            }
        }
    }

    /**
    * Over action sets over now and runs overAction property if it exists.   
    * 
    * @function overAction
    *
    * @param {Object} over event. 
    * @param {String} itemSelected for select action. 
    */
    function overAction(event, itemSelected) {
       appFunctions.logLocalHostOnly("overAction on menuItem do"+dropdownOpen+" "+itemSelected);
        setButtonOverNow(true);
        if(props.overAction !== undefined) {
            if(props.showcase !== undefined) {
                setTimeout(
                        () =>
                        {props.overAction(event, itemSelected, props.showcase.index)},
                        listenerTimerDelay
                    );
            } else {
                setTimeout(
                        () =>
                        {props.overAction(event, itemSelected)},
                        listenerTimerDelay
                    );
            }
        }
    }

    /**
    * Out action sets over now and runs outAction property if it exists.   
    * 
    * @function outAction
    *
    * @param {Object} over event. 
    * @param {String} itemSelected for select action. 
    */
    function outAction(event, itemSelected) {
        appFunctions.logLocalHostOnly("outAction on menuItem do="+dropdownOpen+" "+itemSelected);
        setButtonOverNow(false);
        if(props.outAction !== undefined) {
            if(props.showcase !== undefined) {
                setTimeout(
                        () =>
                        {props.outAction(event, itemSelected, props.showcase.index)},
                        listenerTimerDelay
                    );
            } else {
                setTimeout(
                        () =>
                        {props.outAction(event, itemSelected)},
                        listenerTimerDelay
                    );
            }
        }
    }

    /**
    * Prevent mouse down from allowing drag on button.  
    *
    * @function mouseDownHandler
    * 
    * @param {Object} event for mouse down action.
    */
    function mouseDownHandler(event) {
        event.preventDefault();
    }

    /**
    * Mouse is over dropdown button, the backgroundcolor is set to dropdownButtonActiveColor 
    * to indicate mouse click will select dropdown button.
    *
    * @function mouseOverDropdownButton
    * 
    * @param {Object} event for mouse over of dropdown button action.
    */
    function mouseOverDropdownButton(event) {
        event.preventDefault();
        event.target.style.background=dropdownButtonActiveColor;
        event.target.style.opacity = 0.6;
    }

    /**
    * Mouse is out of dropdown button, the backgroundcolor is set to dropdownButtonBackgroundColor 
    * to indicate mouse is not over dropdown button.  
    *
    * @function mouseOutDropdownButton
    * 
    * @param {Object} event  for mouse out of dropdown button action.
    */
    function mouseOutDropdownButton(event) {
        event.preventDefault();
        event.target.style.opacity = 1;
        event.target.style.background=dropdownButtonBackgroundColor;
    }

    /**
    * Mouse click on Dropdown menu button.  
    * 
    * Use state dropdownOpen and dropdownClicks with useEffect to
    * open/close menu.  
    * 
    * When the menu is displayed a button click will cancel the dropdown.  
    *
    * @function clickedDropdownMenu
    * 
    * @param {Object} event  for mouse click of dropdown button action.
    */
    function clickedDropdownMenu(event) {
        event.preventDefault();
        if(dropdownOpen) {
            event.target.style.background = dropdownButtonBackgroundColor;
            setDropdownOpen(false);
        } else {
            event.target.style.background = dropdownButtonActiveColor;
            setDropdownOpen(true);
        }
    }

    /**
    * Mouse click on document listener is started by the dropdown menu button,
    * after the dropdown menu is opened. A click any where except a menu choice 
    * will cancel the open dropdown menu.  
    * 
    * When dropdownOpen is true, set to false and remove document click listener,
    * otherwise no action.  
    * 
    * When the dropdown menu is displayed a document click will cancel the dropdown menu.  
    *
    * @function cancelDropdownMenu
    * 
    * @param {Object} event for click on document action. i.e. outside menu
    */
    function cancelDropdownMenu(event) {
        event.preventDefault();
       //appFunctions.logLocalHostOnly("click outside menu begin  "+dropdownOpen);
        if(dropdownOpen) {
            setDropdownOpen(false);
           //appFunctions.logLocalHostOnly("click outside menu turned off "+dropdownOpen);
            //setDropdownClicks(dropdownClicks + 1);
            setTimeout(() =>
                            {document.removeEventListener('click', cancelDropdownMenu)},
                            listenerTimerDelay
                        );
        }
       //appFunctions.logLocalHostOnly("click outside menu end "+dropdownOpen);
    }

    /**
    * Determine if iconImage property is set.  
    *
    * @function isIconImage
    * 
    * @return {Boolean} True when props.iconImage is defined, otherwise false.
    */
    function isIconImage() {
        return props.iconImage !== undefined;
    }

    /**
    * Get hamburger svg, which is simply three horizonal lines, evenly sized and spaced.  
    *
    * @function getHamburgerSVG
    * 
    * @param {*} iconWidth 
    * @param {*} iconHeight 
    * @param {*} x 
    * @param {*} y 
    * @param {*} y1 
    * @param {*} y2 
    * @param {*} w 
    * @param {*} h 
    * @param {*} fillColor 
    * @param {*} strokeColor 
    * @param {*} strokeWidth 
    * @param {*} fillOpacity 
    * @param {*} strokeOpacity 
    *
    * @return {svg} of three line hamberger icon.
    */
    function getHamburgerSVG(iconWidth,iconHeight,x,y,y1,y2,w,h,fillColor,strokeColor,strokeWidth,fillOpacity,strokeOpacity){
    return <svg width={iconWidth} height={iconHeight}>
                <rect x={x} y={y} width={w} height={h} 
                    style={{fill:{fillColor},stroke:{strokeColor},strokeWidth:{strokeWidth},fillOpacity:{fillOpacity},strokeOpacity:{strokeOpacity}}} />
                <rect x={x} y={y1} width={w} height={h} 
                    style={{fill:{fillColor},stroke:{strokeColor},strokeWidth:{strokeWidth},fillOpacity:{fillOpacity},strokeOpacity:{strokeOpacity}}} />
                <rect x={x} y={y2} width={w} height={h} 
                    style={{fill:{fillColor},stroke:{strokeColor},strokeWidth:{strokeWidth},fillOpacity:{fillOpacity},strokeOpacity:{strokeOpacity}}} />
            
                Sorry, your browser does not support inline SVG.  
            </svg>
    }
}