/** 
 * @fileOverview A styled button with mouse over highlighing, selectable
 * and activatable with support for tracking clicks. 
 *
 * @module StyledButtonModFunction
 *
 * @author Philip Gottfried
 */
import React, { Component} from 'react';
import styled, { css } from 'styled-components';
 
import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
import * as appTracking from "../modules/AppTracking";

/**
* Instance of styled button for StyledButtonModFunction with appDefaults set for border 
* to give an embossed look.  
*/
const StyledButtonBaseInstance = styled.button.attrs((props) => ({
        key:"styledbutton"+props.keyId+props.text,
        title:props.title || "",
        alt:props.alt || "",
        active:false,
        type:props.type || 'button'
})) ` 
        position: 'relative';
        z-Index:0;
        border-radius: 15px;
        border-top:thick ridge rgba(150,150,150,1);;
        border-left:thick ridge rgba(150,150,150,1);
        border-right:thick ridge rgba(75,75,75,1);
        border-bottom:thick ridge rgba(75,75,75,1);
        width: ${props => props.width || ""};
        color: ${props => props.color || appDefaults.controlColor};
        opacity: 1;
        background-color: ${props => props.backgroundColor || appDefaults.controlBackgroundColor};
        font-size: ${props => (appFunctions.isMobileDevice() ? '6vw' : '3vw')};
        text-align: ${props => props.align || 'center'};
        margin: 0px 0px;
        padding-top: 5px;
        padding-left: ${props => props.padLeft || '5px'};
        padding-bottom: 5px;
        padding-right: ${props => props.padRight || '5px'};
        cursor: 'pointer'
`;

/**
* Implements button with embossed look, mouse over highlighing, selectable,
* activatable and stylable with support for tracking clicks. 
* 
* Uses:
* <ul>
* <li>A link button for a tag or a Link component.</li>
* <li>A menu item that returns the selected value.</li>
* <li>A group item that returns the selected value. i.e., a radio button.</li>
* <li>A navigation button that shows active for matching last node of url.</li>
* </ul>
*
*
* Note: Tracking is implemented using isTracked, tracker and appTrackers.trackPageView.  The
* value of isTracked defaults to appDefaults.isReactTracker.  When a tracker function is supplied
* isTracked is set to true, overriding the default.  The tracker function expects a click event.
* 
*
* @function StyledButtonModFunction
*
* @param {Object} props property object
* 
* @prop {String} type button or submit, default is button.
* @prop {String} text value for button.
* @prop {String} title value for button's tool tip.
* @prop {String} alt value for button' alternate description.
* @prop {String} image value for button.
* @prop {String} align value for text alignment. i.e. left, right or center.
* @prop {String} color of text.
* @prop {String} backgroundColor of buttton.
* @prop {String} activeColor of mouse over.
* @prop {String} keyId for key uniqueness.
* @prop {String} formId identifies associated form.
* @prop {Function} selectionHandler on click pass button text to function when defined.
* @prop {Function} onClick pass click event to function when defined.
* @prop {Function} tracker pass click event to function when defined. defaults to appTrackers.trackButtonEvent
* @prop {Number} width for fixed width button, as in a menu.
* @prop {String} borderColorTL of top and left color for border.
* @prop {String} borderColorBR of bottom and right color for border.
* @prop {String} padLeft of button in pixels.
* @prop {String} padright of button in pixels.
* @prop {String} borderWidth of button in pixels.
* @prop {String} borderStyle of button. i.e. none, solid, ridge ...
* @prop {String} toggle button type of no, yes or route. default is no.
* @prop {Boolean} isTracked indicate clicks are to be tracked. defaults to appDefaults.isReactTracker.
* 
*
* @return {StyledButtonBaseInstance} with styles set from defaults or overridden by props.  
*/
export default function StyledButtonModFunction(props) {

  let activeToggleName = "";

  let formName = "";
  if(props.formId !== undefined) {
    formName = props.formId;
  }
  let styledButtonType = "button";
  if(props.type !== undefined) {
    styledButtonType = props.type;
  }

  let styledButtonAlign = "center";
  if(props.align !== undefined) {
    styledButtonAlign = props.align;
  }

  let styledButtonOpacity=1;
  let styledButtonActiveOpacity=1;
    
  let styledButtonFont = "3vw";
  if(appFunctions.isMobileDevice()) {
    styledButtonFont = "6vw";
  }
  let styledButtonColor=appDefaults.controlColor;
  let styledButtonBackgroundColor=appDefaults.controlBackgroundColor;
  let styledButtonActiveColor=appDefaults.controlActiveColor;
  let styledButtonSelectedColor=appDefaults.controlSelectedColor;
  if(props.color !== undefined) {
    styledButtonColor=props.color;
  }
  if(props.backgroundColor !== undefined) {
    styledButtonBackgroundColor=props.backgroundColor;
  }
  if(props.activeColor !== undefined) {
    styledButtonActiveColor=props.activeColor;
  }

  let styledButtonToggle = "no";
  if(props.toggle !== undefined) {
    styledButtonToggle = props.toggle;
  }

  let styledButtonBorderWidth = appDefaults.borderWidth;
  if(props.borderWidth !== undefined) {
    styledButtonBorderWidth = props.borderWidth+"px";
  }
  let styledButtonBorderStyle = appDefaults.borderStyle;
  if(props.borderStyle !== undefined) {
    styledButtonBorderStyle = props.borderStyle;
  }
  let styledButtonPadLeft = "5px";
  if(props.padLeft !== undefined) {
    styledButtonPadLeft = props.padLeft+"px";
  }
  let styledButtonPadRight = styledButtonPadLeft;
  if(props.padRight !== undefined) {
    styledButtonPadRight = props.padRight+"px";
  }
  let styledButtonBorderTopColor=appDefaults.borderColorTL;
  let styledButtonBorderBottomColor=appDefaults.borderColorBR;
  let styledButtonBorderLeftColor=styledButtonBorderTopColor;
  let styledButtonBorderRightColor=styledButtonBorderBottomColor;
  if(props.borderColorTL !== undefined) {
    styledButtonBorderTopColor=props.borderColorTL;
    styledButtonBorderLeftColor=props.borderColorTL;
  }
  if(props.borderColorBR !== undefined) {
    styledButtonBorderBottomColor=props.borderColorBR;
    styledButtonBorderRightColor=props.borderColorBR;
  }
  let isTracked = appDefaults.isReactTracker;
  if(props.tracker !== undefined) {
    isTracked = true;
  }

  setActiveToggleName()
  let curl = window.location.href;
  let hrefs = curl.split("/");
  let href = hrefs[hrefs.length-1];


  let bw = styledButtonBorderWidth;
  let bs = styledButtonBorderStyle;

  let tc = styledButtonBorderTopColor;
  let lc = styledButtonBorderLeftColor;
  let bc = styledButtonBorderBottomColor;
  let rc = styledButtonBorderRightColor;

  if(isToggleRouteActive()) {
    tc = styledButtonBorderBottomColor;
    lc = styledButtonBorderRightColor;
    bc = styledButtonBorderTopColor;
    rc = styledButtonBorderLeftColor;
  }

  let toolTip = "";
  if(props.title !== undefined) {
    toolTip = props.title;
  }

  let style = {
              color: styledButtonColor,
              fontSize: styledButtonFont,
              textAlign: styledButtonAlign,
              opacity: styledButtonOpacity,
              backgroundColor: styledButtonBackgroundColor,
              paddingTop: '5px',
              paddingLeft: styledButtonPadLeft,
              paddingBottom: '5px',
              paddingRight: styledButtonPadRight,
              cursor: 'pointer',
              borderTop: styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+tc,
              borderBottom: styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+bc,
              borderLeft: styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+lc,
              borderRight: styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+rc,
            }
  if(props.width !== undefined) {
    style.width=props.width;
  }
  style.touchAction = 'manipulation';
  let xti = props.text;
  if(props.image !== undefined) {
    xti = getImage(props.text, props.image);
  }
  let t=0;
  return (
    <StyledButtonBaseInstance
        title={toolTip}
        onMouseDown={mouseDownStyledButton}
        onMouseOver={mouseOverStyledButton}
        onMouseOut={mouseOutStyledButton}
       // onTouchStart={mouseDownStyledButton}
        //onTouchMove={mouseOverStyledButton}
        //onTouchEnd={mouseOutStyledButton}
        onClick={(event) => {
          if(isTracked) {
            if(props.tracker !== undefined) {
              props.tracker(event);
            } else {
              appTracking.trackButtonEvent(event);
            }
          }
          if(props.onClick !== undefined) {
            props.onClick(event);
            return;
          }
          if(props.selectionHandler !== undefined) {
            props.selectionHandler(props.text);
            return;
          }
          if(isToggle()) {
            activeToggleName = props.text
          }
        }}
    >
        {xti}
    </StyledButtonBaseInstance>
  )

/**
* Get img tag for route name and image.  
*
* @function getImage
*
* @param {String} name of route for link id and tool tips.
* @param {String} image source file name.
*
* @reurn {Object} img tag with no border, no pad and width 90%
*/
function getImage(name, image) {
   return (<img id={"imgbutton"+name} src={image}
                alt={"Link to "+name} 
                title={"Link to "+name} 
                width='90%'
                style={{borderStyle:'none', padding:'0px'}}
            /> );
}
  /**
   * Mouse is over toggle button, set background to styledButtonActiveColor.  
   *
   * @function mouseOverStyledButton
   * 
   * @param {Object} event for mouse over.
   */
  function mouseOverStyledButton(event) {
   //     if(!appFunctions.isMobileDevice() && event.type !== 'touchdown') event.preventDefault();
//    event.preventDefault();
    if(isToggleRouteActive()) {
      event.target.style.background = styledButtonSelectedColor;
      event.target.style.opacity = styledButtonOpacity;
      event.target.style.borderTop=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderTopColor;
      event.target.style.borderLeft=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderLeftColor;
      event.target.style.borderBottom=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderBottomColor;
      event.target.style.borderRight=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderRightColor;
      
    } else {
      event.target.style.background = styledButtonActiveColor;
      event.target.style.opacity = styledButtonActiveOpacity;
      appFunctions.logLocalHostOnly("mouse over styledButton "+event.currentTarget.textContent.toLowerCase());  
      event.target.style.borderTop=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderBottomColor;
      event.target.style.borderLeft=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderRightColor;
      event.target.style.borderBottom=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderTopColor;
      event.target.style.borderRight=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderLeftColor;
    }
    if(props.overAction !== undefined) {
        props.overAction(event, props.text);
    }
}

/**
  * Mouse is out of toggle button, set background to styledButtonActiveColor and 
  * when href matches the textContext otherwise use the styledButtonBackgroundColor.  
  * 
  * i.e. highlighted when over.  
  *
  * @function mouseOutStyledButton
  * 
  * @param {Object} event for mouse out.
  */
 function mouseOutStyledButton(event) {
 //       if(!appFunctions.isMobileDevice() && event.type !== 'touchdown') event.preventDefault();
//event.preventDefault();
    let ref = appFunctions.getRouteFromLocationHref();
    if(!(event.target.textContent.toLowerCase() === ref.toLowerCase())) {
      appFunctions.logLocalHostOnly("ref="+ref+" t="+event.target.textContent.toLowerCase());
      event.target.style.background = styledButtonBackgroundColor;
      event.target.style.opacity = styledButtonOpacity;
      event.target.style.borderTop=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderTopColor;
      event.target.style.borderLeft=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderLeftColor;
      event.target.style.borderBottom=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderBottomColor;
      event.target.style.borderRight=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderRightColor;
    } else {
        appFunctions.logLocalHostOnly("ref="+ref+" t="+event.target.textContent.toLowerCase());
        if(isToggleRouteActive()) {
          event.target.style.background = styledButtonSelectedColor;
        } else {
          event.target.style.background = styledButtonActiveColor;
        }
        event.target.style.opacity = styledButtonActiveOpacity;
        event.target.style.borderTop=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderBottomColor;
        event.target.style.borderLeft=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderRightColor;
        event.target.style.borderBottom=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderTopColor;
        event.target.style.borderRight=styledButtonBorderWidth+" "+styledButtonBorderStyle+" "+styledButtonBorderLeftColor;
    }
    if(props.outAction !== undefined) {
        props.outAction(event, props.text);
    }
    appFunctions.logLocalHostOnly("mouse out toggle button "+event.currentTarget.textContent.toLowerCase());   
  }

  /**
   * Prevent mouse down from allowing drag on button.
   *
   * @function mouseDownStyledButton
   * 
   * @param {Object} event for mouse down event.
   */
  function mouseDownStyledButton(event) {
    if(!appFunctions.isMobileDevice() && event.type !== 'touchdown') event.preventDefault();
    //event.preventDefault();
  }

  /**
   * Set the active toggle name, when toggle property is route, set the activeToggleName and 
   * when the text property matches the current route.  i.e., the last node of href.  
   * 
   * So the button shows active selection on first render when
   * the button matchs the end of the url.  
   *
   * @function setActiveToggleName
   *
   */
  function setActiveToggleName() { 
    let curl = window.location.href;
    let refs = curl.split("/");
    let ref = refs[refs.length-1];
    //appFunctions.logLocalHostOnly("did mount styled button "+props.text.toLowerCase()+" ref="+ref.toLowerCase()); 
    if(isToggleRoute && props.text.toLowerCase() === ref.toLowerCase()) {
      //appFunctions.logLocalHostOnly("set styled activeToggleName "+ref); 
      //setState({ activeToggleName: ref });
      activeToggleName = ref;
    } 
  }

  /**
   * Determine if the toggle property is not no. i.e. button toggles.  
   *
   * All buttons that do not have a click or selection handler will display as a toggle.  
   *
   * @function styledButtonToggle
   *
   * @return {Boolean} True when styledButtonToggle is not no, otherwise false.
   */
  function isToggle() {
    return styledButtonToggle.toLowerCase() !== "no";
  }

  /**
   * Determine if the toggle property is for a route.  i.e. is active when text property 
   * matches the last node of the url.  
   *
   * @function isToggleRoute
   *
   * @return {Boolean} True when styledButtonToggle not route, otherwise false.
   */
  function isToggleRoute() {
    return styledButtonToggle.toLowerCase() === "route";
  }

  /**
   * Determine if a toggle route button has a activeToggleName that matches the text property.  i.e. the text property
   * matches the last node of the url.  
   *
   * @function isToggleRouteActive
   *
   * @return {Boolean} True when activeToggleName matches text property, otherwise false.
   */
  function isToggleRouteActive() {
    let activeToggle = false;
    if(isToggleRoute()) {
      activeToggle=activeToggleName.toLowerCase() === props.text.toLowerCase();
    }
    return activeToggle;
  }

}
