/** 
 * @fileOverview Display StyledButtons for submit, preview and/or cancel.  
 * 
 * @module FormMenuFunction
 *
 * @author  Philip Gottfried 
 *
 */
import React, {useState} from "react";
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router-dom";

import * as appDefaults from "../modules/AppDefaults";
import * as appFunctions from "../modules/AppFunctions";
import StyledButtonModFunction from "./StyledButtonModFunction";
import InputTextCompFunction from "./InputTextCompFunction";

/**
* Implements display of a set of StyledButtons named submit, preview and/or cancel 
* with event handlers from the form property.  
*
* Displays SyledButtons as a horizontal menu for use on an input form.  
*
* Submit button on all forms.  
* Preview button when not a contact form.  
* Reload button when a preview is displayed.  
* Cancel button to close form and display previous route.  
*
* Events for the submit and preview buttons pass with props.  
*
*
* @function FormMenuFunction
*
* @param {Object} props property object
* 
* @prop {JSON} menuParms allows access to the form's button handlers.
*
* @return {Object}  h2 for form title and div with submit,cancel,preview and reset buttons.
*/
export default function FormMenuFunction(props)  {
    const navigate = useNavigate();
      
    let timestamper = new Date().getTime();
    let formMenuAlign = 'center';
    let headerFontR="4vw";
    if(appFunctions.isMobileDevice())  {
        headerFontR="8vw";
    }

    let color = appDefaults.divGroupBackground;
    if(props.formInvalid || props.invalidFields.length>0)  {
        color = appDefaults.controlErrorColor;
    }
    return (
    <>
        {!props.isContactForm() &&
            <h2 className="text3d" key="title1form">{props.formTitle} {props.dataStatus}</h2>
        }
        {props.isContactForm() &&
            <h2 className="text3d" key="title2form">{props.formTitle}</h2>
        }
        <div key="menuoptionalform" style={{ display: 'inline-block'}}>
            <div key="submitform" style={{ display: 'inline-block'}}>
                <StyledButtonModFunction key={"ecsubmit"} type="submit" text="Submit" 
                formId={props.formName}
                title={"Submit "+props.formTitle}
                onClick={(event) => {
                    props.handleSubmit(event);
                }}/>
            </div>
            {props.isDataStatus("Edited") && !props.isContactForm() &&
                <StyledButtonModFunction key={"ecreset"} type="button" text="Reset" 
                    title={"Reset "+props.formTitle}
                    formId={props.formName}
                    onClick={() => {
                        props.formWasReset(props.formName, 'Reset', "Reset Form", JSON.stringify(props.jsonFormData));
                    }}
                />
            }
            {props.isDataStatus("Preview") &&
                <a key={"ecreload"} href={window.location.href} target={"_self"} rel='noopener' >
                    <StyledButtonModFunction key={"ecreload"} type="button" text="Reload" 
                        formId={props.formName}
                        title={"Reload "+props.formTitle}
                    />
                </a>
            }
            {!props.isContactForm() &&
                    <StyledButtonModFunction key={"ecpreview"} type="button" text="Preview" 
                        formId={props.formName}
                        title={"Preview "+props.formTitle}
                        onClick={props.previewClick}
                    />
            }
            {props.isContactForm() &&
                    <StyledButtonModFunction key={"eccancel"} type="button" text="Cancel" 
                        formId={props.formName}
                        title={"Cancel "+props.formTitle}
                        onClick={() => {
                            navigate(-1);
                        }}
                    />
            }
        </div>
    </>
    );
}