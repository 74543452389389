/**
* @fileOverview Application default values.  
*
* @module AppDefaults
*/

/**
* Debug flag to use for logging and testing.
*/
export const debugMode = true;
/**
* Debug tag is a comma delitimited list of string values to
* use as tags for debugging and testing.
*/
export const debugTags = ",All,";
/**
* URL that app will be deployed to.  
*/
export const deployTarget = "https://parallaxpc.com";
/**
* Root folder for json data files.
*/
export const jsonLocation = "/json/";//"https://parallaxpc.com/json/";//deployTarget+"/json/";//"./json/";

/**
* Background color for DivGrp.  
*/
export const divGroupBackground = "rgba(255,255,255,.9)";
/**
* Border radius for DivGrp.
*/
export const divGroupBorderRadius = "10px";

export const isUseLogIn = false;
export const isSliderOn = false;
export const isRemoteJSON = true;
export const isSchemaFields = true;
export const isReactTracker = false;//true;
export const isUseRadar = false;

/**
* Border radius default.
*/
export const borderRadius = "15px";
/**
* Border width default.
*/
export const borderWidth = "thick";
/**
* Border style default.  i.e. ridge combines with a top/left border that is lighter than the bottom/right for an embossed look.
*/
export const borderStyle = "ridge";
/**
* Border top and left color default.
*/
export const borderColorTL = "rgba(150,150,150,1)";
/**
* Border bottom and right color default.
*/
export const borderColorBR = "rgba(75,75,75,1)";

/**
* Nav menu button border top and left color default.
*/
export const navBorderColorTL = "aqua";//"rgba(150,150,150,1)";
/**
* Nav menu button border bottom and right color default.
*/
export const navBorderColorBR = "blue";//"rgba(75,75,75,1)";
export const navColor = "white";
export const navBackgroundColor = 'blue';
export const navActiveColor = "cyan";
export const navSelectedColor = "azure";
export const navIconButtonAspectRation = 6/2;

export const controlColor = "white";
export const controlErrorColor = "red";
export const controlErrorColorTL = "red";
export const controlErrorColorBR = "hotpink";
export const controlBackgroundColor = 'black';
export const controlActiveColor = "rgba(120,120,120,1)";
export const controlSelectedColor = "rgba(192,192,192,1)";

export const inputColor = 'black';
export const inputBackgroundColor = "white";
export const inputActiveColor = "white";
export const inputActiveColorControlled = "lightgray";
export const inputFocusColor = "aliceblue";

export const inputSelectedColorTL = "lightblue";
export const inputSelectedColorBR = "blue";

export const inputFontName = "courier new";
export const projectViewTextFontName = "Times New Roman";

export const loadWaitSecondsDelay =  ".1";
export const loadWaitReleaseStep = "1";//udivse a value that will divide delay