/** 
 * @fileOverview Display navigator for routes in a slider with previous, 
 * current and next icon buttons for portfolios with automatic changes, 
 * next/previous buttons, and swipe left/right.  
 * 
 * @module RouteIconSliderFunction
 *
 * @author Philip Gottfried
 */
import React, { useState} from 'react';
import { Link, useNavigate} from 'react-router-dom';

import * as appFunctions from "../modules/AppFunctions";
import * as appDefaults from "../modules/AppDefaults";
import * as appFontSizes from "../modules/AppFontSizes";
import * as appTracking from "../modules/AppTracking";

import ProjectViewFunction from "./ProjectViewFunction";
import StyledButtonModFunction from "./StyledButtonModFunction";
import DivGroupFunction from "./DivGroupFunction";
import IconButtonFunction from "./IconButtonFunction";

/**
  * Implements navigator for routes with icon buttons, drag/touch and auto advance
  * with optional child property enclosed with drag/touch support.  
  *
  * Auto advance of routes is only on if the property is activated from the parent,
  * as a route change ends this components current instance.  
  * 
  * One behavior is to initially auto advance until a swipe, click or scroll.  Not 
  * the best choice as google may start to crawl page and get meta data that does not match the
  * page displayed.  A longer initial delay could help.
  * 
  * Route navigation is done with useNavigate, so there are no states changes.
  * All actions result in a route change.  Inorder to support the auto advance feature
  * working on first load and not on a subsequent call requires the parent to begin to
  * load the new route with auto advance property of false. 
  *
  * The optional function property cancelAdvance is run for each timer cancel.  
  * This function must set the parent's autoAdvance value so subsequent calls 
  * do not autoAdvance.  
  *
  * The drag/touch swipe handling uses verticalSensitivity and horizontalSensitivity
  * values to ingore swipes that exceed the verticalSensitivity while only 
  * swiping when the horizontalSensitivity is exceeded.
  *
  * Note: The mouse and touch event families are used and implement prevent default.  
  * Event listeners are added to elements that require a touchAction style of manipulation, indicating
  * touch and mouse events are used.  Ensures systems with both will not interfere with zoom and pinch.
  * Event mouseUp does not have preventDefault to allow buttons on child components to click.
  *
  * @function RouteIconSliderFunction
  *
  * @param {Object} props property object
  * 
  * @prop {object} projectData json object of project data
  * @prop {Number} beginIndex for route of project. i.e. the middle or current icon button.
  * @prop {Number} delay for automatic next image in milliseconds, defaults to 5000.
  * @prop {Boolean} autoAdvance of true activates timer to advance route, defaults to false.
  * @prop {Function} cancelAdvance allows autoAdvance cancel request to parent, so autoAdance is set to false.
  *
  * @return {Object}  div with DivGroupFunction of three icon buttons and an optional div with children
  *                    that supports swipe/drag and click navigation of routes.
  */

export default function RouteIconSliderFunction(props) {
  const navigate = useNavigate();
  let projectIndex = 0;
  if(props.beginIndex !== undefined) {
    projectIndex = props.beginIndex;
  }
  let autoAdvance = false;
  if(props.autoAdvance !== undefined) {
    autoAdvance = props.autoAdvance
  }
 // autoAdvance = false;//override if you want always off
  let timeoutID = {};
  let stopped = false;
  let isWaiting = false;
  let delay = 5*1000;
  if(props.delay !== undefined) {
    delay = props.delay;
  }
  let autoAdvanceTimer = () => setTimeout(() => 
  { // return the timeoutID
    isWaiting = false;
    //appFunctions.logLocalHostOnly("in timer auto"+autoAdvance);
    if(autoAdvance) {
      displayNextRoute();
    }
  }, delay);
  let portfolios=props.projectData;//just for shorter name
  
  let isDown = false;
  let scaleOnDown = 0;
  let hasMovedH = false;
  let hasMovedV = false;
  let difH = 0;
  let difV = 0;
  let moveDirectionH = '';
  let moveDirectionV = '';
  let horizontalSensitivity = 10;//must swipe horizontal at least this many pixels.
  let verticalSensitivity = 5;//vertical change of swipe horizontal can not be more than this many pixels.
  if(appFunctions.isMobileDevice()) {
    verticalSensitivity = 35;
    horizontalSensitivity = 5;
  }
  let downStartX = 0;
  let downStartY = 0;

  let downStartTouches = [];

 if(autoAdvance) {
    window.addEventListener("scroll",windowScrolled);
  }
  if(!isWaiting && autoAdvance) {
    startAutoNext();
  } 
  let prevIndex = projectIndex - 1;
  if(prevIndex < 0) {
    prevIndex = portfolios.length-1;
  }
  let nextIndex = projectIndex + 1;
  if(nextIndex >= portfolios.length) {
    nextIndex = 0;
  }
  let centerWidth = .85;
  if(appFunctions.isMobileDevice()) {
    centerWidth = .80;
  }
  let scrollWidth =  window.innerWidth - document.documentElement.clientWidth;
  let windowWidth= window.innerWidth-scrollWidth;
  //let minWidth=300;
  let cols = 3;
  let menuWidth= windowWidth;//parseInt(width * .99);
  let buttonWidth = parseInt((windowWidth*centerWidth) / cols);
  let buttonHeight = parseInt(buttonWidth / appDefaults.navIconButtonAspectRation);
  let menuHeight = buttonHeight + 14;

  let ltw = parseInt(menuWidth *.05).toString()+'px';
  let ctw = parseInt(menuWidth *.90).toString()+'px';
  let rtw = parseInt(menuWidth *.05).toString()+'px';
  if(appFunctions.isMobileDevice()) {
    ltw = parseInt(menuWidth *.01).toString()+'px';
    ctw = parseInt(menuWidth *.98).toString()+'px';
    rtw = parseInt(menuWidth *.01).toString()+'px';
  }
  let divColStyle = { display: 'grid',
                gridTemplateColumns: 'repeat('+cols+', 1fr)',
                gridAutoRows: 'auto',
                columnGap: '0px',
                rowGap: '0px'
            }
  let hasChildren = props.children !== undefined;
  let isTouch = appFunctions.isMobileDevice();
  let isDebug = false;
  return ( 
<div key='divNav' style={{zIndex:'0'}}>
      <div key='divSliderNav'

      >
        <DivGroupFunction key="divgrpnav"
            style={{touchAction:'manipulation',height:appFontSizes.makeNumberPixels(menuHeight)}}//,width:appFontSizes.makeNumberPixels(menuWidth)}}
        >
         <div key="divnavswipe"
            style={{touchAction:'manipulation',height:appFontSizes.makeNumberPixels(menuHeight)}}//,width:appFontSizes.makeNumberPixels(menuWidth)}}
            onMouseDown={mouseDown}
            onMouseMove={mouseMove}
            onMouseUp={mouseUp}
            onTouchStart={mouseDown}
            onTouchMove={mouseMove}
            onTouchEnd={mouseUp}
        >
         <IconButtonFunction
            title={"Next route "+portfolios[nextIndex].projectTitle}
            key={"next "+portfolios[nextIndex].projectTitle}
            keyId="next"
            width={appFontSizes.makeNumberPixels(buttonWidth)}
            height={appFontSizes.makeNumberPixels(buttonHeight)}
            text={portfolios[nextIndex].projectTitle}
            image={portfolios[nextIndex].image}
            toggle="drag"
            isTracked={false}
          />
          <img
              key={"current "+portfolios[projectIndex].projectTitle}
              id="current"
              name="current"
              alt="current place holder"
              width={appFontSizes.makeNumberPixels(buttonWidth)}
              height={appFontSizes.makeNumberPixels(buttonHeight)}
              src="images/assets/blankLogoImage.png"
          />
          <IconButtonFunction
            title={"Previous route "+portfolios[prevIndex].projectTitle}
            key={"previous "+portfolios[prevIndex].projectTitle}
            text={portfolios[prevIndex].projectTitle}
            keyId="previous"
            width={appFontSizes.makeNumberPixels(buttonWidth)}
            height={appFontSizes.makeNumberPixels(buttonHeight)}
            image={portfolios[prevIndex].image}
            toggle="drag"
            isTracked={false}
          />
          </div>
        </DivGroupFunction>
        </div>
      {hasChildren &&
        <div key='routeDisplayM'
              style={{touchAction:'manipulation'}}
              onMouseDown={mouseDown}
              onMouseMove={mouseMove}
              onMouseUp={mouseUp}
              onTouchStart={mouseDown}
              onTouchMove={mouseMove}
              onTouchEnd={mouseUp}
          >
            {props.children}
        </div>
      }
   </div>
  );
  /**
  * Is stopped indicates stopped boolean for conditional render.
  *
  * @function isStopped
  *
  * @return {Boolean} value of stopped.
  */
  function isStopped() {
    return stopped;
  }
  /**
   * When mouseDown/touchStart, sets isDown to true, and 
   * downStart, moveDirection and hasMhoved for horizontal and vertical.  
   * 
   * Any mouse down event will cancel autoAdvance timer if it is active, even when
   * target is the optional children.  
   *
   * Same applies for touch down.
   * 
   * @function mouseDown
   *
   * @param {*} e omuseDown or touchStart event
   */
  function mouseDown(e){
    if(!appFunctions.isMobileDevice() && e.type !== 'touchdown') e.preventDefault();
    isDown = true;
    scaleOnDown = window.visualViewport.scale;
    hasMovedH = false;
    hasMovedV = false;
    moveDirectionH = e.type;
    moveDirectionV = e.type;
    downStartX = 0;
    downStartY = 0;
    downStartTouches = [];
    if(e.type === 'mousedown') {
      downStartX = e.clientX;
      downStartY = e.clientY;
    }
    if(e.type === 'touchstart') {
      downStartX = e.touches[0].clientX;
      downStartY = e.touches[0].clientY;
      if(e.touches.length > 1) {
        downStartTouches = JSON.parse(JSON.stringify(e.touches));
        //appFunctions.logLocalHostOnly("md multi-touch="+e.touches.length+" "+e.type+" downStartX="+downStartX+" downStartY="+downStartY+" isDown="+isDown+"i="+projectIndex);
      }
    }
    if(autoAdvance) {
      cancelAutoAdvanceTimer();
    }
    //appFunctions.logLocalHostOnly("md "+e.type+" downStartX="+downStartX+" downStartY="+downStartY+" isDown="+isDown+"i="+projectIndex);
  }
 
  /**
   * When mouseUp/touchEnd handle drag/touch swipes and single clicks on next/previous
   * icon buttons.  
   * 
   * The move event tracks all moves, setting the direction of
   * movement horizontally and vertically when mouse/touch is down.  
   *
   * Based on the direction of movement, the distance moved is determined.
   * When the vertical movement is more than verticalSensitivity, the swipe 
   * is ignored and if the horizontal movement is at least horizontalSensitivity
   * the swipe is performed, making either the left or right route visible.  
   *
   * When there is no movement or the movement is within the sensitivity box
   * centered on the mouseDown/touchStart position, the swipe is treated as 
   * a possible click on next/previous buttons.  On mobile the box is bigger.
   *
   * Same applies for touch up.
   *
   * The preventDefault method is run on fuzzyMouseUpClick when next/previous
   * button to allow child component buttons to accept clicks.  
   *
   * Mamoto tracking for left and right drag/swipes and route. e.i. to see if swipes are used vs clicks.
   * 
   * @function mouseUp
   *
   * @param {*} e mouseUp or touchEnd event.
   */
  function mouseUp(e) {
    isDown = false;
    let scaleOnUp = window.visualViewport.scale;
    if(scaleOnUp != scaleOnDown) {
      //appFunctions.logLocalHostOnly("up scale changed u="+scaleOnUp+" d="+scaleOnDown);
      return;
    }
    hasMovedH = false;
    hasMovedV = false;
    let endX = e.clientX;
    let endY = e.clientY;
    let endX2 = e.clientX;
    let endY2 = e.clientY;
    let totalDifH = 0;
    let totalDifV = 0;
    if(e.type === 'touchend') {
      endX = e.changedTouches[0].clientX;
      endY = e.changedTouches[0].clientY;
      if(e.changedTouches.length > 1) {//ignore multi-tuoch events
        //appFunctions.logLocalHostOnly("up multi-touch="+e.changedTouches.length);
        if(downStartTouches.length === e.changedTouches.length) {//same fingers
          endX2= e.changedTouches[e.changedTouches].clientX;//get last touch
          endY2 = e.changedTouches[e.changedTouches].clientY;
        }
        let tdif1he=downStartTouches[0].clientX - endX;//1st start position - 2nd end position

        let tdif1ve=downStartTouches[0].clientY - endY;

        let touchDifEnd = Math.hypot(tdif1he,tdif1ve);//end distance apart 

        let tdif1hb=downStartTouches[0].clientX - downStartTouches[downStartTouches.length].clientX;//1st start position - 2nd start position

        let tdif1vb=downStartTouches[0].clientY - downStartTouches[downStartTouches.length].clientY;

        let touchDifStart = Math.hypot(tdif1hb,tdif1vb);//start distance apart 
        
        let multiTouchType = "multi:"+downStartTouches.length;
        if(touchDifStart < touchDifEnd) {//pinch
          multiTouchType = "pinch";
        }
        if(touchDifStart > touchDifEnd) {//expand
          multiTouchType = "expand";
        }
        //appFunctions.logLocalHostOnly("up multi-type="+multiTouchType);
        return;//no swipe when multi-touch
      }
    }
    if(moveDirectionH === 'left') {
      totalDifH = downStartX - endX;
    }
    if(moveDirectionH === 'right') {
      totalDifH = endX - downStartX; 
    }
    if(moveDirectionV === 'up') {
      totalDifV = downStartY - endY;
    }
    if(moveDirectionV === 'down') {
      totalDifV = endY - downStartY; 
    }
    //appFunctions.logLocalHostOnly("up moveDirectionH="+moveDirectionH+" moveDirectionV"+moveDirectionV);
    //appFunctions.logLocalHostOnly("up endX="+endX+" endY="+endY+' difH='+totalDifH+' difV='+totalDifV+" i="+projectIndex);
    //appFunctions.logLocalHostOnly("up u="+scaleOnUp+" d="+scaleOnDown);
    if(totalDifV <= verticalSensitivity) {//only swipe when it is too not horizontal
      if(totalDifH >= horizontalSensitivity) {//only swipe if move atleast this much
        if(!autoAdvance) {
          appTracking.trackRouteEvent(moveDirectionH,portfolios[projectIndex].name);
        }
        if(moveDirectionH ==='left') {
            displayPreviousRoute();
        }
        if(moveDirectionH === 'right') {
            displayNextRoute();
        }
      } else {
        fuzzyMouseUpClick(e);//when small move check if should be a click on next/previous buttons.
      }
    } else {
      //appFunctions.logLocalHostOnly("up scroll="+moveDirectionH+" moveDirectionV"+moveDirectionV);
      //appFunctions.logLocalHostOnly("up scroll="+endX+" endY="+endY+' difH='+totalDifH+' difV='+totalDifV+" i="+projectIndex);
    }
  }
 /**
   * Fuzzy mouse up click for mouseUp/touchEnd event.  
   *
   * When the event target name is next or previous and the
   * target id is not empty, use it as the route to push on the navigate.  
   *
   * Called when the mouseUp/touchEnd position is within the sensitivity box
   * centered on the mouseDown/touchStart position and the mouse/touch
   * is on the next or previous buttons.  
   *
   * The preventDefault method is run on touch end to prevent onClick 
   * events on the next and previous buttons.  
   *
   * Mamoto tracking for next and previous button and route. e.i. to see if clicks are used vs swipes.  
   *
   * @function fuzzyMouseUpClick
   *
   * @param {*} e 
   */
  function fuzzyMouseUpClick(e) {
    if(e.target.name === "next" && e.target.id !== '') {
       if(e.type === 'touchend') e.preventDefault();
       if(!autoAdvance) {
        appTracking.trackRouteEvent(e.target.name, e.target.id);
       }
      displayNextRoute();
    }
    if(e.target.name === "previous" && e.target.id !== '') {
       if(e.type === 'touchend') e.preventDefault();
      if(!autoAdvance) {
      appTracking.trackRouteEvent(e.target.name, e.target.id);
      }
      displayPreviousRoute();
    }
  }
  /**
   * When mouse moves handle move only when mouse is down.  
   *
   * The mouse down event tracks the startX and sets as down and not moved.  
   *
   * Each move event, the horizontal and vertical movement is determined.  
   * 
   * Same applies for touch moves.  
   *
   * @function mouseMove
   * 
   * @param {*} e
  */
  function mouseMove(e) {
    if(e.type !== 'touchmove') e.preventDefault();
    //appFunctions.logLocalHostOnly('move ', e.type+ " isDown="+isDown);
    if(isDown) {
      //appFunctions.logLocalHostOnly('move start ', e.type);
      let moveX = downStartX;
      let moveY = downStartY;
      difH = 0;
      difV = 0;
      if(e.type === 'mousemove') {
        moveX = e.clientX;
        moveY = e.clientY;
      }
      if(e.type === 'touchmove') {
        moveX = e.touches[0].clientX;
        moveY = e.touches[0].clientY;
        if(e.touches.length > 1) {
          //appFunctions.logLocalHostOnly("mv multi="+e.touches.length);
        }
      }
      if(moveX < downStartX) {
        moveDirectionH = 'left';
        hasMovedH = true;
        difH = downStartX - moveX;
      }
      if(moveX > downStartX) {
        moveDirectionH = 'right';
        hasMovedH = true;
        difH = moveX - downStartX ;
      }
      if(moveY < downStartY) {
        moveDirectionV = 'up';
        hasMovedV = true;
        difV = downStartY - moveY;
      }
      if(moveY > downStartY) {
        moveDirectionV = 'down';
        difV = moveY - downStartY;
        hasMovedV = true;
      }
      if(moveDirectionH === 'mousedown' || moveDirectionV === 'mousedown') {
        //appFunctions.logLocalHostOnly('move ', moveDirectionV);
      }
    } else {
      let moveXA = downStartX;
      let moveYA = downStartY;
      if(e.type === 'mousemove') {
        moveXA = e.clientX;
        moveYA = e.clientY;
      }
      if(e.type === 'touchmove') {
        moveXA = e.touches[0].clientX;
        moveYA = e.touches[0].clientY;
      }
    }
  }
  /**
   * Prevent mouse down from allowing drag on component.  
   * 
   * @function moveNoHandler
   *
   * @param {*} e 
   */
  function moveNoHandler(e) {
    e.preventDefault();
  }
  
  /**
   * Push route on navigate.  
   * 
   * @function navigatePush
   *
   * @param {String} route to push on navigate. 
   */
   function navigatePush(route) {
    //appFunctions.logLocalHostOnly("navigatePush downStartX="+downStartX+" downStartY="+downStartY);
    //appFunctions.logLocalHostOnly("navigatePush moveDirectionH="+moveDirectionH+" moveDirectionV="+moveDirectionV);
    if(!isDebug) {
      navigate(route);
    }
  }
  /**
   * Set projectIndex to next route, on last route set projectIndex to first route.
   *
   * @function displayNextRoute
   *
   */
  function displayNextRoute() {
    let i = (projectIndex + 1) %  portfolios.length;
    //appFunctions.logLocalHostOnly("next "+portfolios[i].name);
    navigatePush(portfolios[i].name);
    projectIndex = i;
  }
  /**
   * Set projectIndex to previous route, on first route set projectIndex to last route.  
   * 
   * @function displayPreviousRoute
   */
  function displayPreviousRoute() {
    let i = (projectIndex +  portfolios.length - 1) %  portfolios.length;
    //appFunctions.logLocalHostOnly("prev "+portfolios[i].name);
    if(!autoAdvance) {
      appTracking.trackRouteEvent('left',portfolios[i].name);
    }
    navigatePush(portfolios[i].name);
    projectIndex = i;
  }
 /**
  * Asynchronus start automatic next route timer with early return if isStopped
  * and start only if not isWaiting.  i.e. so it does not run if already running.
  *
  * @function startAutoNext
  *
  */
  async function startAutoNext() {
    if(isStopped()) return;
    if(!isWaiting) {
      isWaiting = true;
      await autoNextRoute();
    }
  }
  /**
  * Asynchronus automatic next route timer, assigns timeoutID.  
  *
  * @function autoNextRoute
  *
  */
  async function autoNextRoute() {
    timeoutID = autoAdvanceTimer();
  }
  /**
  * Cancel auto advance timer using timeoutID and run the optional cancelAdvance function.  
  *
  * The cancelAdvance function allows the parent to call the component, when loading
  * a route, with autoAdvance property deactivated.  
  *
  * @function cancelTimer
  */
  function cancelAutoAdvanceTimer() {
    if(timeoutID !== undefined) {
      clearTimeout(timeoutID);
      if(props.cancelAdvance !== undefined) {
        props.cancelAdvance();
      }
    }
  }
  /**
  * Window scroll causes auto advance timer cancel and
  * removes scroll listener.  
  *
  * @function windowScrolled
  *
  */
  function windowScrolled(e) {
    cancelAutoAdvanceTimer();
    window.removeEventListener("scroll",windowScrolled);
  }
}
