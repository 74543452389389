/** 
 * @fileOverview Display portfolio for identity, resume and project data.  
 *  
 * Projects are presented as swipable UI with styled buttons for contact,
 * resume and navigation.  Implements mobile responsiveness as to UI size 
 * and mobile only features.  Metadata for the route is set for SEO support 
 * while analytics are tracked with Matomo.
 * 
 * 
 * @module App
 * 
 * @author Philip Gottfried
 */
//Note:for jsdoc be sure first line of comment ends with a period and two spaces.  The first
//sentence is used as a brief and the two spaces end a paragraph.
import React , {Suspense } from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import preval from 'preval.macro';
 
import * as appDefaults from "./modules/AppDefaults";
import * as appFunctions from "./modules/AppFunctions";
import * as appFontSizes from "./modules/AppFontSizes";

import CompiledVersionFunction from "./components/CompiledVersionFunction";
import IdentityFunctionalComponent from "./components/IdentityFunctionalComponent";
import FormJSONFunction from "./components/FormJSONFunction";
import ProjectViewFunction from "./components/ProjectViewFunction";
import RouteIconSliderFunction from "./components/RouteIconSliderFunction";
import DivGroupFunction from "./components/DivGroupFunction";
import HomeFunction from "./components/HomeFunction";

const ResumeFunction = React.lazy(() => import('./components/ResumeFunction'));
const CopyrightPolicyFunction = React.lazy(() => import('./components/CopyrightPolicyFunction'));
const LightboxRouteFunction = React.lazy(() => import('./components/LightboxRouteFunction'));
/**
* Build time stamp using preval macro saves the build time to act as the version.  
*
* @const
*/
const buildTimeStamp = preval`module.exports = new Date().getTime();`;

/**
* Build jsonData by loading all json files in ./public/json at compile time using preval.   
*
* The output is a JSON object with these members {'portfolios':[],'schemas':[], 'data':[]} 
* that contain an array of the JSON for each file type.  
*
* The file names ending with Data.json and Schema.json are added to their respective members 
* with a name and the SJON from file.  All other files are added to portfolios.  
*
* {'portfolios':[{portfolios JON}]},
*   {'schemas':[{'name':'schema name', 'schemas':{schema JSON}}]},
*   {'data':[{'name':'data name', 'data':{data JSON}}]}
* }
*
* @const
*/
const jsonData = preval`
  const fs = require('fs')
  let output = {'portfolios':[],'schemas':[], 'data':[]}
  const jsonsInDir = fs.readdirSync('./public/json' ,{ withFileTypes: true });
  let fn = ''
  let contents = ''
  let jsonData = {}
  let td = {};
  const arrayLength = jsonsInDir.length
  for (var i = 1; i < arrayLength; i++) {
    let name = jsonsInDir[i]['name']
    fn = "./public/json/"+name
    contents = fs.readFileSync(fn, 'utf-8')
    jsonData = JSON.parse(contents)
    name = name.replace(".json","")
    if(fn.indexOf("Data.json")>=0) {
      td = jsonData.portfolios
      if(fn.indexOf("HomeData.json")>=0) {
        output.data.push({'name':name, 'data':td})
      } else {
        output.data.push({'name':name, 'data':td[0]})
      }
    } else {
      if(fn.indexOf("Schema.json")<0) {
        td = jsonData.portfolios
        output.portfolios.push(td[0])
      } else {
        output.schemas.push({'name':name, 'schemas':jsonData})
      }
    }
  }
  module.exports = output
`

/**
* Build expireHTML by loading the public index.html file at compile time using preval 
* and setting the expire meta tag to formatted date and time one hour earlier.  
*
* @const {String} date written to expires meta data in public index.html
*/
const expireHTML = preval`
let today = new Date()
var oneHour = 60 * 60 * 1000;
var newDateObj = new Date(today.getTime() - oneHour);
let expireDate = newDateObj.toDateString()+' '+newDateObj.toTimeString()
  const fs = require('fs')
  let key = '<meta http-equiv="Expires" content="'
  let  fn = "./public/index.html"
  let contents = fs.readFileSync(fn, 'utf-8')
  let indexA = contents.indexOf(key)
  let firstPart = contents.substring(0,indexA)
  let secondPart = contents.substring(indexA)
  let indexB = secondPart.indexOf(">")
  let lastPart = secondPart.substring(indexB)
  let output = firstPart+key+expireDate+'"'+lastPart
  fs.writeFileSync(fn, output)
  module.exports = expireDate
  `

/**
 * Implements display of IdentityFunctionalComponent and ProjectViewFunction components
* using json data for identity, contact, resume  and project files.  
* 
* The UI has buttons for the Portfolio owner's email contact, phone number and resume 
* followed by the name, title and a button menu of projects.  Use the left corner button
* menu to display owner's LinkedIn, contact and resume, while the right menu is for 
* links like Home, Previous, and Next.  
* 
* 
* Render the components when the wait state changes.
* 
* Uses isLoading state to display wait while loading data or a Router Routes.
* 
* Routes:
* <ul>
* <li>/ - Home route landing page for portfolio projects.</li>
* <li>/ContactForm - display contact form.</li>
* <li>/Resume - display resume with pdf and doc downloads.</li>
* <li>{portfolioName} - display portfolio for project name.</li>
* <li>{App404} - display 404 message. i.e., page not found.</li>
* </ul>
* 
* Routes for /ContactForm, {portfolioName} and {App404} display an IdentityFunctionalComponent.
* 
* The IdentityFunctionalComponent displays a button  for phone# (on mobile a dial link), view the resume, 
* and open a contact form.
* 
* Routes for {portfolioName} and {App404} display a NavigationJSON of project buttons
* to change routes.  A ProjectViewFunction displays the project information.
* 
* A route of LightboxXXX includes a suffix that is the route the lightbox is for.
* 
* An .htaccess file on the deployment server needs to redirect non resolvable requests
* to index.html, so the app handles the 404 as a route.  
* 
* Changes to bake JSON data using preval thus no delays while fetching and no need to cache
* the JSON data.  Also changed default route to a homeFunction as google does not want to
* index a link that is a redirect.  The home component ensures that google will index the url
* of just the domain name. i.e. with out a route.
* 
* @function App
* 
* @param {object} props 
* 
* @return {Object} a router component for the jsonData loaded by preval.
*/
export default function App(props) {
  let userName = "Guest";
  let autoAdvance = false;
  let isAppLoggedIn = false;
  let isUseLogIn = appDefaults.isUseLogIn;

  let titleFontSize = 50;
  let appData ={auth:props.auth,
                  isUseLogIn:isUseLogIn,
                  isAppLoggedIn:isAppLoggedIn,
                  userName:userName,
                  titleFontSize:titleFontSize}
  jsonData.data.push({name:"AppData", data:appData})
  if(appFunctions.isLocalHost()) {
    isAppLoggedIn = true;//simulate log in when localhost
  }
  isAppLoggedIn = false;//override localhost edit

  let validRoutes = ",/,/ContactForm,/Resume,/Identity,/Schema,/Copyrights,/Lightbox,";
    return (
      <>
            <Router >
              <Routes> 
                <Route  exact path="/"
                 element={
                  <>
                    <IdentityFunctionalComponent key={"appnavheadercontactform"} 
                      keyId={"contactform"}
                      projectData={jsonData}
                    /> 
                    <HomeFunction projectData={jsonData}
                          homeData={appFunctions.getData(jsonData,'HomeData', 'data')}/>
                    <CompiledVersionFunction key={"verIdentity"} buildTimeStamp={buildTimeStamp}/>
                  </>
                 }
                 />
                <Route key={"lightbox"} path='/Lightbox:projectName' 
                      element={
                        <div key={"divlightbox"}>
                          <IdentityFunctionalComponent key={"appnavheaderlightbox"}
                            keyId={"lightbox"}
                            projectData={jsonData}
                          />
                          <Suspense>
                          <LightboxRouteFunction 
                            projectData={jsonData}
                            fontName={appDefaults.projectViewTextFontName}
                          />
                          </Suspense>
                        </div>
                      }
                />
                <Route key={"contactform"}
                    path='/ContactForm' 
                    
                    element={
                      <div key={"divcontactform"}>
                          <IdentityFunctionalComponent key={"appnavheadercontactform"} 
                            keyId={"contactform"}
                            projectData={jsonData}
                          /> 
                          {appFunctions.isSmallWindow() &&
                            <FormJSONFunction key={"editcontact"}
                              schema={appFunctions.getData(jsonData,'ContactSchema', 'schemas')}
                              jsonFormData={appFunctions.getData(jsonData,'ContactData', 'data')}
                              formTitle={"Contact Form"}
                              formName={"Contact"}
                              proot='root'
                              fontSize="1.5vw"
                              size ="15"
                              areaColumns="15"
                              fromRoute = {appFunctions.getRouteFromLocationHref()}
                            />
                          } 
                          {!appFunctions.isSmallWindow() &&
                            <FormJSONFunction key={"editcontact"}
                              schema={appFunctions.getData(jsonData,'ContactSchema', 'schemas')}
                              jsonFormData={appFunctions.getData(jsonData,'ContactData', 'data')}
                              formTitle={"Contact Form"}
                              formName={"Contact"}
                              proot='root'
                              fontSize="2vw"
                              size ="25"
                              columns="35"
                              labelWidth="75px"
                              editWidth="300px"
                              fromRoute = {appFunctions.getRouteFromLocationHref()}
                            />
                          }
                      </div>
                    }
                />
                <Route key={"copyrightpolicy"}  
                  path='/Copyrightpolicy' 
                  element={
                    <div key={"divcopyrights"}>
                        <IdentityFunctionalComponent key={"appnavheadercopyrights"} 
                            keyId={"copyrights"}
                            projectData={jsonData}
                        />
                        <Suspense>
                        <CopyrightPolicyFunction key={"viewcopyrights"}  
                            keyId={"copyrights"} 
                            isAppLoggedIn={isAppLoggedIn}
                            userName={userName} 
                            identityData={appFunctions.getData(jsonData,'IdentityData', 'data')}
                              identitySchema={appFunctions.getData(jsonData,'IdentitySchema', 'schemas')}
                        />
                        </Suspense>
                    </div>
                }
                />
               
                <Route key={"resume"}  
                      path='/Resume' 
                      element={
                        <div key={"divresume"}>
                          <IdentityFunctionalComponent key={"appnavheaderresume"} 
                            keyId={"resume"}
                            projectData={jsonData}
                          />
                          <Suspense>
                          <ResumeFunction key={"viewresume"}  
                                    keyId={"resume"}
                                    projectData={jsonData}
                          />
                          </Suspense>
                        </div>
                      }
                />
                <Route key={"identity"}  
                      path='/Identity' 
                      element={
                        <div key={"dividentity"}>
                          <IdentityFunctionalComponent 
                              key={"appnavheaderidentity"} 
                              keyId={"identity"}
                              projectData={jsonData}
                          />
                          <FormJSONFunction key={"edit Identity"}
                                schema={appFunctions.getData(jsonData,'IdentitySchema', 'schemas')}
                                jsonFormData={appFunctions.getData(jsonData,'IdentityData', 'data')}
                                formTitle={"Portfolio Properties Form Identity"}
                                formName={"Identity"}
                                proot='root'
                                size ="25"
                          />
                           <CompiledVersionFunction key={"verIdentity"} buildTimeStamp={buildTimeStamp}/>
                        </div>
                      }
                />
                <Route key={"schema"}  
                      path='/Schema' 
                      element={
                        <div key={"divschema"}>
                          <IdentityFunctionalComponent key={"appnavheaderschema"} 
                            keyId={"schema"}
                            projectData={jsonData}
                          />
                          <FormJSONFunction key={"edit Schema"}
                                //ths is a test about editing schema file, not really needed
                                schema={appFunctions.getData(jsonData,'IdentitySchema', 'schemas')}
                                jsonFormData={appFunctions.getData(jsonData,'ResumeSchema', 'schemas')}
                                formTitle={"Portfolio Properties Form Schema"}
                                formName={"Schema"}
                                proot='root'
                                size ="25"
                          />
                           <CompiledVersionFunction key={"verSchema"} buildTimeStamp={buildTimeStamp}/>
                        </div>
                      }
                />
                {jsonData.portfolios.map((portfolio,portfolioIndex) => 
                  {
                    if(appFunctions.isNotSliderOn()) {
                    validRoutes = validRoutes + portfolio.name +",";
                    appFunctions.logLocalHostOnly("make route "+portfolio.projectTitle);
                    return (
                        <Route key={"route"+portfolio.projectTitle} path={portfolio.name} 
                        element={
                            <div key={"div"+portfolio.projectTitle}>
                              <IdentityFunctionalComponent 
                                  key={"IdentityFunctionalComponent"+portfolio.projectTitle}
                                  keyId={portfolioIndex.toString()}
                                  title={portfolio.seoTitle}
                                  projectData={jsonData}
                              />
                            <div style={{ position: 'relative', display: 'block',zIndex:'0'
                                }}>
                             <RouteIconSliderFunction key={"slidernav"+props.keyId}
                                  projectData={jsonData.portfolios}
                                  beginIndex={portfolioIndex}
                                  autoAdvance={autoAdvance}
                                  cancelAdvance={() =>{
                                    autoAdvance = false;
                                  }}
                              >
                              <ProjectViewFunction key={"portfolio"+portfolio.projectTitle} 
                                      projectData={portfolio}
                                      projectSchema={appFunctions.getData(jsonData,'ProjectSchema', 'schemas')}
                                      identityData={appFunctions.getData(jsonData,'IdentityData', 'data')}
                                      identitySchema={appFunctions.getData(jsonData,'IdentitySchema', 'schemas')}
                                      root='root'
                                      isAppLoggedIn={isAppLoggedIn}
                                      fontName={appDefaults.projectViewTextFontName}
                              /> 
                              </RouteIconSliderFunction>
                              {isAppLoggedIn &&
                                  <DivGroupFunction>
                                  <FormJSONFunction key={"edit"+portfolio.projectTitle}  
                                        schema={appFunctions.getData(jsonData,'ProjectSchema', 'schemas')}
                                        jsonFormData={portfolio}
                                        formTitle={"Project Properties Form "+portfolio.projectTitle}
                                        formName={portfolio.projectTitle}
                                        proot='root'
                                        size ="25"
                                  />
                                  </DivGroupFunction>
                              }
                            </div>
                              <CompiledVersionFunction key={"bts"+portfolio.projectTitle} buildTimeStamp={buildTimeStamp}/>
                            </div>
                          }
                        /> 
                      );
                    }
                    return <></>
                  }
                )}
                {appFunctions.isNotValidPath(validRoutes) &&
                  <Route key={"App404"}  path={appFunctions.getCurrentPath()}
                  element={
                      <div key="div404">
                        <IdentityFunctionalComponent 
                            key={"appHeader404"} 
                            keyId={"404"}
                            setReactiveFont={appFontSizes.setReactiveFontSize}
                            projectData={jsonData}
                        />
                        <DivGroupFunction>
                          <div key={"404versioncenter"} >
                            {"404 Page Not Found! "}
                            <br></br>
                            {window.location.href}
                          </div>
                        </DivGroupFunction>
                        <CompiledVersionFunction buildTimeStamp={buildTimeStamp}/>
                      </div>
                    }
                  />
                } 
              </Routes>
            </Router>
          )
      </>
    )
}
